import { Injectable, NgZone } from '@angular/core';
import { RemoteService } from './remote.service';
import { AppConfig } from './helper/app.config';
import { Storage } from '@ionic/storage';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { Logger, LogLevel } from './helper/app-error-logger';
import { AppEinstellungen, PushMessageHeader, PushMessageType, StatusUpdatePushMessage, GpsPositionCompressed, GeoPositionenPushMessage, LoginPushMessage, AuftragUpdatePushMessage, Ringtone, DownloadFile, GpsLocation, PushRegistration, AuftragListItem, LaunchNavigatorPlugin, NfcEvent } from './model/model';
import { Network } from '@ionic-native/network/ngx';
import { Geolocation, Geoposition } from '@ionic-native/geolocation/ngx';
import { Push, PushObject, PushOptions, RegistrationEventResponse, NotificationEventResponse } from '@ionic-native/push/ngx';
import { UniqueDeviceID } from '@ionic-native/unique-device-id/ngx';
import { NativeAudio } from '@ionic-native/native-audio/ngx';
import { BackgroundMode } from '@ionic-native/background-mode/ngx';
import { PowerManagement } from '@ionic-native/power-management/ngx';
import { Autostart } from '@ionic-native/autostart/ngx';
import { File } from '@ionic-native/file/ngx';
import { FileTransfer } from '@ionic-native/file-transfer/ngx';
import { AppUpdate } from '@ionic-native/app-update/ngx';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import ApkUpdater from 'cordova-plugin-apkupdater';

import { Platform, ToastController } from '@ionic/angular';
import { App } from './helper/app';

import * as moment from 'moment';
import * as pako from 'pako';

import { Textdatei, TextdateiTyp, GpsPosition, Adresse } from './model/swagger-model';
import { AppConsole } from './helper/app-console';
import { AppStorage } from './helper/app-storage';
import { FileHelper } from './helper/file-helper';
import { BackgroundGeolocationPlugin } from 'plugins/cordova-plugin-background-geolocation/www/BackgroundGeolocation';
import { Utils } from './helper/utils';
import { UiHelper } from './helper/ui-helper';
import { AppFileLogger } from './helper/app-file-logger';
import { AppService } from './helper/app-services';
import { LaunchNavigator, LaunchNavigatorOptions } from '@ionic-native/launch-navigator/ngx';
import { WebWorkerCode } from './helper/app-worker-code';
import { WebIntent } from '@ionic-native/web-intent/ngx';
import { NFC } from '@ionic-native/nfc/ngx';

declare var cordova: any;

@Injectable({
    providedIn: 'root'
})
export class SystemService {
    static instance: SystemService;

    private log = new Logger("SystemService", LogLevel.INFO);

    private appConfigWirdGeladen = false;
    private appConfigGeladen = false;
    private appConfigGeladenEvent = new Subject<void>();

    // private postausgang: Postausgang = null;

    private deviceId = null;

    private webSocket: WebSocket = null;

    istImVordergrund = new BehaviorSubject<boolean>(true);

    private lastMessageReceived = Date.now();
    private lastMessageSend = Date.now();
    private verfuegbareKlingeltoene: Ringtone[] = [];

    private sendenPostausgangLaeuft = false;

    auftraegeAktualisieren = new Subject<void>();
    stammdatenAktualisieren = new Subject<void>();
    einstellungenAktualisieren = new Subject<void>();
    fahrerAktualisieren = new Subject<void>();
    fahrzeugeAktualisieren = new Subject<void>();
    uvvAktualisieren = new Subject<void>();
    nachrichtenAktualisieren = new Subject<void>();
    tourenAktualisieren = new Subject<void>();
    changelogAktualisieren = new Subject<void>();
    texteAktualisieren = new Subject<void>();

    auftragUpdate = new Subject<AuftragUpdatePushMessage>();

    pushAvailable = new BehaviorSubject<boolean>(false);
    pushRegistrationId = new BehaviorSubject<string>('');
    pushRegistrationType = new BehaviorSubject<string>('');
    webSocketStatus = new BehaviorSubject<string>('');
    webSocketConnected = new BehaviorSubject<boolean>(false);
    webSocketNachrichtEmpfangen = new Subject<PushMessageHeader>();

    // currentPosition = new BehaviorSubject<GpsLocation>(null);

    pushNotificationEmpfangen = new Subject<NotificationEventResponse>();

    // Für die Aufzeichnung von GPS-Positionen
    // geoPositionen = new BehaviorSubject<GpsLocation[]>([]);

    // Datum (Ticks) an dem das letzte mal Geo-Positionen geschickt wurden
    geoPositionenGesendet = Date.now();
    aktuellerKlingelton: string;
    positionenAufzeichnenAktiv = false;
    positionenSendenIntervalId: any;

    // File download
    filedownloadQueue: DownloadFile[] = null;
    downloadRunning = false;

    ringtonesInitialisiert = false;

    versionNumber = App.version;

    einstellungenLetzteAktualisierung = 0;

    letzteGpsPosition: GpsPosition;
    letzteGpsPositionDate = 0;

    lastUpdateCheck = 0;
    pushRegistration: PushRegistration = null;
    pushRegistrationMandant: string;
    pushRegistrationGeraeteNummer: string;

    initialized = false;
    isVisible = new BehaviorSubject<boolean>(true);
    lastConfigStr = '';

    worker: Worker;

    nfcSubscriptions: Subscription[] = [];
    nfcReceivers: NfcReceiver[] = [];
    nfcRecieverId = 0;
    nfcAktiv = false;

    constructor(
        private storage: Storage,
        private remoteService: RemoteService,
        private network: Network,
        private file: File,
        private appUpdate: AppUpdate,
        private geolocation: Geolocation,
        private push: Push,
        private nfc: NFC,
        private autostart: Autostart,
        private platform: Platform,
        private webIntent: WebIntent,
        private toastController: ToastController,
        private launchNavigator: LaunchNavigator,
        private ngZone: NgZone,
        private androidPermissions: AndroidPermissions,
        private powerManagement: PowerManagement,
        private nativeAudio: NativeAudio,
        private backgroundMode: BackgroundMode,
        private fileTransfer: FileTransfer) {

        SystemService.instance = this;
        //// this.init();
    }

    async initialize() {
        this.log.debug('initialize');

        try {
            if (typeof Worker !== 'undefined') {
                // Create a new
                this.worker = new Worker(new URL('./system.service.worker', import.meta.url));
                this.worker.onmessage = ({ data }) => {
                    this.onWorkerMessage(data);
                };

                this.worker.postMessage('Test');
            } else {
                this.log.warn('WebWorker nicht verfügbar');
            }

            this.network.onDisconnect().subscribe(() => { App.internetAvailable.next(false); });
            this.network.onConnect().subscribe(() => { App.internetAvailable.next(true); });

            await this.ladeAppConfig();

            App.internetAvailable.subscribe((connected) => this.connectivityChanged(connected));

            this.log.debug('DEBUG: Wait platform ready');

            await App.ready();

            this.log.debug('DEBUG: Wait storage ready');

            await this.storage.ready();

            this.log.debug('DEBUG: platform + storage are ready');

            const updateFunction = () => {
                try {
                    this.update('updateFunction');
                } catch (err) {
                    this.log.error('update', err);
                }

                // Wenn die App im Vordergrund ist, dann alle 60 Sekunden aktualisieren.
                // Ansonsten alle 5 Minuten
                // Geht so nicht. Ansonsten bricht die WebSocket-Verbindung zusammen. Wir brauchen alle 120 Sekunden einen Ping
                // const time = this.istImVordergrund.getValue() ? 60 : 5 * 60;

                setTimeout(updateFunction, 60 * 1000);
            }

            setTimeout(updateFunction, 10 * 1000);


            // Hier überall kein await. Soll den App-Start nicht verzögern
            this.initFileLogger();
            this.initFileDownload();
            this.initAutostart();
            this.initPushNotifications();
            this.initBackgroundMode();
            this.initLocalNotifications();
            this.startBackgroundGeolocation();
            this.initNavigator();
            this.initNfc();
            this.collectSystemInfos();

            // Schreibe den Stats auch immer in ein App-Feld für den einfachen Zugriff
            this.istImVordergrund.subscribe((p) => App.istImVordergrund = p);

            this.log.debug('DEBUG 1');

            // Websocket wird eigentlich über 'onAppConfigChanged' initialisiert.
            // Wenn nicht, dann nach spätestens 10 Sekunden initialisieren
            setTimeout(() => {
                try {
                    if (!this.webSocket && App.internetAvailable.getValue()) {
                        this.initWebSocket('setTimeout: kein webSocket');
                    }
                } catch (err) {
                    this.log.error('setTimeout: ' + Utils.getErrorMessage(err), err);
                }
            }, 10000);

            this.log.debug('DEBUG 2');

            App.current.configRefreshed.subscribe(() => {
                this.onAppConfigChanged();
            });

            this.log.debug('DEBUG 3');

            setInterval(() => {
                this.collectSystemInfos();
            }, 5 * 60 * 1000);

            // AppConfig.changed.subscribe(p => this.onAppConfigChanged());

            this.einstellungenAktualisieren.subscribe(() => this.aktualisiereEinstellungen());

            document.addEventListener("deviceready", () => this.onDeviceReady(), false);
            document.addEventListener("pause", () => this.onPause(), false);
            document.addEventListener("resume", () => this.onResume(), false);

            this.log.debug('DEBUG 4');
        } catch (err) {
            this.log.error('initialize', err);
        }

        this.initialized = true;
    }

    onResume() {
        this.log.info('onResume');
        this.isVisible.next(true);
    }

    onPause(): any {
        this.log.info('onPause');
        this.isVisible.next(false);
    }

    onDeviceReady(): any {
        this.log.info('onDeviceReady');

        const device = (window as any).device;

        this.log.info('device' + JSON.stringify(device));
    }

    /**
     * Sammelt Informationen zum Gerät.
     * Diese Daten können mit an den ReCoMobil-Server geschickt werden
     */
    async collectSystemInfos() {
        this.log.info('collectSystemInfos');

        try {
            await this.updatePermissionSystemAlertWindow();
        } catch (err) {
            this.log.error(Utils.getErrorMessage(err));
        }
    }

    /**
     * Berechtigung für "System Alerts".
     * Das ermöglicht der App, sich über anderen Apps anzuzeigen und damit auch in den Vordergrund zu holen.
     */
    async updatePermissionSystemAlertWindow() {
        this.log.debug('updatePermissionSystemAlertWindow');

        return new Promise((resolve, reject) => {
            try {
                const systemAlertWindowPermission = (cordova.plugins as any).systemAlertWindowPermission;

                if (systemAlertWindowPermission) {
                    systemAlertWindowPermission.hasPermission(
                        (e) => {
                            this.log.info('systemAlertWindowPermission hasPermission result=' + JSON.stringify(e));
                            AppConfig.current.permissionSystemAlertWindow = !!e;
                            resolve(true);
                        },
                        (err) => {
                            this.log.info('systemAlertWindowPermission hasPermission failed=' + JSON.stringify(err));
                            AppConfig.current.permissionSystemAlertWindow = false;
                            resolve(false);
                        });
                } else {
                    AppConfig.current.permissionSystemAlertWindow = false;
                    resolve(false);
                }
            } catch (err) {
                this.log.warn('updatePermissionSystemAlertWindow: ' + Utils.getErrorMessage(err));
            }
        });
    }

    initNfc() {

        if (App.isCordovaAvailable()) {
            try {
                this.log.debug('initNfc');

                this.nfcSubscriptions.push(this.nfc.addNdefListener(async () => {
                    this.log.debug('NFC listener added');
                    this.nfcAktiv = true;
                }, (err) => {
                    this.log.warn('NFC fehler', err);
                }).subscribe(async (event) => {
                    this.log.info('NFC event', event);
                    this.verarbeiteNfcEvent(event);
                }));

                this.log.debug('NFC addNdefFormatableListener');
                this.nfcSubscriptions.push(this.nfc.addNdefFormatableListener(async () => {
                    this.log.debug('NFC addNdefFormatableListener added');
                }, (err) => {
                    this.log.warn('NFC addNdefFormatableListener fehler: ' + JSON.stringify(err), err);
                }).subscribe(async (event) => {
                    this.log.debug('NFC ndef-formatable event: ' + JSON.stringify(event));
                    this.verarbeiteNfcEvent(event);
                }));
            } catch (err) {
                this.log.error('NFC exception: ' + err, err);
            }
        }
    }

    verarbeiteNfcEvent(event: any) {
        try {
            this.log.debug('verarbeiteNfcEvent', event)

            let id: number[] = event.tag.id;

            if (App.current.NfcFormat === "reverse") {
                id = id.reverse();
            }

            const seriennummer = this.nfc.bytesToHexString(id).trim().toUpperCase();
            const seriennummer2 = this.nfc.bytesToHexString(id.reverse()).trim().toUpperCase();

            let payload = '';
            const payloadList: string[] = [];

            this.log.debug('NFC seriennummer: ' + seriennummer);

            if (App.current.NfcPayloadVerwenden && event.tag.ndefMessage && event.tag.ndefMessage.length > 0) {
                const record = event.tag.ndefMessage[0];
                payload = this.nfc.bytesToString(record.payload).substring(3).trim().toUpperCase();

                this.log.debug('NFC payload: ' + payload);

                for (const record of event.tag.ndefMessage) {
                    const payload = this.nfc.bytesToString(record.payload).substring(3);

                    if (payload.length > 5) {
                        payloadList.push(payload.trim().toUpperCase());
                    }
                }
            }

            this.ngZone.run(async () => {
                const nfcEvent: NfcEvent = {
                    id: id,
                    seriennummer: seriennummer,
                    seriennummer2: seriennummer2,
                    payload: payload,
                    payloadList: payloadList,
                    handled: false
                };

                for (const handler of this.nfcReceivers) {
                    if (typeof (handler.func) === 'function') {
                        handler.func(nfcEvent);

                        if (nfcEvent.handled) {
                            break;
                        }
                    }
                }
            });
        } catch (err) {
            this.log.error('verarbeiteNfcEvent: ' + err, err);
        }

        // const event: BarcodeEvent = {
        //     barcode: {
        //         text: data,
        //         format: symbology
        //     },
        //     handled: false
        // };

        // for (const handler of this.barcodeReceivers) {
        //     if (typeof (handler.func) === 'function') {
        //         handler.func(event);

        //         if (event.handled) {
        //             break;
        //         }
        //     }
        // }

        // if (!event.handled) {
        //     this.barcodeReceived.next({
        //         text: data,
        //         format: symbology
        //     });
        // }
    }

    initNavigator() {
        const launchnavigator = ((window as any).launchnavigator as LaunchNavigatorPlugin);

        if (launchnavigator) {
            const googleApiKey = AppConfig.current.einstellungen.GoogleApiKey;

            if (googleApiKey) {
                launchnavigator.setApiKey(
                    googleApiKey,
                    () => { this.log.debug(`setApiKey ${googleApiKey} success`); },
                    (err) => { this.log.warn(`setApiKey ${googleApiKey} failed: ${err}`); });
            }
        }
    }

    private async initFileLogger() {
        this.log.info('initFileLogger');

        if (App.isCordovaAvailable()) {
            this.log.info('initFileLogger cordova available');

            Logger.fileLogger = new AppFileLogger(this.file, this.platform);
            Logger.fileLogger.init({
                logDir: 'logfiles',
                enableMetaLogging: false,
                fileMaxLines: 10000,
                fileMaxSize: 1000000,
                totalLogSize: 5000000,
            });
        }

        this.log.debug('initFileLogger done');
    }

    private async initFileDownload() {
        this.log.info('initFileDownload');

        this.filedownloadQueue = await AppStorage.current.get('download-file-queue', true, false);

        if (!this.filedownloadQueue) {
            this.filedownloadQueue = [];
        }

        this.log.debug('initFileDownload done');
    }

    private onAppConfigChanged() {
        this.log.debug('onAppConfigChanged. initialized=' + this.initialized);

        Logger.fileLoggerAktiv = AppConfig.current.einstellungen.FileLoggerAktiv == 'ja';

        if (Logger.fileLogger) {
            Logger.fileLogger.getConfig().enableMetaLogging = AppConfig.current.einstellungen.FileLoggerMetadaten == 'ja';
        }

        const appConfig = AppConfig.current;

        const configStr = `${appConfig.mandant};${appConfig.geraeteNummer};${appConfig.token}`;

        if (configStr != this.lastConfigStr) {
            this.lastConfigStr = configStr;

            if (AppConfig.current.token) {
                this.initWebSocket('onAppConfigChanged');
            }

            this.initPushNotifications();
        }

        this.initNavigator();

        const spracheAendernErlaubt = Utils.isTrue(AppConfig.current.einstellungen.SpracheWechseln);
        const standardSprache = AppConfig.current.einstellungen.SpracheStandard;

        if (standardSprache) {
            let setzeStandardSprache = false;

            if (!spracheAendernErlaubt) {
                // Anwender darf die Sprache nicht ändern, deshalb immer die Standard-Sprache setzen
                setzeStandardSprache = true;
            } else if (!AppConfig.current.language) {
                // Es ist noch keine Sprache gesetzt. Deshalb die Standard-Sprache verwenden.
                setzeStandardSprache = true;
            }

            if (setzeStandardSprache) {
                if (AppConfig.current.language != standardSprache) {
                    this.log.info(`Setze Standard-Sprache: ${standardSprache}, aktuell: ${appConfig.language}`);
                    App.current.setLanguage(standardSprache);
                }
            }
        }
    }

    async getCurrentPosition(): Promise<GpsLocation> {
        try {
            const location = await this.geolocation.getCurrentPosition({
                timeout: App.current.GpsPositionTimeout,
                enableHighAccuracy: true,
                maximumAge: 3000
            });

            return {
                latitude: location.coords.latitude,
                longitude: location.coords.longitude,
                time: location.timestamp
            } as any;
        } catch (err) {
            this.log.warn('getCurrentPosition: ' + Utils.getErrorMessage(err, true));
            return null;
        }
    }

    async getExakteGpsPosition(info: string, loadingAnzeigen = true): Promise<GpsPosition> {
        this.log.debug('getExakteGpsPosition: ' + info);

        let loadingWiederSetzen = false;

        if (loadingAnzeigen) {
            if (App.isLoading()) {
                loadingWiederSetzen = true;
                App.loading(false);
            }

            await UiHelper.showLoading('Exakte GPS-Position wird ermittelt...');
        }

        if (!App.isCordovaAvailable()) {
            await App.delay(1000);
        }

        const timeout = Date.now() + 10000;

        try {
            for (let i = 0; i < 30; i++) {
                try {
                    const location = await this.geolocation.getCurrentPosition({
                        timeout: App.current.GpsPositionTimeout,
                        enableHighAccuracy: true,
                        maximumAge: 3000
                    });

                    if (location && location.coords && location.coords.accuracy <= App.current.PositionenExaktMeter) {
                        const gpsPosition: GpsPosition = {
                            Latitude: location.coords.latitude,
                            Longitude: location.coords.longitude,
                            Accuracy: location.coords.accuracy,
                            Altitude: location.coords.altitude,
                            Bearing: location.coords.heading,
                            Speed: location.coords.speed,
                            Timestamp: location.timestamp
                        }

                        this.letzteGpsPosition = gpsPosition;
                        this.letzteGpsPositionDate = Date.now();

                        if (loadingAnzeigen) {
                            const toast = await this.toastController.create({
                                message: 'GPS-Position erfasst',
                                duration: 1500,
                                color: 'success'
                            });

                            toast.present();

                            await Utils.delay(1000);
                        }

                        return gpsPosition;
                    }
                } catch (err) {
                    this.log.warn('getExakteGpsPosition: ' + Utils.getErrorMessage(err, true));
                }

                await Utils.delay(1000);

                if (Date.now() > timeout) {
                    break;
                }
            }
        } finally {
            if (loadingAnzeigen) {
                await UiHelper.hideLoading();
            }

            if (loadingWiederSetzen) {
                App.loading(true);
            }
        }

        this.log.warn('getExakteGpsPosition: Position konnte nicht ermittelt werden');

        return null;
    }

    async getAktuelleGpsPositionStr(info: string, maxAlterSekunden = 10): Promise<string> {
        let gpsPosition = await this.getAktuelleGpsPosition(info, maxAlterSekunden);
        let gpsWert = '';

        if (gpsPosition?.Latitude) {
            gpsWert = `${gpsPosition.Latitude.toFixed(5)}, ${gpsPosition.Longitude.toFixed(5)}`;
        }

        return gpsWert;
    }

    async getAktuelleGpsPosition(info: string, maxAlterSekunden = 5): Promise<GpsPosition> {
        try {
            this.log.debug('getAktuelleGpsPosition: ' + info);

            if (this.letzteGpsPosition && this.letzteGpsPositionDate > Date.now() - (maxAlterSekunden * 1000)) {
                // Vor 5 Sekunden wurde shcon eine Position aufgezeichnet. Zu häufig muss das auch nicht sein...
                return this.letzteGpsPosition;
            }

            if (!App.isCordovaAvailable()) {
                await App.delay(1000);
            }

            const location = await this.geolocation.getCurrentPosition({
                timeout: App.current.GpsPositionTimeout,
                enableHighAccuracy: true,
                maximumAge: 5000
            });

            const gpsPosition: GpsPosition = {
                Latitude: location.coords.latitude,
                Longitude: location.coords.longitude,
                Accuracy: location.coords.accuracy,
                Altitude: location.coords.altitude,
                Bearing: location.coords.heading,
                Speed: location.coords.speed,
                Timestamp: location.timestamp
            }

            this.letzteGpsPosition = gpsPosition;
            this.letzteGpsPositionDate = Date.now();

            return gpsPosition;
        } catch (err) {
            this.log.warn('getAktuelleGpsPosition: ' + Utils.getErrorMessage(err, true));
            return null;
        }
    }

    async aktualisiereEinstellungen(): Promise<boolean> {
        this.log.debug('aktualisiereEinstellungen');

        try {
            const appConfig = await this.getAppConfig();

            const deviceId = await this.getDeviceId();

            // TODO: Hash des schluessels mit an dern Server schicken und überprüfen
            const loginResult = await this.remoteService.login({
                appVersion: AppConfig.AppVersion,
                geraeteNummer: appConfig.geraeteNummer,
                passwort: appConfig.passwort,
                mandant: appConfig.mandant,
                deviceId
            });

            if (!loginResult) {
                this.log.warn('aktualisiereEinstellungen: REST-Antwort ist NULL. Keine Internetverbindung?');
                return false;
            }

            if (loginResult.success) {
                this.log.debug('aktualisiereEinstellungen erfolgreich');

                appConfig.uebernehmeLoginResult(loginResult);

                await this.speichereAppConfig(appConfig);

                App.current.refresh();

                // this.speichereEinstellungen(loginResult.einstellungen);

                this.einstellungenLetzteAktualisierung = Date.now();

                return true;
            } else {
                this.log.debug('aktualisiereEinstellungen nicht erfolgreich: ' + loginResult.message);
            }
        } catch (err) {
            this.log.error('aktualisiereEinstellungen: ' + Utils.getErrorMessage(err), err);
        }

        return false;
    }

    async aktualisiereEinstellungenWennNotwendig(): Promise<void> {
        try {
            const intervallStunden = 12;

            if (this.einstellungenLetzteAktualisierung > Date.now() - intervallStunden * 60 * 60 * 1000) {
                // Einstellungen wurden in den letzten {intervallStunden} Stunden schon aktualisiert
                return;
            }

            if (App.internetAvailable.getValue()) {
                const appConfig = AppConfig.current;

                if (appConfig.mandant && appConfig.geraeteNummer && appConfig.host) {
                    const ok = await this.aktualisiereEinstellungen();

                    if (!ok) {
                        // Login fehlgeschlagen etc.
                        // Erst in einer Stunde wieder probieren
                        this.einstellungenLetzteAktualisierung = Date.now() - (intervallStunden - 1) * 60 * 60 * 1000;
                    }
                }
            }
        } catch (err) {
            this.log.error('aktualisiereEinstellungenWennNotwendig: ' + Utils.getErrorMessage(err), err);
        }
    }

    async getAppConfig(): Promise<AppConfig> {
        if (this.appConfigGeladen) {
            return AppConfig.current;
        }

        if (this.appConfigWirdGeladen) {
            this.log.debug("appConfigWirdGeladen...");

            return new Promise<AppConfig>((resolve, reject) => {
                const subscription = this.appConfigGeladenEvent.subscribe(() => {
                    this.log.debug("appConfigGeladenEvent");
                    subscription.unsubscribe();
                    resolve(AppConfig.current);
                });
            });
        }

        this.log.debug('getAppConfig');
        await this.ladeAppConfig();

        return AppConfig.current;
    }

    async speichereAppConfig(model: AppConfig) {
        await AppStorage.current.set('einstellungen', model, true, true);

        AppConfig.changed.next();
    }

    async speichereEinstellungen(appEinstellungen: AppEinstellungen) {
        this.log.debug('speichereEinstellungen', appEinstellungen);

        const appConfig = await this.getAppConfig();

        appConfig.einstellungen = appEinstellungen;

        await this.speichereAppConfig(appConfig);

        App.current.refresh();
    }

    async ladeAppConfig(): Promise<void> {
        this.log.info('ladeAppConfig');

        if (this.appConfigGeladen) {
            this.log.debug('appConfig bereits geladen. abbruch');
            return;
        }

        this.appConfigWirdGeladen = true;

        try {
            const savedAppConfig: AppConfig = await AppStorage.current.get('einstellungen', true, true);

            this.log.info('appConfig', savedAppConfig);

            if (savedAppConfig) {
                AppConfig.current.mandant = savedAppConfig.mandant;
                AppConfig.current.geraeteNummer = savedAppConfig.geraeteNummer;
                AppConfig.current.schluessel = savedAppConfig.schluessel;
                AppConfig.current.passwort = savedAppConfig.passwort;
                AppConfig.current.token = savedAppConfig.token;
                AppConfig.current.gekoppelteDrucker = savedAppConfig.gekoppelteDrucker;
                AppConfig.current.gekoppelteWaagen = savedAppConfig.gekoppelteWaagen;
                AppConfig.current.UnterMandant = savedAppConfig.UnterMandant;
                AppConfig.current.GeraeteGruppe = savedAppConfig.GeraeteGruppe;
                AppConfig.current.GeraeteBezeichnung = savedAppConfig.GeraeteBezeichnung;
                AppConfig.current.darkMode = savedAppConfig.darkMode;
                AppConfig.current.Anlagen = savedAppConfig.Anlagen;
                AppConfig.current.aktuelleAnlage = savedAppConfig.aktuelleAnlage;

                if (savedAppConfig.host) {
                    AppConfig.current.setHost(savedAppConfig.host);
                }

                if (savedAppConfig.language && savedAppConfig.language.length == 2) {
                    App.current.setLanguage(savedAppConfig.language);
                }

                AppConfig.current.einstellungen = savedAppConfig.einstellungen;
            }

            if (!AppConfig.current.gekoppelteDrucker) {
                AppConfig.current.gekoppelteDrucker = [];
            }

            if (!AppConfig.current.gekoppelteWaagen) {
                AppConfig.current.gekoppelteWaagen = [];
            }

            if (!AppConfig.current.host) {
                AppConfig.current.setHost('https://app.recomobil.de');
            }

            if (AppConfig.current.host == 'https://recomobil-app.zilles-it.com') {
                AppConfig.current.setHost('https://app.recomobil.de');
            }

            if (!AppConfig.current.einstellungen) {
                AppConfig.current.einstellungen = {};
            }

            if (!AppConfig.current.token) {
                AppConfig.current.token = '';
            }

            if (!AppConfig.current.Anlagen) {
                AppConfig.current.Anlagen = [];
            }

            this.appConfigGeladen = true;
            this.appConfigWirdGeladen = false;
            this.appConfigGeladenEvent.next();

            AppConfig.loaded.next(AppConfig.current);

            this.log.info('appConfig result', savedAppConfig);

            App.current.refresh();
            App.current.geladen.next(true);
        } catch (err) {
            this.log.error('ladeAppConfig: ' + Utils.getErrorMessage(err), err);
        } finally {
            this.appConfigGeladen = true;
            this.appConfigWirdGeladen = false;
            this.appConfigGeladenEvent.next();
        }
    }

    async sendeTextdatei(textdatei: Textdatei, mitGpsPosition: boolean) {
        this.log.debug('sendeTextdatei', textdatei);

        try {
            await this.aktualisiereTextdatei(textdatei, mitGpsPosition);

            const id = Date.now();

            await AppStorage.current.set('postausgang-' + id, textdatei, false);

            this.sendePostausgang();
        } catch (err) {
            this.log.error('sendeTextdatei: ' + Utils.getErrorMessage(err), err);
        }
    }

    async aktualisiereTextdatei(textdatei: Textdatei, mitGpsPosition: boolean): Promise<void> {
        this.log.debug('sendeTextdatei', textdatei);

        try {
            textdatei.AppVersion = this.versionNumber;

            if (mitGpsPosition) {
                textdatei.Position = await this.getAktuelleGpsPosition('Status');
            }

            if (!textdatei.DeviceInfo) {
                textdatei.DeviceInfo = {};
            }

            textdatei.DeviceInfo['SYSTEM_ALERT_WINDOW'] = AppConfig.current.permissionSystemAlertWindow ? 'ja' : 'nein';

            const device = (window as any).device;

            if (device?.available) {
                textdatei.DeviceInfo['platform'] = device.platform;
                textdatei.DeviceInfo['version'] = device.version;
                textdatei.DeviceInfo['uuid'] = device.uuid;
                textdatei.DeviceInfo['cordova'] = device.cordova;
                textdatei.DeviceInfo['model'] = device.model;
                textdatei.DeviceInfo['manufacturer'] = device.manufacturer;
                textdatei.DeviceInfo['sdkVersion'] = device.sdkVersion;
            }
        } catch (err) {
            this.log.error('sendeTextdatei: ' + Utils.getErrorMessage(err), err);
        }
    }

    /**
     * Versucht alle Nachrichten im Postausgang an den Server zu schicken.
     */
    async sendePostausgang() {
        if (this.sendenPostausgangLaeuft) {
            return;
        }

        this.log.debug('sendePostausgang');

        this.sendenPostausgangLaeuft = true;

        try {
            await App.ready();

            let ttl = 10;

            do {
                const allKeys = await this.storage.keys();

                const postausgangKeys = allKeys.filter(p => p && p.startsWith('postausgang-'));

                if (!postausgangKeys.length) {
                    return;
                }

                postausgangKeys.sort();

                this.log.debug(`sendePostausgang ${postausgangKeys.length} Textdateien gefunden`);

                if (!App.isInternetAvailable()) {
                    this.log.info('sendePostausgang abgebrochen. Keine Internetverbindung.');
                }

                for (const key of postausgangKeys) {
                    this.log.debug('sendePostausgang: ' + key);

                    if (!App.isInternetAvailable()) {
                        this.log.info('senden abgebrochen. Keine Internetverbindung.');
                        break;
                    }

                    let textdatei = await this.storage.get(key);

                    if (!textdatei) {
                        await Utils.delay(1000);

                        // Nochmal probieren
                        textdatei = await this.storage.get(key);

                        if (!textdatei) {
                            this.log.warn('Textdatei nicht im storage gefunden: ' + key);

                            // Vorsichtshalber nochmal löschen
                            await this.storage.remove(key);
                            continue;
                        }
                    }

                    const json = JSON.stringify(textdatei);
                    const zipped: ArrayBuffer = pako.deflate(json);
                    let sendenErfolgreich = false;

                    for (let i = 0; i < 10; i++) {
                        if (!App.isInternetAvailable()) {
                            break;
                        }

                        try {
                            this.log.debug('sendTextdateiZipped: ' + zipped.byteLength + ' Bytes (' + i + ')');

                            const sendResult = await this.remoteService.sendTextdateiZipped(zipped);

                            if (!sendResult) {
                                // Fehler bei der Kommunikation mit dem Server (kein internet?)
                                this.log.warn('Fehler beim senden der Textdatei (Kommunikation)');
                                sendenErfolgreich = false;
                                await Utils.delay(5000);
                                continue;
                            }

                            if (sendResult.success) {
                                sendenErfolgreich = true;
                                break;
                            } else {
                                this.log.warn('Fehler beim senden der Textdatei (Server): ' + sendResult.message);
                                sendenErfolgreich = false;

                                await Utils.delay(1000);
                            }
                        } catch (err) {
                            const errorMessage = Utils.getErrorMessage(err);
                            this.log.warn('Fehler beim senden der Textdatei (Client): ' + errorMessage);
                            sendenErfolgreich = false;

                            if (errorMessage && errorMessage.indexOf('ERR_CONNECTION_REFUSED') >= 0) {
                                await Utils.delay(10000);
                            } else {
                                await Utils.delay(1000);
                            }
                        }

                        if (sendenErfolgreich) {
                            break;
                        }
                    }

                    if (sendenErfolgreich) {
                        // Lösche die lokale Nachricht
                        this.log.debug('Senden erfolgreich: ' + key);
                        await this.storage.remove(key);
                    } else {
                        if (App.isInternetAvailable()) {
                            this.log.warn('Textdatei konnte auch nach 10 Versuchen nicht versendet werden. Abbruch')
                        } else {
                            this.log.warn('Textdatei konnte auch nach 10 Versuchen nicht versendet werden. Abbruch da keine Internetverbindung vorhanden.')
                        }

                        break;
                    }
                }
            } while (ttl-- > 0);
        } finally {
            this.sendenPostausgangLaeuft = false;
        }

        this.log.debug('sendePostausgang FERTIG');
    }

    // async speicherePostausgang(): Promise<void> {
    //     await AppStorage.current.set('postausgang', this.postausgang, false);
    // }

    // async getPostausgang(): Promise<Postausgang> {
    //     if (this.postausgang == null) {
    //         this.postausgang = await AppStorage.current.get('postausgang', true, false);

    //         if (!this.postausgang) {
    //             this.postausgang = {
    //                 lastId: 0,
    //                 textdateiIds: []
    //             };
    //         }
    //     }

    //     if (!this.postausgang.lastId) {
    //         this.postausgang.lastId = 0;
    //     }

    //     if (!this.postausgang.textdateiIds) {
    //         this.postausgang.textdateiIds = [];
    //     }

    //     return this.postausgang;
    // }

    private connectivityChanged(connected: boolean) {
        this.log.debug('connectivityChanged: ' + connected + ', initialized=' + this.initialized);

        if (connected && this.initialized) {
            this.sendePostausgang();

            if (!this.webSocket) {
                setTimeout(() => {
                    try {
                        if (!this.webSocket) {
                            this.initWebSocket('connectivityChanged');
                        }
                    } catch (err) {
                        this.log.error('setTimeout connectivityChanged: ' + Utils.getErrorMessage(err), err);
                    }
                }, 500);
            }
        }
    }

    private async initWebSocket(info: string): Promise<void> {
        this.log.debug('initWebSocket: ' + info);

        this.lastMessageReceived = Date.now();

        this.webSocketStatus.next('connecting');
        this.webSocketConnected.next(false);

        const host = this.remoteService.getAktiverHost().url
            .toLowerCase()
            .replace("https://", "wss://")
            .replace("http://", "ws://");

        const url = host + '/ws';

        this.log.info('Connecting web socket to ' + url);

        if (this.webSocket) {
            this.webSocket.close();
            this.webSocket = null;
        }

        this.webSocket = new WebSocket(url);

        this.webSocket.onmessage = (ev: MessageEvent) => {
            this.log.debug('webSocket.onmessage');

            try {
                this.log.debug('onmessage', ev);

                if (this.webSocketStatus.getValue() != 'OK') {
                    this.webSocketStatus.next('OK');
                }

                if (!this.webSocketConnected.getValue()) {
                    this.webSocketConnected.next(true);
                }

                const data = ev.data;

                if (typeof (data) === 'string') {
                    this.verarbeiteWebSocketMessage(data as string);
                } else if (typeof (data) === 'object') {
                    this.log.error('Noch nicht implementiert !', ev);
                }
            } catch (err) {
                this.log.error('webSocket.onmessage', err);
            }
        };

        this.webSocket.onopen = async (ev: Event) => {
            this.log.info('webSocket.onopen');

            try {
                this.webSocketStatus.next('connected');
                this.webSocketConnected.next(true);
                await this.sendeWebSocketLoginMessage();
                await this.pingWebSocket(true);
            } catch (err) {
                this.log.warn('onopen', err);
            }
        };

        this.webSocket.onclose = (ev: CloseEvent) => {
            this.log.info('webSocket.onclose');
            this.webSocketStatus.next('disconnected');
            this.webSocketConnected.next(false);
        };

        this.webSocket.addEventListener('close', () => {
            this.log.info('webSocket.close');
            this.webSocketStatus.next('disconnected');
            this.webSocketConnected.next(false);
        });
    }

    async sendeWebSocketLoginMessage() {
        this.log.debug('sendeWebSocketLoginMessage');

        const deviceId = await this.getDeviceId();

        await this.sendWebSocketMessage({
            t: PushMessageType.Login,
            m: AppConfig.current.mandant,
            g: AppConfig.current.geraeteNummer,
            x: AppConfig.current.token,
            d: deviceId,
            v: this.versionNumber
        } as LoginPushMessage, 'login');
    }

    async initBackgroundMode() {
        this.log.info('initBackgroundMode');

        try {
            if (AppConfig.current.anwendungAktivLassen) {
                if (!this.isCordovaAvailable()) {
                    this.log.warn('Cordova nicht verfügbar. Background-Mode wird nicht aktiviert');
                    return;
                }

                this.log.info("Aktiviere Background-Mode");

                // this.log.info('backgroundMode setDefaults');
                this.backgroundMode.setDefaults({
                    // Title of the background task
                    title: 'ReCoMobil',

                    // Description of background task
                    text: 'Aufträge empfangen, GPS-Positionen aufzeichnen',

                    // if true plugin will not display a notification. Default is false.
                    silent: true,

                    // icon: 'icon' // this will look for icon.png in platforms/android/res/drawable|mipmap
                    // color: 'F14F4D', // hex format like 'F14F4D'

                    // By default the app will come to foreground when tapping on the notification. If false, plugin won't come to foreground when tapped.
                    resume: true,

                    // When set to false makes the notifications visible on lock screen (Android 5.0+)
                    hidden: true,

                    // The text that scrolls itself on statusbar
                    ticker: undefined,

                    // hidden: Boolean,
                    bigText: true
                });

                this.backgroundMode.on('enable').subscribe(() => { this.log.info('backgroundMode enabled'); });
                this.backgroundMode.on('disable').subscribe(() => { this.log.info('backgroundMode disabled'); });

                this.backgroundMode.on('activate').subscribe(() => {
                    this.log.info('backgroundMode activated');

                    // Various APIs like playing media or tracking GPS position in background might not work while in background even the
                    // background mode is active. To fix such issues the plugin provides a method to disable most optimizations done by Android/CrossWalk.
                    this.backgroundMode.disableWebViewOptimizations();

                    // https://github.com/katzer/cordova-plugin-background-mode/issues/430
                    (cordova.plugins as any).backgroundMode.disableBatteryOptimizations();

                    this.istImVordergrund.next(false);

                    setTimeout(() => { this.update('backgroundMode activated'); }, 10 * 1000);
                });

                this.backgroundMode.on('deactivate').subscribe(() => {
                    try {
                        this.log.info('backgroundMode deactivated -> moving to foreground');
                        const localNotifications = (cordova.plugins as any).notification?.local;
                        localNotifications.clearAll();

                        this.istImVordergrund.next(true);
                    } catch (err) {
                        this.log.error('deactivate: ' + Utils.getErrorMessage(err), err);
                    }
                });

                this.backgroundMode.on('failure').subscribe(() => {
                    this.log.info('backgroundMode failure');
                });

                this.platform.pause.subscribe(() => {
                    this.log.info('backgroundMode pause -> moving to background');
                    // this.backgroundMode.excludeFromTaskList();
                    //// this.backgroundMode.moveToBackground();
                });

                this.backgroundMode.enable();

                this.backgroundMode.isScreenOff(value => this.log.info('isScreenOff: ' + value));

                this.backgroundMode.moveToForeground();

                // this.log.info('backgroundMode excludeFromTaskList');
                // this.backgroundMode.excludeFromTaskList();

                // this.log.info('backgroundMode setDefaults');
                // this.backgroundMode.setDefaults({
                //     title: 'ReCoMobil',
                //     text: 'Wird ausgeführt',
                //     // icon: 'icon' // this will look for icon.png in platforms/android/res/drawable|mipmap
                //     color: 'F14F4D', // hex format like 'F14F4D'
                //     resume: true,
                //     hidden: false,
                //     // hidden: Boolean,
                //     // bigText: Boolean
                // });

                // let farbe = AppConfig.current.einstellungen.Farbe1;

                // if (farbe) {
                //     if (farbe.startsWith('#')) {
                //         farbe = farbe.substr(1);
                //     }

                //     this.backgroundMode.configure({ color: farbe });
                // }

                // this.backgroundMode.configure({ color: 'ff0000' });

                // Override the back button on Android to go to background instead of closing the app.
                // this.log.info('backgroundMode overrideBackButton');
                // this.backgroundMode.overrideBackButton();

                this.log.info('backgroundMode acquire powerManagement');
                this.powerManagement.acquire()
                    .then(res => this.log.info('Wakelock aquire'))
                    .catch(res => this.log.warn('Error Wakelock acquired'));

                this.powerManagement.setReleaseOnPause(false)
                    .then(res => this.log.info('setReleaseOnPause successfully'))
                    .catch(res => this.log.warn('Failed to set ReleaseOnPause'));

            } else {
                this.log.info("Background-Mode ist deaktiviert");
            }
        } catch (err) {
            this.log.warn('initBackgroundMode', err);
        }

        this.log.debug('initBackgroundMode done');
    }

    moveToBackground() {
        this.log.info('moveToBackground');

        try {
            this.backgroundMode.moveToBackground();
        } catch (err) {
            this.log.warn('moveToBackground', err);
        }
    }

    // moveToForeground() {
    //     this.log.info('moveToForeground');

    //     try {
    //         if (this.backgroundMode.isActive()) {
    //             // Turn screen on
    //             this.backgroundMode.wakeUp();

    //             // Turn screen on and show app even locked
    //             this.backgroundMode.unlock();
    //         }
    //     } catch (err) {
    //         this.log.error('moveToForeground', err);
    //     }
    // }

    async initRingtones() {
        this.log.debug('initRingtones');

        try {
            if (!this.isCordovaAvailable()) {
                this.log.warn('Cordova nicht verfügbar. Ringtones werden nicht geladen');
                this.verfuegbareKlingeltoene = [];
                return;
            }

            this.ringtonesInitialisiert = true;

            const result1 = await this.nativeAudio.preloadComplex('1', 'assets/ringtones/1.mp3', 1, 1, 0);
            const result2 = await this.nativeAudio.preloadComplex('2', 'assets/ringtones/2.mp3', 1, 1, 0);
            const result3 = await this.nativeAudio.preloadComplex('3', 'assets/ringtones/3.mp3', 1, 1, 0);
            const result4 = await this.nativeAudio.preloadComplex('definite', 'assets/ringtones/definite.mp3', 1, 1, 0);

            this.log.debug('init ringtones result', {
                result1,
                result2,
                result3,
                result4,
            });

        } catch (err) {
            this.log.error('initRingtones', err);
        }
    }

    getVerfuegbareKlingeltoene(): Ringtone[] {
        return this.verfuegbareKlingeltoene;
    }

    async beepFuerNeueNachricht() {
        this.log.debug('beepFuerNeueNachricht');

        if (!this.isCordovaAvailable()) {
            this.log.debug('beepFuerNeueNachricht (corova nicht verfügbar)');
            return;
        }

        try {
            // if (!this.ringtonesInitialisiert) {
            //     this.log.debug('beepFuerNeueNachricht (ringtones nicht initialisiert)');
            //     await this.initRingtones();
            // }

            (navigator as any).notification.beep(1);

            // const assetId = 'definite';
            // const volume = App.current.NachrichtenSoundLautstaerke.getValue() / 100.0;

            // this.nativeAudio.setVolumeForComplexAsset(assetId, volume);

            // await this.nativeAudio.play(assetId, () => { });
        } catch (err) {
            this.log.error('beepFuerNeueNachricht: ' + Utils.getErrorMessage(err));
        }
    }

    async starteKlingeln() {
        this.log.info(`starteKlingeln`);

        if (!this.isCordovaAvailable()) {
            return;
        }

        try {
            if (!this.ringtonesInitialisiert) {
                await this.initRingtones();
            }

            this.log.info(`starteKlingeln: 2`);
            await this.nativeAudio.loop('2');
        } catch (err) {
            this.log.error('starteKlingeln', err);
        }
    }

    async stoppeKlingeln() {
        this.log.info(`stoppeKlingeln`);

        if (!this.isCordovaAvailable()) {
            return;
        }

        try {
            if (!this.ringtonesInitialisiert) {
                await this.initRingtones();
            }

            this.log.debug(`stoppeKlingeln: 2`);
            await this.nativeAudio.stop('2');
        } catch (err) {
            this.log.warn('stoppeKlingeln', err);
        }
    }

    async initLocalNotifications(): Promise<void> {
        this.log.info('initLocalNotifications');

        try {
            const cordovaAvailable = this.platform.is('cordova');

            if (!cordovaAvailable) {
                this.log.warn('Cordova nicht verfügbar. Local notifications werden nicht aktiviert');
                return;
            }

            const localNotifications = (cordova.plugins as any).notification?.local;
            const hasPermission = await localNotifications.hasPermission();

            if (!hasPermission) {
                await localNotifications.requestPermission();
            }

            this.log.debug('initLocalNotifications done');
        } catch (err) {
            this.log.error('initLocalNotifications: ' + Utils.getErrorMessage(err));
        }
    }

    async initAutostart() {
        this.log.info('initFileDownload');

        try {
            if (!this.isCordovaAvailable()) {
                this.log.warn('Cordova nicht verfügbar. Autostart wird nicht aktiviert');
                return;
            }

            this.autostart.enable();
        } catch (err) {
            this.log.error('initAutostart', err);
        }

        this.log.debug('initFileDownload done');
    }

    async initPushNotifications(): Promise<void> {
        this.log.info('initPushNotifications');

        if (!AppConfig.current.mandant) {
            this.log.debug('initPushNotifications: Push noch nicht registrieren, da Mandant nicht gesetzt');
            return;
        }

        if (!AppConfig.current.geraeteNummer) {
            this.log.debug('initPushNotifications: Push noch nicht registrieren, da Gerät nicht gesetzt');
            return;
        }

        if (this.pushRegistration) {
            this.log.debug('initPushNotifications: Push-Registration bereits vorhanden');

            if (AppConfig.current.mandant == this.pushRegistrationMandant) {
                if (AppConfig.current.geraeteNummer === this.pushRegistrationGeraeteNummer) {
                    this.log.debug('initPushNotifications: Push-Registration bereits für Mandant und Gerät registriert');
                    return;
                }
            }

            this.processPushRegistration(this.pushRegistration);
            return;
        }

        try {
            const cordovaAvailable = this.platform.is('cordova');

            if (!cordovaAvailable) {
                this.log.warn('Cordova nicht verfügbar. Push wird nicht aktiviert');
                return;
            }

            // to check if we have permission
            const res = await this.push.hasPermission();

            if (!res.isEnabled) {
                this.log.warn('push nicht verfügbar. no permission');
                // return;
            }

            this.log.info('hasPermission result', res);

            // Es können unterschiedliche Channels eingerichtet werden, mit unterschiedlicher Prio.
            // Brauchen wir eigentlich nicht
            const channel = await this.push.createChannel({
                id: "PushPluginChannel",
                description: "ReCoMobil channel",
                // The importance property goes from 1 = Lowest, 2 = Low, 3 = Normal, 4 = High and 5 = Highest.
                importance: 5,
                vibration: true,
                visibility: 1
            });

            // this.log.info('channel', channel);

            this.pushAvailable.next(true);

            const options: PushOptions = {
                android: {
                    //  Maps to the project number in the Google Developer Console.
                    senderID: undefined,

                    // If true the device vibrates on receipt of notification.
                    vibrate: true,

                    //  If true it plays the sound specified in the push data or the default system sound
                    sound: true,

                    // If true the icon badge will be cleared on init and before push messages are processed.
                    clearBadge: false,

                    // If true will always show a notification, even when the app is on the foreground
                    forceShow: false,

                    // The key to search for text of notification.
                    messageKey: undefined,

                    // The key to search for title of notification.
                    titleKey: undefined
                },
                ios: {
                    alert: 'true',
                    badge: 'true',
                    sound: 'false'
                },
                windows: {},
                browser: {
                    pushServiceURL: 'http://push.api.phonegap.com/v1/push'
                }
            };

            const pushObject: PushObject = this.push.init(options);

            this.log.debug('pushObject', pushObject);

            pushObject.on('notification').subscribe((notification: any) => this.processPushNotification(notification));

            pushObject.on('registration').subscribe((registration: any) => this.processPushRegistration(registration));

            pushObject.on('error').subscribe(error => this.processPushError(error));
        } catch (err) {
            this.log.error('error initializing push: ' + err);
        }

        this.log.debug('initPushNotifications done');
    }

    async processPushRegistration(registration: PushRegistration) {
        this.log.info('push registration', registration);

        this.pushRegistration = registration;

        // App wurde am Push-Dienst registriert. Jetzt muss die registrationId an den Server geschickt werden
        const deviceId = await this.getDeviceId();
        const platform = this.getPlatform();

        try {
            const result = await this.remoteService.registerPush({
                registrationId: registration.registrationId,
                registrationType: registration.registrationType,
                uniqueDeviceId: deviceId,
                platform
            });

            if (result.success) {
                this.pushRegistrationMandant = AppConfig.current.mandant;
                this.pushRegistrationGeraeteNummer = AppConfig.current.geraeteNummer;
            } else {
                this.pushRegistrationMandant = null;
                this.pushRegistrationGeraeteNummer = null;
            }

            this.pushAvailable.next(true);
            this.pushRegistrationId.next(registration.registrationId);
            this.pushRegistrationType.next(registration.registrationType);
        } catch (error) {
            this.log.error('processPushRegistration', error);
            this.pushAvailable.next(false);
            this.pushRegistrationId.next('');
            this.pushRegistrationType.next('');
            this.pushRegistrationMandant = null;
            this.pushRegistrationGeraeteNummer = null;
        }
    }

    processPushNotification(notification: NotificationEventResponse) {
        this.log.info('push notification', notification);

        // Selbst verarbeiten ?
        if (notification.additionalData) {
            const typ = notification.additionalData.typ;

            if (typ === "check-update") {
                this.checkUpdate(false, true);
                return;
            }
        }

        this.pushNotificationEmpfangen.next(notification);
    }

    processPushError(error: any) {
        this.log.info('push error', error);
    }

    async getDeviceId(): Promise<string> {
        if (this.deviceId) {
            return this.deviceId;
        }

        this.log.info('getDeviceId start');

        try {
            this.deviceId = await AppStorage.current.get('deviceId', true, true);

            if (!this.deviceId) {
                this.deviceId = Utils.uuid(); // Math.floor(Math.random() * Math.floor(99999999)).toString();
            }

            await AppStorage.current.set('deviceId', this.deviceId, true, true);

            // if (this.isCordovaAvailable()) {
            //     this.log.info('getDeviceId uniqueDeviceID.get');
            //     this.deviceId = await this.uniqueDeviceID.get();
            // } else {
            //     this.log.info('load deviceId from storage');
            // }
        } catch (err) {
            this.deviceId = 'unknown';
            this.log.error(`error getting unique device id: ${err}. using device id=${this.deviceId}`);
        }

        this.log.info('deviceId: ' + this.deviceId);

        return this.deviceId;
    }

    isCordovaAvailable(): boolean {
        return App.isCordovaAvailable();
    }

    getPlatform(): string {
        if (this.platform.is('ios')) {
            return 'ios';
        } else if (this.platform.is('android')) {
            return 'android';
        } else if (this.platform.is('desktop')) {
            return 'desktop';
        } else {
            return this.platform.platforms().join();
        }
    }

    get websocketEnabled() {
        // TODO: kann erst deaktiviert werden, wenn GPS-Positionsdaten nicht mehr über WebSocket gesendet werden
        return true;

        // const platform = this.getPlatform();
        // return platform != "android";
    }

    async sendeDiagnosedaten(): Promise<void> {
        this.log.info('sendeDiagnosedaten');

        const fahrerListe = await AppStorage.current.get('fahrer', true, false);
        const fahrzeugeListe = await AppStorage.current.get('fahrzeuge', true, false);

        let alleAuftraege: AuftragListItem[];

        if (AppService.auftrag) {
            alleAuftraege = AppService.auftrag.alleAuftraege;
        }

        let textdatei: Textdatei = {
            typ: TextdateiTyp.Diagnosedaten,
            datum: moment().toISOString(),
            geraeteNummer: AppConfig.current.geraeteNummer,
            diagnosedaten: {
                console: {
                    logs: AppConsole.consoleLogs
                },
                verfuegbareKlingeltoene: this.getVerfuegbareKlingeltoene().map(p => ({ name: p.Name, url: p.Url })),
                config: AppConfig.current,
                pushAvailable: this.pushAvailable.getValue(),
                pushRegistrationId: this.pushRegistrationId.getValue(),
                pushRegistrationType: this.pushRegistrationType.getValue(),
                webSocketStatus: this.webSocketStatus.getValue(),
                app: {
                    tourStartenErforderlich: App.current.tourStartenErforderlich.getValue(),
                    transportauftraegeVerfuegbar: App.current.transportauftraegeVerfuegbar.getValue(),
                    hofauftraegeVerfuegbar: App.current.hofauftraegeVerfuegbar.getValue(),
                    nachrichtenVerfuegbar: App.current.nachrichtenVerfuegbar.getValue(),
                    positionenAufzeichnen: App.current.positionenAufzeichnen.getValue(),
                    positionenSendenIntervall: App.current.positionenSendenIntervall.getValue(),
                    positionenAufzeichnenIntervall: App.current.positionenAufzeichnenIntervall.getValue(),
                    fahrerAngemeldet: App.current.fahrerAngemeldet.getValue(),
                    tourGestartet: App.current.tourGestartet.getValue(),
                    abfahrtskontrolleFertig: App.current.abfahrtskontrolleFertig.getValue(),
                    fahrer: App.current.fahrer.getValue(),
                    fahrzeug: App.current.fahrzeug.getValue(),
                    anhaenger: App.current.anhaenger.getValue(),
                    anmeldungErforderlich: App.current.anmeldungErforderlich.getValue(),
                    aktuelleTour: App.current.aktuelleTour.getValue(),
                    geladen: App.current.geladen.getValue(),
                    alleAuftraege,
                },
                stammdaten: {
                    fahrer: fahrerListe,
                    fahrzeuge: fahrzeugeListe
                }
            }
        };

        let json = JSON.stringify(textdatei);
        let zipped: ArrayBuffer = pako.deflate(json);

        await this.remoteService.sendTextdateiZipped(zipped);

        // Sende für jede Log-Datei eine Diagnosedatei
        if (Logger.fileLogger && Logger.fileLoggerAktiv) {
            try {
                const logFiles = await Logger.fileLogger.getLogFiles();

                this.log.debug('logFiles count: ' + logFiles.length);

                for (const logEntry of logFiles) {
                    let lines = [];

                    try {
                        const directory = FileHelper.getDirectory(logEntry.nativeURL);
                        let filename = FileHelper.getFilename(logEntry.nativeURL);

                        if (directory && filename) {
                            filename = filename.replace('%20', ' ');

                            const content = await this.file.readAsText(directory, filename);

                            if (content) {
                                lines = content.match(/[^\r\n]+/g);
                            }
                        }

                        textdatei = {
                            typ: TextdateiTyp.LogDatei,
                            datum: moment().toISOString(),
                            geraeteNummer: AppConfig.current.geraeteNummer,
                            diagnosedaten: {
                                filename,
                                fullPath: logEntry.fullPath,
                                lines,
                            }
                        };

                        json = JSON.stringify(textdatei);
                        zipped = pako.deflate(json);

                        await this.remoteService.sendTextdateiZipped(zipped);
                    } catch (err2) {
                        this.log.warn('Fehler beim Lesen der Log-Dateien: ' + Utils.getErrorMessage(err2));
                    }
                }
            } catch (err) {
                this.log.warn('Fehler beim Lesen der Log-Dateien: ' + Utils.getErrorMessage(err));
            }
        }
    }

    private pingWebSocket(force = false) {
        if (!this.websocketEnabled) {
            return;
        }

        if (this.webSocket && this.webSocket.readyState === WebSocket.OPEN) {
            const pingIntervall = parseInt(AppConfig.current.einstellungen.WebSocketClientPingIntervall, 10);
            const reconnectTimeout = parseInt(AppConfig.current.einstellungen.WebSocketClientTimeout, 10);

            // Achtung: Die Methode pingWebSocket wird nur alle 60 Sekunden aufgerufen. Deshalb hier prüfen ob der letzte Ping 100 Sekunden her ist.
            // Das sollte somit alle 120 Sekunden einen Ping auslösen
            const timeout = Date.now() - (pingIntervall * 1000);

            if (this.lastMessageReceived < timeout || force) {
                this.sendWebSocketMessage({ t: 1 }, 'ping');
            }

            // Der ReConnect-Timeout darf auf jeden Fall nicht im Selben Takt zuschlagen wir der Ping.
            // Wurden 4.9 Minuten (also fast 5 Minuten) lange keine Nachricht empfangen, dann erzwinge ein Reconnect
            const reconnectDate = Date.now() - (reconnectTimeout * 1000);

            if (this.lastMessageReceived < reconnectDate) {
                this.log.warn('Reconnecting WebSocket. lastMessageReceived zu alt: ' + this.lastMessageReceived + ", now=" + Date.now());
                this.initWebSocket('pingWebSocket 1');
            }
        } else {
            this.initWebSocket('pingWebSocket 2');
        }
    }

    public async sendWebSocketMessage(obj: any, info: string): Promise<boolean> {
        this.log.debug('sendWebSocketMessage: ' + info, obj);

        if (!this.websocketEnabled) {
            this.log.debug('websocket disabled');
            return;
        }

        if (!this.webSocket) {
            this.log.info('sendWebSocketMessage ' + info + ': nachricht kann nicht gesendet werden. webSocket == NULL', obj);
            return false;
        }

        if (this.webSocket.readyState !== WebSocket.OPEN) {
            this.log.info('sendWebSocketMessage ' + info + ': nachricht kann nicht gesendet werden. readyState=' + this.webSocket.readyState, obj);
            return false;
        }

        this.lastMessageSend = Date.now();

        if (this.worker) {
            // Übergebe die Nachricht an den WebWorker. Er soll sie komprimieren und die Nachricht zum versenden vorbereiten
            this.worker.postMessage({
                type: 'sendWebSocketMessage',
                data: obj
            });
        } else {
            // Web-Worker nicht verfügbar. Selbst Daten vorbereiten
            this.onWorkerMessage({
                type: 'sendWebSocketMessage',
                data: WebWorkerCode.prepareWebSocketMessage(obj)
            })
        }

        return true;
    }

    private verarbeiteWebSocketMessage(json: string) {
        this.log.debug('verarbeiteWebSocketMessage: ' + json);

        this.lastMessageReceived = Date.now();

        try {
            const obj = JSON.parse(json) as PushMessageHeader;

            if (obj.ackId) {
                this.sendWebSocketMessage({ t: PushMessageType.Ack, ackId: obj.ackId }, 'ACK');
            }

            switch (obj.t) {
                case PushMessageType.Ping:
                    this.log.debug('PING received');
                    this.sendWebSocketMessage({ t: 2 }, 'pong');
                    break;
                case PushMessageType.Pong:
                    this.log.debug('PONG received');
                    break;
                case PushMessageType.StatusUpdate:
                    this.verarbeiteStatusUpdate(obj as StatusUpdatePushMessage);
                    break;
                case PushMessageType.DiagnosedatenAnfordern:
                    this.verarbeiteDiagnosedatenAnfordern();
                    break;
                case PushMessageType.AuftragUpdate:
                    this.auftragUpdate.next(obj as AuftragUpdatePushMessage);
                    break;
                case PushMessageType.Hello:
                    this.sendeWebSocketLoginMessage();
                    break;

                default:
                    this.webSocketNachrichtEmpfangen.next(obj);
            }
        } catch (err) {
            this.log.error('Fehler beim verarbeiten der web socket nachricht', err);
        }
    }

    private verarbeiteStatusUpdate(message: StatusUpdatePushMessage) {
        this.log.debug('verarbeiteStatusUpdate', message);

        if (message.a) {
            this.auftraegeAktualisieren.next();
        }

        if (message.s) {
            this.stammdatenAktualisieren.next();
        }

        if (message.e) {
            this.einstellungenAktualisieren.next();
        }

        if (message.d) {
            this.fahrerAktualisieren.next();
        }

        if (message.f) {
            this.fahrzeugeAktualisieren.next();
        }

        if (message.uvv) {
            this.uvvAktualisieren.next();
        }

        if (message.n) {
            this.nachrichtenAktualisieren.next();
        }

        if (message.tour) {
            this.tourenAktualisieren.next();
        }

        if (message.changelog) {
            this.changelogAktualisieren.next();
        }

        if (message.texte) {
            this.texteAktualisieren.next();
        }
    }

    private async sendeGeoPositionen() {
        this.log.debug('sendeGeoPositionen');

        const backgroundGeolocation = (window as any).BackgroundGeolocation as BackgroundGeolocationPlugin;

        if (!App.isCordovaAvailable()) {
            this.log.warn('Cordova nicht verfübar. Aktuelle GPS-Position kann nicht gesendet werden');
            return;
        }

        if (!backgroundGeolocation) {
            this.log.warn('backgroundGeolocation nicht verfügbar');
            return;
        }

        const intervall = App.current.positionenSendenIntervall.getValue();

        if (intervall <= 0) {
            // Positionen sollen nicht gesendet werden
            backgroundGeolocation.deleteAllLocations();
            return;
        }

        if (!this.webSocketConnected.getValue()) {
            this.log.debug('WebSocket nicht verbunden. GPS-Positionen werden nicht gesendet');
            return;
        }

        // const timeout = Date.now() - (intervall * 1000);

        // if (this.geoPositionenGesendet > timeout) {
        //     return;
        // }

        backgroundGeolocation.getValidLocations(async (geoPositionen) => {
            this.log.debug('geoPositionen length: ' + geoPositionen.length);

            if (!geoPositionen.length) {
                return;
            }

            this.geoPositionenGesendet = Date.now();

            let batchSize = 100;
            let sendenErfolgreich = false;

            while (geoPositionen.length > 0) {
                let subList = geoPositionen.splice(0, batchSize);

                this.log.debug('Sende GPS-Positionen: ' + subList.length);

                const message: GeoPositionenPushMessage = {
                    t: PushMessageType.Positionen,
                    i: []
                };

                const roundFaktor = 1000000;

                for (const p of subList) {
                    message.i.push({
                        a: Math.round(p.latitude * roundFaktor) / roundFaktor,
                        b: Math.round(p.longitude * roundFaktor) / roundFaktor,
                        s: p.time,
                        u: p.accuracy,
                        v: p.speed,
                        w: p.bearing,
                        // p: p.provider
                    });
                }

                try {
                    // Sende per WebSocket
                    const ok = await this.sendWebSocketMessage(message, 'gps-positionen');

                    this.log.debug('Sende GPS-Positionen Result: ' + ok);

                    if (ok) {
                        sendenErfolgreich = true;
                    } else {
                        break;
                    }
                } catch (err) {
                    this.log.warn('Fehler bein Senden der GPS-Positionen: ' + Utils.getErrorMessage(err));
                }
            }

            if (sendenErfolgreich) {
                this.log.warn('Senden der GPS-Positionen erfolgreich. Lösche alle gespeicherten Positionen');

                try {
                    backgroundGeolocation.deleteAllLocations(
                        () => {
                            this.log.debug('deleteAllLocations erfolgreich');
                        },
                        (e) => {
                            this.log.warn('deleteAllLocations fehlgeschlagen: ' + Utils.getErrorMessage(e), e);
                        });
                } catch (err) {
                    this.log.warn('Fehler bein Löschen der GPS-Positionen: ' + Utils.getErrorMessage(err));
                }
            } else {
                this.log.warn('Senden der GPS-Positionen war nicht erfolgreich');
            }

        }, fail => {
            this.log.error('getValidLocations failed: ' + fail.code + ' ' + fail.message);
        });
    }

    private checkGeolocationService() {
        if (!App.isCordovaAvailable()) {
            return;
        }

        const backgroundGeolocation = (window as any).BackgroundGeolocation as BackgroundGeolocationPlugin;

        if (!App.current.positionenAufzeichnen.getValue()) {
            return;
        }

        backgroundGeolocation.checkStatus(status => {
            if (!status.isRunning) {
                this.log.debug('backgroundGeolocation service not running. starting...');
                backgroundGeolocation.start(); // triggers start on start event
            }
        });
    }

    private verarbeiteDiagnosedatenAnfordern() {
        this.log.info('verarbeiteDiagnosedatenAnfordern');

        try {
            this.sendeDiagnosedaten();
        } catch (err) {
            this.log.error('verarbeiteDiagnosedatenAnfordern', err);
        }
    }

    /**
     * Returns if we are connected to the server
     */
    isConnectedToServer(): boolean {
        return this.webSocketConnected.getValue();
    }

    isInternetAvailable(): boolean {
        return App.internetAvailable.getValue();
    }

    /**
     * Diese Methode wird alle 60 Sekunden aufgerufen
     */
    update(info: string) {
        this.log.debug('update: ' + info);

        try {
            if (!App.internetAvailable.getValue()) {
                if (this.webSocketConnected.getValue()) {
                    App.internetAvailable.next(true);
                }

                if (App.isCordovaAvailable()) {
                    this.log.debug('Network: ' + this.network.Connection);
                }
            }
        } catch (err) {
            this.log.error('sendePostausgang', err);
        }

        try {
            if (App.internetAvailable.getValue()) {
                this.sendePostausgang();
            }
        } catch (err) {
            this.log.error('sendePostausgang', err);
        }

        try {
            if (this.websocketEnabled) {
                this.pingWebSocket();
            }
        } catch (err) {
            this.log.error('pingWebSocket', err);
        }

        try {
            this.checkGeolocationService();
        } catch (err) {
            this.log.error('update checkGeolocationService', err);
        }

        try {
            this.downloadQueuedFiles();
        } catch (err) {
            this.log.error('update downloadQueuedFiles', err);
        }

        try {
            if (AppConfig.current.einstellungen.AppUpdateAktiv === 'ja') {
                // Alle 12 Stunden auf ein Update prüfen
                if (this.lastUpdateCheck < Date.now() - 12 * 60 * 60 * 1000) {
                    if (this.istImVordergrund.getValue() && this.isInternetAvailable()) {
                        this.lastUpdateCheck = Date.now();
                        this.checkUpdate(false);
                    }
                }
            }
        } catch (err) {
            this.log.error('update update check', err);
        }

        this.aktualisiereEinstellungenWennNotwendig();
    }

    async enqueueDownloadFiles(downloadFileList: DownloadFile[]) {
        this.log.info('enqueueDownloadFiles', downloadFileList);

        if (!this.filedownloadQueue) {
            this.filedownloadQueue = [];
        }

        if (!downloadFileList?.length) {
            return;
        }

        for (const file of downloadFileList) {
            if (this.filedownloadQueue.find(p => p.guid == file.guid && p.filePath == file.filePath)) {
                // wird schon heruntergeladen...
                this.log.debug('Datei wird schon heruntergeladen', file);
                continue;
            }

            this.filedownloadQueue.push(file);
        }

        await AppStorage.current.set('download-file-queue', this.filedownloadQueue);

        setTimeout(() => this.downloadQueuedFiles(), 500);
    }

    async downloadFile(downloadFile: DownloadFile): Promise<void> {
        this.log.info("downloadFile: " + downloadFile.filePath, downloadFile);

        const url = this.remoteService.toAbsoluteUrl('/api/app/file/' + downloadFile.guid);

        if (App.isCordovaAvailable()) {
            const fileTransferObject = this.fileTransfer.create();

            const fileEntry = await fileTransferObject.download(url, downloadFile.filePath, true, {
                headers: {
                    'X-Token': AppConfig.current.token
                }
            });

            this.log.info("Download erfolgreich: " + downloadFile.filePath);
        }
    }

    async downloadQueuedFiles() {
        if (this.downloadRunning) {
            return;
        }

        if (!this.filedownloadQueue || !this.filedownloadQueue.length) {
            return;
        }

        if (!this.isInternetAvailable()) {
            return;
        }

        if (this.filedownloadQueue.length > 0) {
            const downloadFile = this.filedownloadQueue.pop();

            try {
                const fileExists = await FileHelper.checkFile(downloadFile.filePath);

                if (!fileExists) {
                    const url = this.remoteService.toAbsoluteUrl('/api/app/file/' + downloadFile.guid);

                    const fileTransferObject = this.fileTransfer.create();

                    const fileEntry = await fileTransferObject.download(url, downloadFile.filePath, true, {
                        headers: {
                            'X-Token': AppConfig.current.token
                        }
                    });

                    this.log.info("Download erfolgreich: " + downloadFile.filePath);
                } else {
                    this.log.info("Datei existiert bereits. Downlaod nicht notwendig: " + downloadFile.filePath);
                }

                await AppStorage.current.set('download-file-queue', this.filedownloadQueue);

                // Nächste Datei herunterladen
                setTimeout(() => this.downloadQueuedFiles(), 100);
            } catch (err) {
                this.log.warn("Download fehlgeschlagen " + downloadFile.filename + ': ' + JSON.stringify(err));

                downloadFile.errorCount++;

                if (err.http_status) {
                    const httpStatus = err.http_status as number;

                    if (httpStatus == 404) {
                        // Datei ist auf dem Server nicht vorhanden. Nicht nochmal probieren
                        downloadFile.errorCount = 1000;
                    }
                }

                if (downloadFile.errorCount < 10) {
                    this.filedownloadQueue.push(downloadFile);
                }

                await AppStorage.current.set('download-file-queue', this.filedownloadQueue);

                // Nächste Datei herunterladen
                setTimeout(() => this.downloadQueuedFiles(), 5000);
            }
        }

        this.downloadRunning = false;
    }

    anrufenTelefonnummer(telNummer: string) {
        this.log.info('anrufenTelefonnummer: ' + telNummer);

        if (!telNummer) {
            return;
        }

        telNummer = Utils.normalisiereTelefonnummer(telNummer);

        const link = document.createElement("a");
        link.href = "tel:" + telNummer;
        link.click();
    }

    async requestGeolocationPermissions(): Promise<void> {
        await this.requestPermissions(
            'android.permission.ACCESS_BACKGROUND_LOCATION',
            'android.permission.ACCESS_FINE_LOCATION',
            'android.permission.REQUEST_IGNORE_BATTERY_OPTIMIZATIONS',
            // 'android.permission.ACCESS_MEDIA_LOCATION',
            // 'android.permission.WRITE_EXTERNAL_STORAGE'
        );
    }

    async requestPermissions(...permissions: string[]): Promise<void> {
        if (!App.isCordovaAvailable()) {
            this.log.warn("requestPermissions: Cordova nicht verfügbar.")
            return;
        }

        for (const permission of permissions) {
            let hasPermission = false;

            if (!permission) {
                this.log.warn(`Unbekannte Permission '${permission}' in array: ` + permissions.join(', '));
                continue;
            }

            try {
                const result = await this.androidPermissions.checkPermission(permission);
                hasPermission = result.hasPermission;

                this.log.info('checkPermission ' + permission + ': ' + hasPermission);
            } catch (err) {
                this.log.error('checkPermission ' + permission + ': ' + Utils.getErrorMessage(err));
                hasPermission = false;
            }

            if (!hasPermission) {
                try {
                    this.log.info('Requesting permission: ' + permission);

                    const result = await this.androidPermissions.requestPermission(permission);

                    this.log.info('requestPermission ' + permission + ': ' + result.hasPermission);
                } catch (err) {
                    this.log.error('requestPermission ' + permission + ': ' + Utils.getErrorMessage(err));
                }
            }
        }
    }

    async startBackgroundGeolocation() {
        this.log.info('startBackgroundGeolocation');

        await this.requestGeolocationPermissions();

        try {
            if (!App.isCordovaAvailable()) {
                this.log.warn('Cordova nicht verfügbar: Background Geolocation wird nicht aktiviert');
                return;
            }

            await App.ready();

            this.log.info('startBackgroundGeolocation app ready');

            // Etwas warten bis der Geolocation-Service aktiviert wird.
            // Es sollen erst andere Einstellungen geladen werden
            await Utils.delay(5000);

            this.log.info('startBackgroundGeolocation delay finished');

            const backgroundGeolocation = (window as any).BackgroundGeolocation as BackgroundGeolocationPlugin;

            const config = {
                locationProvider: this.getLocationProvider(),

                // Desired accuracy in meters. Possible values [HIGH_ACCURACY, MEDIUM_ACCURACY, LOW_ACCURACY, PASSIVE_ACCURACY].
                // Accuracy has direct effect on power drain. Lower accuracy = lower power drain.
                desiredAccuracy: App.current.PositionenDesiredAccuracy.getValue(),

                // Stationary radius in meters. When stopped, the minimum distance the device must move beyond the stationary
                // location for aggressive background-tracking to engage.
                stationaryRadius: App.current.PositionenStationaryRadius.getValue(),

                // The minimum distance (measured in meters) a device must move horizontally before an update event is generated. @see Apple docs.
                distanceFilter: App.current.PositionenDistanceFilter.getValue(),

                notificationTitle: 'ReCoMobil GPS-Dienst',
                notificationText: 'GPS-Dienst ist aktiv',

                // When enabled, the plugin will emit sounds for life-cycle events of background-geolocation! See debugging sounds table.
                debug: App.current.PositionenDebugAktiv.getValue(),

                // The minimum time interval between location updates in milliseconds. @see Android docs for more information.
                interval: App.current.positionenAufzeichnenIntervall.getValue() * 1000,

                // Fastest rate in milliseconds at which your app can handle location updates. @see Android docs.
                fastestInterval: App.current.positionenAufzeichnenIntervall.getValue() * 1000 / 2,

                // Rate in milliseconds at which activity recognition occurs. Larger values will result in fewer activity detections while improving battery life.
                activitiesInterval: App.current.PositionenActivitiesInterval.getValue() * 1000,

                // Enable this in order to force a stop() when the application terminated (e.g. on iOS, double-tap home button, swipe away the app).
                stopOnTerminate: !App.current.PositionenAufzeichnenAktivHalten.getValue(),

                // Limit maximum number of locations stored into db
                maxLocations: 10000
            };

            this.log.info('startBackgroundGeolocation configuration: ' + JSON.stringify(config));

            backgroundGeolocation.configure(config);

            backgroundGeolocation.on('error', (error) => {
                this.log.warn('backgroundGeolocation error: ' + Utils.getErrorMessage(error, true));
            });

            backgroundGeolocation.on('start', () => {
                this.log.debug('backgroundGeolocation start');
            });

            backgroundGeolocation.on('stop', () => {
                this.log.debug('backgroundGeolocation stop');
            });

            backgroundGeolocation.on('background', () => {
                this.log.debug('backgroundGeolocation background');
            });

            backgroundGeolocation.on('foreground', () => {
                this.log.debug('backgroundGeolocation foreground');
            });

            if (Logger.globalLogLevel <= LogLevel.DEBUG) {
                backgroundGeolocation.on('location', (gpsLocation: GpsLocation) => {
                    this.log.debug(`backgroundGeolocation location: lat=${gpsLocation.latitude}, lon=${gpsLocation.longitude}, t=${gpsLocation.time}, acc=${gpsLocation.accuracy}, provider=${gpsLocation.provider}`);

                    // handle your locations here
                    // to perform long running operation on iOS
                    // you need to create background task
                    // backgroundGeolocation.startTask((taskKey) => {
                    //     this.log.debug('backgroundGeolocation startTask', taskKey);

                    //     // execute long running task
                    //     // eg. ajax post location
                    //     // IMPORTANT: task has to be ended by endTask
                    //     backgroundGeolocation.endTask(taskKey);
                    // });
                });

                backgroundGeolocation.on('stationary', (gpsLocation) => {
                    this.log.debug(`backgroundGeolocation stationary`);
                });

                backgroundGeolocation.on('abort_requested', () => {
                    this.log.debug('backgroundGeolocation abort_requested');
                });

                backgroundGeolocation.on('http_authorization', () => {
                    this.log.debug('backgroundGeolocation http_authorization');
                });
            }

            backgroundGeolocation.on('authorization', (status) => {
                this.log.debug('backgroundGeolocation authorization');

                if (status !== backgroundGeolocation.AUTHORIZED) {
                    // we need to set delay or otherwise alert may not be shown
                    setTimeout(() => {
                        try {
                            const showSettings = confirm('ReCoMobil benötigt Zugriff auf GPS-Koordinaten. Einstellungen jetzt öffnen?');
                            if (showSettings) {
                                return backgroundGeolocation.showAppSettings();
                            }
                        } catch (err) {
                            this.log.error('setTimeout authorization: ' + Utils.getErrorMessage(err), err);
                        }
                    }, 1000);
                }
            });

            App.current.positionenAufzeichnen.subscribe(p => {
                if (p != this.positionenAufzeichnenAktiv) {
                    if (p) {
                        this.log.debug('Background geolocation start');

                        const newConfig = {
                            locationProvider: this.getLocationProvider(),
                            desiredAccuracy: App.current.PositionenDesiredAccuracy.getValue(),
                            stationaryRadius: App.current.PositionenStationaryRadius.getValue(),
                            distanceFilter: App.current.PositionenDistanceFilter.getValue(),
                            debug: App.current.PositionenDebugAktiv.getValue(),
                            interval: App.current.positionenAufzeichnenIntervall.getValue() * 1000,
                            fastestInterval: App.current.positionenAufzeichnenIntervall.getValue() * 1000 / 2,
                            activitiesInterval: App.current.PositionenActivitiesInterval.getValue() * 1000,
                            stopOnTerminate: !App.current.PositionenAufzeichnenAktivHalten.getValue()
                        };

                        this.log.info('startBackgroundGeolocation update configuration: ' + JSON.stringify(newConfig));

                        // Konfiguration aktualisieren
                        backgroundGeolocation.configure(newConfig);

                        backgroundGeolocation.start();
                    } else {
                        this.log.debug('Background geolocation stop');
                        backgroundGeolocation.stop();
                    }
                }
            });

            App.current.configRefreshed.subscribe(p => {
                this.updatePositionenSendenInterval();
            });

            this.updatePositionenSendenInterval();

            this.log.debug('startBackgroundGeolocation done');
        } catch (err) {
            this.log.error('startBackgroundGeolocation', err);
        }
    }

    updatePositionenSendenInterval() {
        if (this.positionenSendenIntervalId) {
            clearInterval(this.positionenSendenIntervalId);
            this.positionenSendenIntervalId = null;
        }

        if (App.current.positionenAufzeichnen.getValue()) {
            const intervall = App.current.positionenSendenIntervall.getValue() * 1000;

            if (intervall > 0) {
                this.positionenSendenIntervalId = setInterval(() => {
                    try {
                        this.sendeGeoPositionen();
                    } catch (err) {
                        this.log.error('update sendeGeoPositionen', err);
                    }
                }, intervall);
            }
        }
    }

    private getLocationProvider() {
        const backgroundGeolocation = (window as any).BackgroundGeolocation as BackgroundGeolocationPlugin;

        let locationProvider;

        if (AppConfig.current.einstellungen.PositionenLocationProvider == 'DISTANCE_FILTER_PROVIDER') {
            locationProvider = backgroundGeolocation.DISTANCE_FILTER_PROVIDER;
        } else if (AppConfig.current.einstellungen.PositionenLocationProvider == 'ACTIVITY_PROVIDER') {
            locationProvider = backgroundGeolocation.ACTIVITY_PROVIDER;
        } else {
            locationProvider = backgroundGeolocation.RAW_PROVIDER;
        }

        return locationProvider;
    }

    async checkUpdate(meldungenAnzeigen = false, force = false): Promise<boolean> {
        this.log.debug('checkUpdate');

        if (!App.isCordovaAvailable()) {
            if (meldungenAnzeigen) {
                UiHelper.showAlert('Cordova nicht verfügbar');
            } else {
                this.log.debug('checkUpdate nicht möglich. Cordova nicht verfügbar.');
            }

            return false;
        }

        // const updateUrl = this.remoteService.toAbsoluteUrl('/api/app/check-update/' + AppConfig.current.mandant + '/' + AppConfig.current.geraeteNummer
        //     + '?packageName=' + App.packageName);

        // this.log.info('updateUrl: ' + updateUrl)

        try {
            const installedVersion = await ApkUpdater.getInstalledVersion();

            this.log.info('installedVersion', installedVersion);

            const result = await this.remoteService.checkUpdate();

            if (!result) {
                if (meldungenAnzeigen) {
                    UiHelper.showFehlerKommunikation();
                }

                return false;
            }

            if (result.Version <= App.versionCode) {
                this.log.info('Kein Update erforderlich');

                if (meldungenAnzeigen) {
                    UiHelper.showAlert('Kein Update verfübar. Aktuellste Version ist installiert.');
                }

                return false;
            }

            App.loading(true);

            const update = await ApkUpdater.download(
                result.Url,
                {
                    onDownloadProgress: (progress) => {
                        this.log.info('Downloading ' + progress.progress + '%');
                    },

                    onUnzipProgress: (progress) => {
                        this.log.info('Entpacke ' + progress.progress + '%');
                    }
                }
                // ,
                // (success) => {
                //     this.log.info('checkAppUpdate success', success);

                // },
                // (error) => {
                //     this.log.warn('checkAppUpdate error: ' + JSON.stringify(error), error);
                //     App.loading(false);
                // }
            );

            this.log.info('update', update);

            App.loading(false);

            const installResult = await ApkUpdater.install();

            this.log.info('installResult', installResult);

            // const appUpdate = (cordova.plugins as any).AppUpdate;

            // cordova.exec(
            //     (success) => {
            //         this.log.info('checkAppUpdate success', success);
            //     },
            //     (error) => {
            //         this.log.warn('checkAppUpdate error: ' + JSON.stringify(error), error);
            //     },
            //     "AppUpdate",
            //     "checkAppUpdate",
            //     [
            //         updateUrl, {
            //             skipProgressDialog: force,
            //             skipPromptDialog: force,
            //         }]);

            // this.appUpdate.checkAppUpdate()
            // // function(successOrUrl, errorOrOptions, updateUrl, options)

            // await appUpdate.checkAppUpdate(
            //     // Success callback
            //     (success) => {
            //         this.log.info('checkAppUpdate success', success);
            //     },

            //     // Error callbx
            //     (error) => {
            //         this.log.warn('checkAppUpdate error: ' + JSON.stringify(error), error);
            //     },

            //     // URL
            //     updateUrl,

            //     // Options
            //     {
            //         skipProgressDialog: force,
            //         skipPromptDialog: force,
            //     });

            // TODO
            // this.log.info('checkUpdate result ' + updateUrl + ': ' + JSON.stringify(result));

            // if (meldungenAnzeigen) {
            //     if (result.code == 202) {
            //         UiHelper.showAlert('Kein Update verfübar. Aktuellste Version ist installiert.');
            //     }
            // }
        } catch (err) {
            this.log.error('checkUpdate: ' + Utils.getErrorMessage(err));

            if (meldungenAnzeigen) {
                UiHelper.showError(err);
            }

            return false;
        }
    }

    async starteNavigation(adresse: Adresse): Promise<boolean> {
        this.log.debug('starteNavigation', adresse);

        const navigationsApp = AppConfig.current.einstellungen.NavigationsApp;

        // if (navigationsApp == 'PTV') {
        //     return await this.starteNavigationPTV(adresse);
        // }

        const appConfig = AppConfig.current;

        const options: LaunchNavigatorOptions = {
            appSelection: {
                rememberChoice: {
                    promptFn: (callback: (rememberChoice: boolean) => void) => {
                        UiHelper.confirmJaNein('Navigations-App merken?').then(antwort => {
                            callback(antwort);
                        });
                    },
                    enabled: 'prompt'
                }
            },
            enableGeolocation: appConfig.einstellungen.NavigationGeocodingErlauben !== 'nein',
        };

        const launchnavigator = ((window as any).launchnavigator as LaunchNavigatorPlugin);

        if (!launchnavigator) {
            UiHelper.showErrorOhneSentry('Navigation nicht verfügbar');
            return;
        }

        // }

        if (navigationsApp) {
            switch (navigationsApp) {
                case 'user_select':
                    options.app = launchnavigator.APP.USER_SELECT;
                    break;
                case 'geo':
                    options.app = launchnavigator.APP.GEO;
                    break;
                case 'PTV':
                    options.app = launchnavigator.APP.GEO;
                    break;
                case 'apple_maps':
                    options.app = launchnavigator.APP.APPLE_MAPS;
                    break;
                case 'google_maps':
                    options.app = launchnavigator.APP.GOOGLE_MAPS;
                    break;
                case 'waze':
                    options.app = launchnavigator.APP.WAZE;
                    break;
                case 'citymapper':
                    options.app = launchnavigator.APP.CITYMAPPER;
                    break;
                case 'navigon':
                    options.app = launchnavigator.APP.NAVIGON;
                    break;
                case 'transit_app':
                    options.app = launchnavigator.APP.TRANSIT_APP;
                    break;
                case 'yandex':
                    options.app = launchnavigator.APP.YANDEX;
                    break;
                case 'uber':
                    options.app = launchnavigator.APP.UBER;
                    break;
                case 'tomtom':
                    options.app = launchnavigator.APP.TOMTOM;
                    break;
                case 'bing_maps':
                    options.app = launchnavigator.APP.BING_MAPS;
                    break;
                case 'sygic':
                    options.app = launchnavigator.APP.SYGIC;
                    break;
                case 'here_maps':
                    options.app = launchnavigator.APP.HERE_MAPS;
                    break;
                case 'moovit':
                    options.app = launchnavigator.APP.MOOVIT;
                    break;
                case 'lyft':
                    options.app = launchnavigator.APP.LYFT;
                    break;
                default:
                    options.app = navigationsApp;
                    break;
            }

            if (options.app && options.app != 'geo' && options.app != launchnavigator.APP.USER_SELECT) {
                const verfuegbar = await this.launchNavigator.isAppAvailable(options.app);

                if (!verfuegbar) {
                    options.app = launchnavigator.APP.USER_SELECT;
                }
            }
        }

        let destination = adresse.Strasse + ', ' + adresse.PLZ + ' ' + adresse.Ort;

        options.destinationName = adresse.Name1 + ', ' + destination;

        if (AppConfig.current.einstellungen.NavigationGeoKoordinatenBevorzugen === 'ja') {
            if (adresse.GeoX && adresse.GeoY) {
                destination = this.getDestinationLatLong(adresse, navigationsApp);
            } else if (adresse.Strasse && adresse.PLZ && adresse.Ort) {
                destination = this.getDestinationAdresse(adresse, navigationsApp);
            }
        } else {
            if (adresse.Strasse && adresse.PLZ && adresse.Ort) {
                destination = this.getDestinationAdresse(adresse, navigationsApp);
            } else if (adresse.GeoX && adresse.GeoY) {
                destination = this.getDestinationLatLong(adresse, navigationsApp);
            }
        }

        try {
            this.log.debug('starteNavigation: ' + destination);

            if (destination.startsWith("geo:")) {
                const link = document.createElement("a");
                link.href = destination; // "geo:0,0?q=DE,76131,Karlsruhe,Haid,15";
                link.click();
            } else {
                this.launchNavigator.navigate(destination, options);
            }

            // this.log.info('Launched navigator result', result);
        } catch (err) {
            this.log.error('launchNavigator: ' + Utils.getErrorMessage(err), err);
            UiHelper.showError(err);
        }

        this.log.debug('starteNavigation done');

        return true;
    }

    getDestinationAdresse(adresse: Adresse, navigationsApp: string): string {
        switch (navigationsApp) {
            case 'PTV':
                let land = adresse.LKZ;

                if (!land) {
                    land = 'DE';
                }

                const strasseHausnr = this.getStrasseHausnummer(adresse.Strasse);

                if (strasseHausnr.hausnummer) {
                    return `geo:0,0?q=${land},${adresse.PLZ},${adresse.Ort},${strasseHausnr.strasse},${strasseHausnr.hausnummer}`;
                } else {
                    return `geo:0,0?q=${land},${adresse.PLZ},${adresse.Ort},${adresse.Strasse}`;
                }

            default:
                return adresse.Strasse + ', ' + adresse.PLZ + ' ' + adresse.Ort;
        }
    }

    getDestinationLatLong(adresse: Adresse, navigationsApp: string): string {
        switch (navigationsApp) {
            case 'PTV':
                return `geo:0,0?q=${adresse.GeoY},${adresse.GeoX}`;

            default:
                return adresse.GeoY + ' ' + adresse.GeoX;
        }
    }

    getStrasseHausnummer(str: string): { strasse: string, hausnummer: string } {
        if (str) {
            str = str.replace(/[\.\,/\\?%*:|"<>]/g, '');

            let pos = -1;

            for (let i = 0; i < str.length; i++) {
                const c = str[i];

                if (c >= '0' && c <= '9') {
                    pos = i;
                    break;
                }
            }

            if (pos > 0) {
                return {
                    strasse: str.substring(0, pos).trim(),
                    hausnummer: str.substr(pos).trim()
                };
            } else {
                return {
                    strasse: str,
                    hausnummer: ''
                };
            }
        }
    }

    async onWorkerMessage(data: any) {
        this.log.debug('onWorkerMessage');

        switch (data.type) {
            case 'sendWebSocketMessage':
                try {
                    this.log.debug('sendWebSocketMessage: ' + data.data);

                    let counter = 0;

                    while (!this.webSocket || this.webSocket.readyState === WebSocket.CONNECTING) {
                        this.log.debug('delay sendWebSocketMessage: ' + counter);

                        await Utils.delay(500);

                        if (counter > 10) {
                            break;
                        }
                    }

                    this.webSocket.send(data.data);
                } catch (err) {
                    this.log.warn('Fehler beim Senden der WebSocket nachricht', err);
                    this.initWebSocket('sendWebSocketMessage error');
                }
                break;

        }
    }

    registerNfcReceiver(prio: number, func: (event: NfcEvent) => void): number {
        const id = ++this.nfcRecieverId;

        this.nfcReceivers.push({
            func,
            prio,
            id
        });

        // Sortiere die Liste absteigend nach Prio
        this.nfcReceivers.sort((a, b) => b.prio - a.prio);

        return id;
    }

    unregisterNfcReceiver(registrationId: number) {
        if (registrationId) {
            const idx = this.nfcReceivers.findIndex(p => p.id === registrationId);

            if (idx >= 0) {
                this.nfcReceivers.splice(idx, 1);
            }
        }
    }

    //     async starteNavigationPTV(adresse: Adresse): Promise<boolean> {
    //         this.log.debug('starteNavigationPTV', adresse);

    //         const options = {
    //             action: this.webIntent.ACTION_VIEW,
    //             url: 'file:///storage/emulated/0/tour.bcr',

    //         }
    //  String address = "DE,76131,Karlsruhe,Haid,15";
    //     String uri = "geo:0,0?q=" + address;
    //         if (AppConfig.current.einstellungen.NavigationGeoKoordinatenBevorzugen === 'ja') {
    //             if (adresse.GeoX && adresse.GeoY) {
    //                 destination = adresse.GeoY + ' ' + adresse.GeoX;
    //             } else if (adresse.Strasse && adresse.PLZ && adresse.Ort) {
    //                 destination = adresse.Strasse + ', ' + adresse.PLZ + ' ' + adresse.Ort;
    //             }
    //         } else {
    //             if (adresse.Strasse && adresse.PLZ && adresse.Ort) {
    //                 destination = adresse.Strasse + ', ' + adresse.PLZ + ' ' + adresse.Ort;
    //             } else if (adresse.GeoX && adresse.GeoY) {
    //                 destination = adresse.GeoY + ' ' + adresse.GeoX;
    //             }
    //         }

    //         String fileName = "";
    //         String uri = "file://" + fileName;
    //         Intent intent = new Intent(android.content.Intent.ACTION_VIEW, Uri.parse(uri));
    //         intent.addFlags(Intent.FLAG_ACTIVITY_REORDER_TO_FRONT);
    //         intent.addFlags(Intent.FLAG_ACTIVITY_NEW_TASK);

    //         window.plugins.intentShim.startActivity(
    //             {
    //                 action: window.plugins.intentShim.ACTION_VIEW,
    //                 url: 'geo:0,0?q=London'
    //             },
    //             function() {},
    //             function() {alert('Failed to open URL via Android Intent')}
    //             );

    //         this.webIntent.startActivity(options).then(onSuccess, onError);

    //         return true;
    //     }

    reverseGeocoding(latitude: number, longitude: number): Promise<Adresse> {
        let nativegeocoder = (window as any).nativegeocoder as any;

        if (!nativegeocoder) {
            this.log.warn('nativegeocoder nicht verfügbar');
            return Promise.resolve(null);
        }

        let options = {};

        return new Promise(resolve => {
            nativegeocoder.reverseGeocode(
                (success) => {
                    // Success
                    this.log.debug('reverseGeocode: ' + JSON.stringify(success));

                    const array = success as any[];

                    if (array?.length) {
                        let a1 = array[0];

                        const adresse: Adresse = {
                            GeocodingLatitude: a1.latitude,
                            GeocodingLongitude: a1.longitude,
                            LKZ: a1.countryCode,
                            PLZ: a1.postalCode,
                            Ort: a1.locality,
                            Strasse: a1.thoroughfare + ' ' + a1.subThoroughfare
                        }

                        resolve(adresse);
                    } else {
                        resolve(null);
                    }
                },
                (err) => {
                    // Error
                    this.log.warn('reverseGeocode: ' + Utils.getErrorMessage(err));
                    resolve(null);
                }, latitude, longitude, options);
        });
    }

    moveToForeground(info: string) {
        this.log.debug('moveToForeground: ' + info);

        if (!App.isCordovaAvailable()) {
            return;
        }

        try {
            // Turn screen on
            this.backgroundMode.wakeUp();

            // Turn screen on and show app even locked
            cordova.plugins.backgroundMode.unlock();

            this.backgroundMode.moveToForeground();
        } catch (err) {
            this.log.error(Utils.getErrorMessage(err));
        }
    }
}

// interface Postausgang {
//     textdateiIds: number[];
//     lastId: number;
// }

interface NfcReceiver {
    func: (event: any) => void;
    prio: number;
    id: number;
}


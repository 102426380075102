import { Router } from "@angular/router";
import { WebView } from "@ionic-native/ionic-webview/ngx";
import { ActionSheetController, AlertController, ModalController, ToastController } from "@ionic/angular";
import { BehaviorSubject, Subject, Subscription } from "rxjs";
import { AuftragService } from "src/app/api/auftrag.service";
import { App } from "src/app/api/helper/app";
import { Logger } from "src/app/api/helper/app-error-logger";
import { AppConfig } from "src/app/api/helper/app.config";
import { AuftragHelper } from "src/app/api/helper/auftrag-helper";
import { ChecklistHelper } from "src/app/api/helper/checklist-helper";
import { UiHelper } from "src/app/api/helper/ui-helper";
import { Utils } from "src/app/api/helper/utils";
import { Auftragsstatus } from "src/app/api/model/aufteagsstatus";
import { AuftragEx, AuftragListItem, AuftragsdateilsDarstellung, AuftragspositionEx, StatusmeldungZusatzdaten, UnterschriftInfo } from "src/app/api/model/model";
import { Adresse, ArbeitsartKonfiguration, AuftragsFormular, AuftragsKomponente, Auftragstyp, Checkliste, Kontakt, MengeOptionTyp, ReCoMobilBild, Regel, StatusmeldungZusatzinfo, TextdateiTyp, TourStatusAuftragsdaten, Workflow, WorkflowStatus } from "src/app/api/model/swagger-model";
import { RemoteService } from "src/app/api/remote.service";
import { StammdatenService } from "src/app/api/stammdaten.service";
import { SystemService } from "src/app/api/system.service";
import { TourService } from "src/app/api/tour.service";
import { WaageService } from "src/app/api/waage.service";
import { KontaktlisteComponent } from "src/app/shared/components/kontaktliste/kontaktliste.component";
import { StatuszusatzinfoModalPage } from "src/app/shared/modal/statuszusatzinfo-modal/statuszusatzinfo-modal.page";
import { BildVO, StatusMeldenResult, ButtonVO } from "./auftragdetails.model";
import { Camera } from '@ionic-native/camera/ngx';
import { File } from '@ionic-native/file/ngx';
import { FileHelper } from "src/app/api/helper/file-helper";

import { FileOpener } from "@ionic-native/file-opener/ngx";
import { AuftragLieferungPage } from "../auftrag-lieferung/auftrag-lieferung.page";
import { AuftragBarcodeModalPage } from "src/app/shared/modal/auftrag-barcode-modal/auftrag-barcode-modal.page";
import { AuftragAnmerkungenPage } from "../auftrag-anmerkungen/auftrag-anmerkungen.page";
import { AuftragDruckenPage } from "../auftrag-drucken/auftrag-drucken.page";
import { AuftragBezahlenPage } from "../auftrag-bezahlen/auftrag-bezahlen.page";
import { AuftragAdressePage } from "../auftrag-adresse/auftrag-adresse.page";
import { skip } from "rxjs/operators";
import { AuftragWeiterleitenPage } from "../auftrag-weiterleiten/auftrag-weiterleiten.page";
import { IAuftragdetails } from "./iauftragdetails";
import { AuftragStatusmeldungPage } from "../auftrag-statusmeldung/auftrag-statusmeldung.page";
import { StoerzeitenPage } from "src/app/shared/components/stoerzeiten/stoerzeiten.page";
import { SeriennummernListeComponent } from "src/app/shared/components/seriennummern-liste/seriennummern-liste.component";
import { I18N } from "src/app/api/helper/i18n";
import { ImageHelper } from "src/app/api/helper/image-helper";
import { DruckerHelper } from "src/app/shared/helper/drucker-helper";
import { AppService } from "src/app/api/helper/app-services";
import { Zeiterfassung } from "src/app/api/helper/zeiterfassung-helper";
import * as moment from "moment";

const log = new Logger("AuftragdetailsHelper");

export class Auftragdetails implements IAuftragdetails {
    auftrag: AuftragEx;
    workflow: Workflow;
    formularKonfiguration: AuftragsFormular;
    checklisten: Checkliste[] = [];
    auftragsbilder: BildVO[];
    bilder: BildVO[];
    buttons: ButtonVO[];
    buttons2: ButtonVO[];
    buttons3: ButtonVO[];
    buttonsAuftragPopup: ButtonVO[];
    darstellung: AuftragsdateilsDarstellung;

    lieferungFehlertext = new BehaviorSubject<string>('');
    istLieferungVollstaendig = new BehaviorSubject<boolean>(false);

    leergutFehlertext = new BehaviorSubject<string>('');
    istLeergutVollstaendig = new BehaviorSubject<boolean>(false);

    detectChanges = new Subject<string>();

    auftragspositionFertigChanged = new Subject<AuftragspositionEx>();

    workflowChanged = new Subject<Workflow>();

    beaforePageLeave = new Subject<string>();

    /** Auftrag abgeschlossen */
    auftragAbgeschlossen = new Subject<string>();

    /** Lieferung soll angezeigt werden */
    lieferungAnzeigen = new Subject<string>();

    /** Druck-Dialog soll angezeigt werden */
    druckenAnzeigen = new Subject<WorkflowStatus>();

    /** Bezahlen-Dialog soll angezeigt werden */
    bezahlenAnzeigen = new Subject<WorkflowStatus>();

    /** Anmerkungen-Dialog soll angezeigt werden */
    anmerkungenAnzeigen = new Subject<WorkflowStatus>();

    /** Auftrag weiterleiten-Dialog soll angezeigt werden */
    weiterleitenAnzeigen = new Subject<WorkflowStatus>();

    auftragspositionenGeaendert = new Subject<void>();

    /**  */
    scrollToButtons = new Subject<string>();

    isModalSichtbar = false;
    kameraAktiv = false;
    alertAktiv = false;

    anzeigeBild: BildVO = null;

    subscriptions: Subscription[] = [];

    disposed = false;

    isReadOnly = false;
    istAuftragAbgeschlossen = false;

    isNavigationGesperrt = false;
    isLieferungAendernGesperrt = false;
    isBilderErfassenGesperrt = false;
    isAnmerkungenErfassenGesperrt = false;
    isStatusmeldungGesperrt = false;
    isSchreibgeschuetztAnzeigen = false;
    isUnterschreibenGesperrt = false;

    constructor(
        private webView: WebView,
        private toastController: ToastController,
        private router: Router,
        private modalController: ModalController,
        private actionSheetController: ActionSheetController,
        private camera: Camera,
        private file: File,
        private fileOpener: FileOpener,
        private alertController: AlertController,
        private stammdatenService: StammdatenService,
    ) {

        this.subscriptions.push(this.stammdatenService.workflows.pipe(skip(1)).subscribe((workflows) => this.onWorkflowsChanged(workflows)));
    }

    get auftragService(): AuftragService { return AuftragService.instance; };

    async init(): Promise<void> {
        log.debug('init');

        this.updateAuftragReadOnly();
        this.updateAuftragAbgeschlossen();

        await this.updateBilder();
        await this.updateAuftragsbilder();
        await this.ladeChecklisten();

        this.initButtons();
        this.updateLieferungVollstaendig('init');
        this.updateLeergutVollstaendig('init');
    }

    dispose() {
        log.debug('dispose');
        this.disposed = true;
        this.subscriptions.forEach(p => p.unsubscribe());
        this.subscriptions = [];
    }

    async onWorkflowsChanged(workflows: Workflow[]) {
        log.debug('onWorkflowsChanged');

        if (this.workflow && workflows) {
            const newWorkflow = workflows.find(p => p.name === this.workflow.name);

            if (newWorkflow) {
                this.workflow = newWorkflow;

                this.formularKonfiguration = await this.stammdatenService.getAuftragsFormularKonfiguration(this.auftrag);

                this.workflowChanged.next(newWorkflow);
                this.detectChanges.next('onWorkflowsChanged');
            }
        }
    }

    async updateBilder(): Promise<void> {
        log.debug('updateBilder');

        const auftrag = this.auftrag;
        const voList = [];

        if (auftrag.Bilder) {
            for (const bild of auftrag.Bilder) {
                const vo = await this.createBildVo(bild);
                voList.push(vo);
            }
        }

        this.bilder = voList;
    }

    async updateAuftragsbilder(): Promise<void> {
        log.debug('updateAuftragsbilder');

        const auftrag = this.auftrag;
        const auftragsbilderVoList = [];

        const auftragBilder = await AuftragService.instance.getAuftragsBilder(auftrag);

        for (const bild of auftragBilder) {
            const vo = await this.createBildVo(bild);
            auftragsbilderVoList.push(vo);
        }

        log.debug('auftragsbilder', auftragsbilderVoList);

        this.auftragsbilder = auftragsbilderVoList;
    }

    async createBildVo(bild: ReCoMobilBild): Promise<BildVO> {
        let src = bild.Bild;

        if (!src) {
            src = await AuftragService.instance.getBildSrc(bild.BildGuid);
        }

        if (src && src.startsWith('file:')) {
            // Bild ist als Datei gespeichert und es wird hier der Pfad zurückgegeben
            src = this.webView.convertFileSrc(src);
        } else {
            // Bild ist als Base64 hinterlegt
            if (!src.startsWith("data:image/")) {
                src = 'data:image/jpeg;base64,' + src;
            }
        }

        return {
            src,
            guid: bild.BildGuid,
            datum: '',
            nummer: 0,
            bezeichnung: bild.Bezeichnung
        };
    }

    showAuftragNichtZugewiesen() {
        UiHelper.showAlert('Bitte erst Auftrag diesem Gerät zuweisen.');
    }

    showAuftragArchiviert() {
        UiHelper.showAlert('Auftrag ist bereits archiviert und kann nicht mehr geändert werden');
    }

    showAuftragSchreibgeschuetzt() {
        UiHelper.showAlert('Auftrag ist schreibgeschützt und kann nicht geändert werden');
    }

    updateAuftragAbgeschlossen() {
        this.istAuftragAbgeschlossen = this.auftrag && this.auftrag.Auftragsstatus >= Auftragsstatus.Abgeschlossen;
    }

    async statusMelden(status: WorkflowStatus, mitRueckfrage: boolean): Promise<StatusMeldenResult> {
        log.debug('statusMelden: ' + status.name, status);

        // Ein Auftrag soll auch abgelehnt werden können ohne die Zeiterfassung zu starten
        if (status.auftragsstatus !== Auftragsstatus.Abgelehnt) {
            if (!await this.pruefeZeiterfassungGestartet()) {
                return;
            }
        }

        const auftrag = this.auftrag;
        const checklisten = this.checklisten;
        const workflow = this.workflow;

        const result: StatusMeldenResult = {
            erfolgreich: false,
            detectChanges: false,
            abbruch: true
        };

        this.updateAuftragReadOnly();
        this.updateAuftragAbgeschlossen();

        let schreibgeschuetztPruefen = true;
        let tourStartErforderlich = true;

        if (status.auftragsstatus == Auftragsstatus.Weiterleiten || status.auftragsstatus == Auftragsstatus.Angenommen || status.auftragsstatus == Auftragsstatus.Abgelehnt) {
            schreibgeschuetztPruefen = false;
            tourStartErforderlich = false;
        }

        if (status.NachAbschlussErlaubt) {
            if (!this.istAuftragAbgeschlossen && this.isStatusmeldungGesperrt && schreibgeschuetztPruefen) {
                this.showAuftragSchreibgeschuetzt();
                return result;
            }
        } else {
            if (this.istAuftragAbgeschlossen) {
                this.showAuftragArchiviert();
                return result;
            }

            if (this.isReadOnly && schreibgeschuetztPruefen) {
                this.showAuftragSchreibgeschuetzt();
                return result;
            }
        }

        if (await this.preufeAuftragNichtZugewiesen(auftrag)) {
            return result;
        }

        // Prüfe ob der Statusübergang erlaubt ist
        const erlaubt = AuftragService.instance.istStatusuebergangErlaubt(auftrag, workflow, status);

        if (!erlaubt) {
            const erlaubteStatusMeldungen = AuftragService.instance.getAktuellErlaubteStatusMeldungen(auftrag, workflow);

            UiHelper.showAlert(I18N.instant('Meldung430') + ': \n' + erlaubteStatusMeldungen.map(p => p.name).join(', '));
            return result;
        }

        if (!App.current.tourGestartet.getValue() && App.current.tourStartenErforderlich.getValue()) {
            if (auftrag.Auftragstyp == Auftragstyp.Transportauftrag && tourStartErforderlich) {
                const ok = await UiHelper.confirmJaNein(`Meldung429`, 'Tour start');

                if (ok) {
                    await this.router.navigateByUrl('/tour/start');
                }

                return result;
            }
        }

        const aktuelleTour = App.current.aktuelleTour.getValue();

        if (aktuelleTour && auftrag.Auftragstyp === Auftragstyp.Transportauftrag) {
            if (aktuelleTour.ErstwiegungErfolgt || aktuelleTour.ZweitwiegungErfolgt) {
                await UiHelper.showError(`Statusmeldung428`, 'Tour');
                return result;
            }
        }

        if (status.unterschriftErforderlich) {
            // Workflow erfordert, dass alle erforderlichen Unterschriften vorhanden sind
            const fehlertext = this.pruefeUnterschriftenVollstaendig();

            if (fehlertext) {
                UiHelper.showAlert(fehlertext, status.name);
                return result;
            }
        }

        let minAnzahlBilder = status.minAnzahlBilder;

        if (minAnzahlBilder == null) {
            minAnzahlBilder = 0;
        }

        let bilderPositionsText = '';

        // Prüfe die Auftragsposition ob dort eine Mindestanzahl an Bildern vorgegeben ist
        if (status.auftragsstatus === Auftragsstatus.Abgeschlossen && this.auftrag.Positionen) {
            const positionsTexte: string[] = [];

            let minAnzahlAusAuftragspositionen = 0;
            const aufsummieren = AppConfig.current.einstellungen.MinAnzahlBilderInAuftragspositionenAddieren === 'ja';

            const alleArtikel = StammdatenService.instance.getAlleArtikel().getValue();

            for (const position of this.auftrag.Positionen) {
                if (position.Menge) {
                    let positionMinAnzahlBilder = 0;

                    if (typeof (position.MinAnzahlBilder) === 'number') {
                        if (position.MinAnzahlBilder > 0) {
                            positionsTexte.push(position.Bezeichnung + ': ' + position.MinAnzahlBilder);
                        }

                        positionMinAnzahlBilder = position.MinAnzahlBilder;
                    } else {
                        // Prüfe ob der Artikel bekannt ist und ob dort eine Mindestanzahl hinterlegt ist
                        if (position.ArtikelKey) {
                            const artikel = alleArtikel.find(p => p.artikelKey && p.artikelKey as any == position.ArtikelKey);

                            if (artikel) {
                                if (artikel.MinAnzahlBilder) {
                                    positionsTexte.push(position.Bezeichnung + ': ' + artikel.MinAnzahlBilder);
                                    positionMinAnzahlBilder = artikel.MinAnzahlBilder;
                                }
                            }
                        }
                    }

                    if (positionMinAnzahlBilder) {
                        if (aufsummieren) {
                            minAnzahlAusAuftragspositionen += positionMinAnzahlBilder;
                        } else {
                            minAnzahlAusAuftragspositionen = Math.max(positionMinAnzahlBilder, minAnzahlAusAuftragspositionen);
                        }
                    }
                }

                if (positionsTexte.length) {
                    bilderPositionsText = '\n\n' + positionsTexte.join(', \n');
                }
            }

            if (minAnzahlBilder < minAnzahlAusAuftragspositionen) {
                minAnzahlBilder = minAnzahlAusAuftragspositionen;
            }
        }

        // Prüfe ob in den Einstellungen eine Mindestanzahl konfiguriert ist, falls Mengen geändert wurden
        if (auftrag.Auftragstyp === Auftragstyp.Transportauftrag && status.bilderSenden) {
            const minAnzahlBeiMengenaenderung = Utils.parseInt(AppConfig.current.einstellungen.TransportauftragMinAnzahlBilderBeiMengenaenderung);

            if (minAnzahlBeiMengenaenderung > 0 && minAnzahlBilder < minAnzahlBeiMengenaenderung) {
                for (const position of this.auftrag.Positionen) {
                    if (position.Menge != position.MengeSoll) {
                        log.debug(`Menge wurde geändert ${this.auftrag.Auftragsnummer} Pos. ${position.PosNr} ${position.Bezeichnung}: soll=${position.MengeSoll}, ist=${position.Menge} ${position.Einheit}`);

                        // Prüfe ob die Einheit auch relevant ist
                        let filterEinheiten = AppConfig.current.einstellungen.TransportauftragMinAnzahlBilderEinheiten;
                        let positionRelevant = true;

                        if (filterEinheiten && filterEinheiten !== '*' && filterEinheiten.trim().length > 0) {
                            // Prüfe ob diese Mengenänderung wirklich relevant ist.
                            // Dafür muss die Einheit noch geprüft werden. Sie ist nur dann relevant, wenn die Einheit explizit angegeben ist
                            positionRelevant = false;

                            filterEinheiten = Utils.trimToEmpty(filterEinheiten).toLowerCase();

                            let einheiten = filterEinheiten.split(',');

                            for (const einheit of einheiten) {
                                let istEinheit = Utils.trimToEmpty(position.Einheit).toLowerCase();

                                if (istEinheit === einheit) {
                                    // Ja, Einheit ist relevant
                                    positionRelevant = true;
                                    break;
                                }
                            }
                        }

                        if (positionRelevant) {
                            if (minAnzahlBilder < minAnzahlBeiMengenaenderung) {
                                minAnzahlBilder = minAnzahlBeiMengenaenderung;
                                break;
                            }
                        }
                    }
                }
            }
        }

        if (auftrag.Auftragstyp === Auftragstyp.Kommissionierung && this.auftrag.Positionen) {
            if (status.auftragsstatus === Auftragsstatus.Abgeschlossen) {
                // Alle Positionen sollen abgehakt worden sein
                if (this.auftrag.Positionen.some(p => !p.Fertig)) {
                    const ok = await UiHelper.confirmJaNein(`Meldung431`, 'Achtung');

                    if (!ok) {
                        return result;
                    }
                }
            }

            if (status.auftragsstatus === Auftragsstatus.Abgelehnt) {
                // Palette wird als Vollpalette gemeldet.
                // if (this.auftrag.Positionen.some(p => p.Fertig)) {
                //     const ok = await UiHelper.confirmJaNein(`Es wurden nicht alle Positionen fertig gemeldet. Palette wirklich fertig melden?`, 'Achtung');

                //     if (!ok) {
                //         return result;
                //     }
                // }
            }
        }

        let meldungAnzahlBilder = '';

        if (status.auftragsstatus === Auftragsstatus.Abgeschlossen) {
            const aktiveRegeln = AuftragHelper.getZutreffendeRegeln(this.auftrag);

            for (const regel of aktiveRegeln) {
                if (regel.Aktion?.MinAnzahlBilderAktiv && regel.Aktion.MinAnzahlBilder != null) {
                    minAnzahlBilder = regel.Aktion.MinAnzahlBilder;
                    meldungAnzahlBilder = regel.Aktion.MinAnzahlBilderMeldung;
                }
            }
        }

        const anzahlFotos = this.auftrag.Auftragsbilder.length;

        if (anzahlFotos < minAnzahlBilder) {
            if (meldungAnzahlBilder) {
                // Es ist über die Regel ein Text definiert der angezeigt werden soll
                UiHelper.showAlert(meldungAnzahlBilder);
            } else if (minAnzahlBilder == 1) {
                UiHelper.showAlert('Es muss mindestens ein Foto gemacht werden!' + bilderPositionsText);
            } else {
                UiHelper.showAlert(`Es müssen mindestens ${minAnzahlBilder} Fotos gemacht werden!` + bilderPositionsText);
            }

            return result;
        }

        // Prüfe ob für alle "nicht gescannten" Container ein Foto gemacht wurde.
        // Für Huber & Sohn sollen die Container eigentlich imemr gescannt werden.
        // Wenn sie nicht gescannt wurden, muss für jeden container mindestens ein Foto gemacht werden.
        if (Utils.isTrue(AppConfig.current.einstellungen.TransportauftraBehaelterNummerHandeingabeFotoErforderlich)) {
            let nichtGescannteBehaelterNummern: string[] = [];

            for (const position of this.auftrag.Positionen) {
                if (position.Behaelter?.length > 0) {
                    for (const behaelter of position.Behaelter) {
                        if (behaelter.BehaelterNr && !behaelter.IsScan) {
                            nichtGescannteBehaelterNummern.push(behaelter.BehaelterNr);
                        }
                    }
                }
            }

            if (nichtGescannteBehaelterNummern.length > anzahlFotos) {
                const anzahlDifferenz = nichtGescannteBehaelterNummern.length - anzahlFotos;
                const str = Utils.joinNotEmpties('\n', ...nichtGescannteBehaelterNummern);

                const header = anzahlDifferenz + ' Behälter fotografieren';

                if (anzahlDifferenz === 1) {
                    if (nichtGescannteBehaelterNummern.length === 1) {
                        UiHelper.showAlert(`Es wurden nicht alle Behälter gescannt. Bitte fotografieren Sie den Behälter: \n\n` + str, header);
                    } else {
                        UiHelper.showAlert(`Es wurden nicht alle Behälter gescannt!\nEs ist noch ein weiteres Foto erforderlich. Bitte fotografieren Sie alle Behälter: \n\n` + str, header);
                    }
                } else {
                    UiHelper.showAlert(`Es wurden nicht alle Behälter gescannt!\nEs sind noch ${anzahlDifferenz} weitere Fotos erforderlich. Bitte fotografieren Sie die Behälter: \n\n` + str, header);
                }

                return result;
            }
        }

        if (status.anmerkungErforderlich) {
            if (!auftrag.Anmerkungen) {
                await UiHelper.showAlert('Bitte Anmerkungen eingeben', null, true);
                this.anmerkungenAnzeigen.next(status);
                return result;
            }
        }

        if (status.auftragsstatus == Auftragsstatus.Abgeschlossen) {
            // Prüfe of Checklisten ausgefüllt wurden
            const ok = await this.pruefeChecklistenVollstaendig(auftrag, checklisten);

            if (!ok) {
                UiHelper.showAlert('Bitte Checklisten vollständig ausfüllen');
                return result;
            }

            // Prüfe Positionen vollständig
            const fehlertext = this.pruefeAuftragspositionenVollstaendig();

            if (fehlertext) {
                UiHelper.showAlert('Bitte Auftragspositionen vollständig ausfüllen. ' + fehlertext);
                this.lieferungAnzeigen.next('');
                return result;
            }

            if (App.current.waageVerfuegbar.getValue()) {
                const anzahlWiegedaten = WaageService.instance.wiegedaten.getValue().length;

                if (anzahlWiegedaten > 0) {
                    const config = AppConfig.current.einstellungen.WaageAuftragAbschliessenMitOffnenWiegungen;

                    if (config == 'fehler') {
                        const confirmResult = await UiHelper.confirm(
                            'Es wurden nicht alle Wiegungen zugeordnet. Bitte zuordnen oder löschen.',
                            'Wiegungen',
                            'Löschen',
                            'Zuordnen');

                        switch (confirmResult) {
                            case 'Zuordnen':
                                this.lieferungAnzeigen.next('');
                                break;
                            case 'Löschen':
                                this.router.navigateByUrl('/einstellungen/waage');
                                break;
                            default:
                                return;
                        }
                        return result;
                    } else if (config == 'warnung') {
                        const confirmResult = await UiHelper.confirmJaNein('Es wurden nicht alle Wiegungen zugeordnet. Auftrag wirklich abschließen?');

                        if (!confirmResult) {
                            return result;
                        }
                    }
                }
            }
        }

        result.abbruch = false;

        if (status.auftragsstatus == Auftragsstatus.Gedruckt) {
            this.druckenAnzeigen.next(status);
            result.abbruch = false;

            // Hier wird abgebrochen. Alles weitere wird von der AuftragDruckenPage gemacht
            return result;
        }

        if (status.auftragsstatus == Auftragsstatus.Bezahlt) {
            this.bezahlenAnzeigen.next(status);
            result.abbruch = false;

            // Hier wird abgebrochen. Alles weitere wird von der AuftragBezahlenPage gemacht
            return result;
        }

        if (status.auftragsstatus == Auftragsstatus.Weiterleiten) {
            this.weiterleitenAnzeigen.next(status);
            result.abbruch = false;

            // Hier wird abgebrochen. Alles weitere wird von der xxxx gemacht
            return result;
        }

        let zusatzinfo: StatusmeldungZusatzinfo = null;

        if (status.statuszusatzinfo) {
            const statuszusatzinfoModal = await this.modalController.create({
                component: StatuszusatzinfoModalPage,
                componentProps: {
                    'workflowStatus': status,
                    'auftrag': auftrag
                }
            });

            log.debug('statuszusatzinfoModal wird angezeigt...');
            await statuszusatzinfoModal.present();

            const modalResult = await statuszusatzinfoModal.onDidDismiss();

            zusatzinfo = modalResult.data.zusatzinfo

            log.debug('statuszusatzinfoModal wurde geschlossen', zusatzinfo);

            if (!zusatzinfo) {
                // Abbruch
                result.abbruch = true;
                return result;
            }

            // Keine Rückfrage mehr.
            // Der Dialog wurde schon mit OK geschlossen und damit bestätigt
            mitRueckfrage = false;
        }

        if (mitRueckfrage) {
            if (workflow) {
                if (workflow.einstellungen.RueckfrageStatusmeldungen === 'nein') {
                    mitRueckfrage = false;
                }
            }
        }

        if (mitRueckfrage) {
            if (status.auftragsstatus == Auftragsstatus.FreigabeAnfordern) {
                const ok = await UiHelper.confirmJaNein('Freigabe wirklich anfordern?');

                if (!ok) {
                    result.abbruch = true;
                    return result;
                }
            } else if (status.auftragsstatus == Auftragsstatus.Angenommen) {
                const ok = await UiHelper.confirmJaNein('Auftrag wirklich annehmen?');

                if (!ok) {
                    result.abbruch = true;
                    return result;
                }
            } else if (status.auftragsstatus == Auftragsstatus.Abgelehnt) {
                const ok = await UiHelper.confirmJaNein('Auftrag wirklich ablehnen?');

                if (!ok) {
                    result.abbruch = true;
                    return result;
                }
            } else if (status.auftragsstatus == Auftragsstatus.Geloescht) {
                const ok = await UiHelper.confirmJaNein('Auftrag wirklich löschen?');

                if (!ok) {
                    result.abbruch = true;
                    return result;
                }
            } else if (status.auftragsstatus == Auftragsstatus.Gedruckt) {
                // Keine Rückfrage hier notwendig. Wird oben schon abgefangen
            } else {
                const ok = await UiHelper.confirmJaNein(`wirklich senden?`, status.name);

                if (!ok) {
                    result.abbruch = true;
                    return result;
                }
            }
        }

        try {
            App.loading(true);

            await Utils.delay(250);

            let auftraegeAmEinsatzort: AuftragEx[] = [];

            if (status.FuerAlleAuftraegeAnEinsatzort) {
                // Diese Statusmeldung gilt für alle Aufträge am Einsatzort/für diesen Kunden.
                // ... d.h. zumindest für alle Aufträge die von der Reihenfolge gleich sind, also gleich vor oder nach diesem Auftrag kommen.
                // Das wurde für KLOH implementiert, da es bei AIS auch so vorhanden war.
                // Damit sollen die Zeiten richtig für die Aufträge erfasst werden (???).
                // Aktuell wird das nur für den Status "Ankunft am Leistungsort" verwendet.

                // Suche hier zuerst alle zusammenhängenden Aufträge, bevor die Statusmeldung gemacht wurde.
                auftraegeAmEinsatzort = await this.getAuftraegeAmEinsatzort(auftrag);
            }

            const zusatzdaten: StatusmeldungZusatzdaten = {
                zusatzinfo: zusatzinfo
            };

            const ok = await AuftragService.instance.statusmeldung(auftrag, status, zusatzdaten);

            if (!ok) {
                result.abbruch = true;
                return result;
            }

            if (status.FuerAlleAuftraegeAnEinsatzort && auftraegeAmEinsatzort.length) {
                // Diese Statusmeldung gilt für alle Aufträge am Einsatzort/für diesen Kunden.
                // ... d.h. zumindest für alle Aufträge die von der Reihenfolge gleich sind, also gleich vor oder nach diesem Auftrag kommen.
                // Das wurde für KLOH implementiert, da es bei AIS auch so vorhanden war.
                // Damit sollen die Zeiten richtig für die Aufträge erfasst werden (???).
                // Aktuell wird das nur für den Status "Ankunft am Leistungsort" verwendet.
                await this.meldeStatusmeldungFuerAlleAuftraegeAmEinsatzort(auftraegeAmEinsatzort, status, zusatzdaten);
            }

            result.abbruch = false;

            if (aktuelleTour) {
                if (status.auftragsstatus == Auftragsstatus.Abgeschlossen) {
                    // Merke zur Tour, dass der Auftrag abgeschlossen wurde
                    if (!aktuelleTour.AbgeschlosseneAuftraege) {
                        aktuelleTour.AbgeschlosseneAuftraege = [];
                    }

                    const abgeschlossenerAuftrag: TourStatusAuftragsdaten = {
                        AuftragKey: auftrag.Key,
                        Auftragsnummer: auftrag.Auftragsnummer,
                        Auftragstyp: auftrag.Auftragstyp,
                        GewichtInKg: AuftragHelper.getSummeGewichtInKg(auftrag),
                        Mengen: AuftragHelper.getMengeMap(auftrag)
                    };

                    aktuelleTour.AbgeschlosseneAuftraege.push(abgeschlossenerAuftrag)

                    if (!aktuelleTour.SummeGewichtInKg) {
                        aktuelleTour.SummeGewichtInKg = 0;
                    }

                    aktuelleTour.SummeGewichtInKg += abgeschlossenerAuftrag.GewichtInKg;

                    await TourService.instance.speichereAktuelleTour();
                }
            }

            if (status.auftragsstatus >= Auftragsstatus.Unterbrochen) {
                let toastMessage = '';

                if (status.auftragsstatus == Auftragsstatus.Abgeschlossen) {
                    toastMessage = `Auftrag wurde abgeschlossen`;
                } else if (status.auftragsstatus == Auftragsstatus.Unterbrochen) {
                    toastMessage = `Auftrag wurde unterbrochen`;
                } else if (status.auftragsstatus == Auftragsstatus.FreigabeAnfordern) {
                    toastMessage = `Freigabe wird angefordert...`;
                } else if (status.auftragsstatus == Auftragsstatus.Abgelehnt) {
                    toastMessage = `Auftrag wurde storniert`;
                } else if (status.auftragsstatus == Auftragsstatus.Angenommen) {
                    toastMessage = `Auftrag wurde angenommen`;
                }

                if (toastMessage) {
                    const toast = await this.toastController.create({
                        message: toastMessage,
                        duration: 1000,
                        color: 'medium'
                    });

                    toast.present();
                }

                this.auftragAbgeschlossen.next('');
            } else {
                result.detectChanges = true;
                this.scrollToButtons.next('');
            }
        } catch (err) {
            UiHelper.showError(err);
        } finally {
            App.loading(false);
        }

        this.updateAuftragReadOnly();

        this.detectChanges.next('statusmeldung');

        return result;
    }

    async getAuftraegeAmEinsatzort(auftrag: AuftragEx): Promise<AuftragEx[]> {
        if (!auftrag?.Abholadresse?.AdressNummer) {
            log.warn('getAuftraegeAmEinsatzort: Abholadresse.AdressNummer nicht gesetzt. Das sollte nicht sein');
            return [];
        }

        const aktuelleAuftraege = await AuftragService.instance.getAktuelleAuftraege(auftrag.Auftragstyp);

        AuftragHelper.sortiereAuftraege(aktuelleAuftraege);

        const idx = aktuelleAuftraege.findIndex(p => p.Key == auftrag.Key);

        if (idx < 0) {
            // Das darf eigentlich nicht eintreten.
            log.warn('getAuftraegeAmEinsatzort: Index des aktuellen Auftrags nicht gefunden. Das sollte nicht sein: ' + idx);
            return [];
        }

        const listItems: AuftragListItem[] = [];

        // Suche ab dem Index nach vorne und hinten nach Aufträgen am gleichen Einsatzort und zum gleichen Kunden

        // Zuerst nach vorne
        for (let i = idx - 1; i >= 0; i--) {
            const a = aktuelleAuftraege[i];

            if (a?.Abholadresse?.AdressNummer === auftrag.Abholadresse.AdressNummer
                && a.Abholadresse.Name1 == auftrag.Abholadresse.Name1
                && a.Abholadresse.PLZ == auftrag.Abholadresse.PLZ
                && a.Abholadresse.Ort == auftrag.Abholadresse.Ort
                && a.Abholadresse.Strasse == auftrag.Abholadresse.Strasse) {

                // Gleiche Adresse
                listItems.push(a);
            } else {
                // Nicht weiter suchen. Es sollen nur Aufträge gefunden werden die direkt davor/dahinter stehen
                break;
            }
        }

        // Und nach hinten
        for (let i = idx + 1; i < aktuelleAuftraege.length; i++) {
            const a = aktuelleAuftraege[i];

            if (a?.Abholadresse?.AdressNummer === auftrag.Abholadresse.AdressNummer
                && a.Abholadresse.Name1 == auftrag.Abholadresse.Name1
                && a.Abholadresse.PLZ == auftrag.Abholadresse.PLZ
                && a.Abholadresse.Ort == auftrag.Abholadresse.Ort
                && a.Abholadresse.Strasse == auftrag.Abholadresse.Strasse) {

                // Gleiche Adresse
                listItems.push(a);
            } else {
                // Nicht weiter suchen. Es sollen nur Aufträge gefunden werden die direkt davor/dahinter stehen
                break;
            }
        }

        const resultList: AuftragEx[] = [];

        if (listItems.length) {
            log.debug('Anzahl gleiche Aufträge zum Einsatzort: ' + listItems.length);

            for (const item of listItems) {
                log.debug('Auftrag am Einsatzort: ' + item.Key);

                // Hole den richtigen Auftrag zum ListItem
                const a2 = await this.auftragService.getAuftrag(item.Key);

                if (a2) {
                    resultList.push(a2);
                }
            }
        }

        return resultList;
    }

    async meldeStatusmeldungFuerAlleAuftraegeAmEinsatzort(auftraegeAmEinsatzort: AuftragEx[], status: WorkflowStatus, zusatzdaten: StatusmeldungZusatzdaten) {
        const workflow = this.workflow;

        for (const a2 of auftraegeAmEinsatzort) {
            log.debug(`Statusmeldung für Auftrag em Einsatzort: ${a2.Key}, ${status.name}`);

            const erlaubt = AuftragService.instance.istStatusuebergangErlaubt(a2, workflow, status);

            if (!erlaubt) {
                log.warn(`Statusübergang für Auftrag em Einsatzort nicht erlaubt`);
                continue;
            }

            await AuftragService.instance.statusmeldung(a2, status, zusatzdaten);
        }
    }

    pruefeAuftragspositionenVollstaendig(): string {
        const auftrag = this.auftrag;

        if (!auftrag.Positionen || auftrag.Positionen.length == 0) {
            return null;
        }

        const artikelList = StammdatenService.instance.getAlleArtikel().getValue();

        let laderaumAuswahlAktiv = false;

        if (auftrag.Auftragstyp === Auftragstyp.Transportauftrag) {
            laderaumAuswahlAktiv = AppConfig.current.einstellungen.TransportauftragLaderaumAuswahlAktiv === 'ja';
        }

        let abschliessenOhneWiegungen = AppConfig.current.einstellungen.TransportauftragPositionWiegenAbschliessenOhneWiegungen === 'ja';

        let fehlertext = '';

        const behaelterAbstellorteVerwenden = App.current.behaelterAbstellorteVerwenden.getValue();

        for (const p of auftrag.Positionen) {
            if (p.Menge !== null) {
                if (typeof (p.Menge) === 'string' && p.Menge !== '' && p.Menge !== null) {
                    p.Menge = Utils.parseFloat(p.Menge);
                }
            }

            if (!p.Menge && p.Menge !== 0 && p.MengeOption !== MengeOptionTyp.NichtAenderbar) {
                fehlertext = 'Fehlende Menge';
                return fehlertext;
            }

            // TODO Kontrolle
            if (!p.Menge && p.Menge <= 0 && p.MengeOption === MengeOptionTyp.AenderbarGroesserNull) {
                fehlertext = 'Menge muss größer 0 sein';
                return fehlertext;
            }

            if (p.GewichtInKgAktiv && p.GewichtInKgPflicht) {
                if (p.GewichtInKg !== null && p.GewichtInKg as any != '') {
                    p.GewichtInKg = Utils.parseFloat(p.GewichtInKg);

                    if (isNaN(p.GewichtInKg)) {
                        p.GewichtInKg = null;
                    }
                }

                //  && p.GewichtInKg !== 0
                if (!p.GewichtInKg && p.Menge !== 0) {
                    fehlertext = 'Gewicht in kg';
                    return fehlertext;
                }
            }

            if (!abschliessenOhneWiegungen && p.AuftragWiegungAktiv && p.Wiegungen2 && p.Wiegungen2?.length > 0) {
                // TODO bei einer Handwiegung kann im Gewicht "" stehen. Wie soll das abgefangen werden?
                // || p.Wiegungen2[0].Gewicht.toString() === "" 
                const gewicht1 = p.Wiegungen2[0]?.Gewicht as any;
                const gewicht2 = p.Wiegungen2[1]?.Gewicht as any;

                if (gewicht1 == null || gewicht1 == '' || gewicht2 == null || gewicht2 == '') {
                    fehlertext = 'Beide Wiegungen müssen vorhanden sein';
                    return fehlertext;
                }
            }

            if (laderaumAuswahlAktiv && p.Menge) {
                const fahrzeug = App.current.fahrzeug.getValue();

                if (fahrzeug && fahrzeug.Laderaeume) {
                    // Prüfen ob für den Artikel ein Laderaum gewählt werden muss
                    let laderaumErforderlich = false;

                    if (p.LaderaumAuswahlAktiv !== null && typeof (p.LaderaumAuswahlAktiv) !== 'undefined') {
                        laderaumErforderlich = p.LaderaumAuswahlAktiv;
                    } else if (p.ArtikelKey) {
                        const artikel = artikelList.find(x => x.artikelKey as any == p.ArtikelKey);

                        if (artikel) {
                            laderaumErforderlich = artikel.LaderaumAuswahlAktiv;
                        }
                    }

                    if (laderaumErforderlich) {
                        if (fahrzeug.Laderaeume.length == 1) {
                            // Nur ein Laderaum vorhanden. Automatisch füllen
                            if (fahrzeug.Laderaeume[0].Nummer) {
                                p.Laderaum = fahrzeug.key + '-' + fahrzeug.Laderaeume[0].Nummer.toString();
                            }
                        }

                        if (!p.Laderaum && fahrzeug.Laderaeume.length > 1) {
                            fehlertext = 'Laderaum';
                            return fehlertext;
                        }
                    }
                }
            }

            if (p.BhAktion) {
                const bhAktion = p.BhAktion.toLowerCase().trim();
                let anzahl = Math.ceil(p.Menge);

                if (anzahl > 100) {
                    anzahl = 100;
                }

                switch (bhAktion) {
                    case 'tauschen':
                        anzahl = anzahl * 2;
                        break;
                }

                if (!p.Behaelter || p.Behaelter.length != anzahl) {
                    fehlertext = 'Fehlende Behälter-Nummer';
                    return fehlertext;
                }

                for (const behaelter of p.Behaelter) {
                    if (!behaelter.BehaelterNr) {
                        fehlertext = 'Behälter-Nummer darf nicht leer sein';
                        return fehlertext;
                    }

                    if (behaelterAbstellorteVerwenden) {
                        if (behaelter.Aktion === 'abziehen') {
                            if (!behaelter.Abstellort) {
                                fehlertext = 'Abstellort für Behälter ' + behaelter.BehaelterNr + ' nicht ausgewählt';
                                return fehlertext;
                            }

                            if (!behaelter.Zustand) {
                                fehlertext = 'Zustand für Behälter ' + behaelter.BehaelterNr + ' nicht ausgewählt (voll / leer)';
                                return fehlertext;
                            }
                        }
                    }
                }
            }
        }

        // Eigenschaften prüfen

        for (const position of auftrag.Positionen) {
            if (!position.Eigenschaften) {
                continue;
            }

            if (!position.Menge) {
                continue;
            }

            for (const eigenschaft of position.Eigenschaften) {
                if (eigenschaft.Regex) {
                    try {
                        const wert = Utils.trimToEmpty(eigenschaft.Wert);

                        if (!wert.match(eigenschaft.Regex)) {
                            if (wert) {
                                fehlertext = `Eigenschaft '${eigenschaft.Bezeichnung}' für Position ${position.PosNr} ungültig`;
                            } else {
                                fehlertext = `Eigenschaft '${eigenschaft.Bezeichnung}' für Position ${position.PosNr} darf nicht leer sein`;
                            }

                            return fehlertext;
                        }
                    } catch (err) {
                        log.warn('Fehler beim Testen des Regex ' + eigenschaft.Regex + ' für Wert ' + eigenschaft.Wert + ': ' + Utils.getErrorMessage(err));
                    }
                }
            }
        }

        return null;
    }

    pruefeLeergutVollstaendig(): string {
        const auftrag = this.auftrag;

        if (!auftrag.RetourPositionen || auftrag.RetourPositionen.length == 0) {
            return null;
        }

        // const artikelList = StammdatenService.instance.getAlleArtikel().getValue();

        // let laderaumAuswahlAktiv = false;

        // if (auftrag.Auftragstyp === Auftragstyp.Transportauftrag) {
        //     laderaumAuswahlAktiv = AppConfig.current.einstellungen.TransportauftragLaderaumAuswahlAktiv === 'ja';
        // }

        let fehlertext = '';

        // const behaelterAbstellorteVerwenden = App.current.behaelterAbstellorteVerwenden.getValue();

        for (const p of auftrag.RetourPositionen) {
            if (p.Menge !== null) {
                if (typeof (p.Menge) === 'string' && p.Menge !== '' && p.Menge !== null) {
                    p.Menge = Utils.parseFloat(p.Menge);
                }
            }

            if (!p.Menge && p.Menge !== 0 && p.MengeOption !== MengeOptionTyp.NichtAenderbar) {
                fehlertext = 'Fehlende Menge';
                return fehlertext;
            }

            if (p.GewichtInKgAktiv && p.GewichtInKgPflicht) {
                if (p.GewichtInKg !== null && p.GewichtInKg as any != '') {
                    p.GewichtInKg = Utils.parseFloat(p.GewichtInKg);

                    if (isNaN(p.GewichtInKg)) {
                        p.GewichtInKg = null;
                    }
                }

                //  && p.GewichtInKg !== 0
                if (!p.GewichtInKg && p.Menge !== 0) {
                    fehlertext = 'Gewicht in kg';
                    return fehlertext;
                }
            }
        }

        // Eigenschaften prüfen

        for (const position of auftrag.RetourPositionen) {
            if (!position.Eigenschaften) {
                continue;
            }

            if (!position.Menge) {
                continue;
            }

            for (const eigenschaft of position.Eigenschaften) {
                if (eigenschaft.Regex) {
                    try {
                        const wert = Utils.trimToEmpty(eigenschaft.Wert);

                        if (!wert.match(eigenschaft.Regex)) {
                            if (wert) {
                                fehlertext = `Eigenschaft '${eigenschaft.Bezeichnung}' für Retour-Position ${position.PosNr} ungültig`;
                            } else {
                                fehlertext = `Eigenschaft '${eigenschaft.Bezeichnung}' für Retour-Position ${position.PosNr} darf nicht leer sein`;
                            }

                            return fehlertext;
                        }
                    } catch (err) {
                        log.warn('Fehler beim Testen des Regex ' + eigenschaft.Regex + ' für Wert ' + eigenschaft.Wert + ': ' + Utils.getErrorMessage(err));
                    }
                }
            }
        }

        return null;
    }

    async pruefeChecklistenVollstaendig(auftrag: AuftragEx, checklisten: Checkliste[]): Promise<boolean> {
        if (!checklisten || !checklisten.length) {
            // Keine Checklisten vorhanden. Deshalb immer vollständig
            return true;
        }

        // if (!this.auftrag.ChecklistenErgebnis || this.auftrag.ChecklistenErgebnis.length <= 0) {
        //     // noch gar kein Ergebnis vorhanden
        //     log.info(`Gar keine Ergebnisse für Checklisten vorhanden`);
        //     return false;
        // }

        for (const checkliste of checklisten) {
            let ergebnis = auftrag.ChecklistenErgebnis.find(p => p.ChecklisteName == checkliste.name);

            if (!ergebnis) {
                ergebnis = {
                    ChecklisteName: checkliste.name,
                    Eintraege: []
                };

                auftrag.ChecklistenErgebnis.push(ergebnis);
            }

            for (const eintrag of checkliste.eintraege) {
                let status = ergebnis.Eintraege.find(p => p.Key == eintrag.key);

                const config = ChecklistHelper.parseConfig(eintrag);

                if (config.default) {
                    if (!status) {
                        status = { Key: eintrag.key, Text: eintrag.text };
                        ergebnis.Eintraege.push(status);
                    }

                    if (!status.Status) {
                        status.Status = config.default;
                    }
                } else if (eintrag.antworten == '5') {
                    // Toggle
                    if (!status) {
                        status = { Key: eintrag.key, Text: eintrag.text };
                        ergebnis.Eintraege.push(status);
                    }

                    if (!status.Status) {
                        status.Status = 'nein';
                    }
                }

                if (!status) {
                    log.info(`Kein Status für Checkliste '${checkliste.name}' Eintrag: ${eintrag.text}`);
                    return false;
                }

                if (!status.Status) {
                    log.info(`Keine Antwort für Checkliste '${checkliste.name}' Eintrag: ${eintrag.text}`);
                    return false;
                }
            }
        }

        return true;
    }

    async preufeAuftragNichtZugewiesen(auftrag: AuftragEx): Promise<boolean> {
        if (auftrag.Geraet === AppConfig.current.geraeteNummer) {
            return false;
        }

        const listItem = AuftragService.instance.getAuftragListItem(auftrag.Key);

        return await this.preufeAuftragListItemNichtZugewiesen(listItem);
    }

    async preufeAuftragListItemNichtZugewiesen(auftrag: AuftragListItem): Promise<boolean> {
        if (auftrag.Geraet === AppConfig.current.geraeteNummer) {
            return false;
        }

        switch (auftrag.Auftragstyp) {
            case Auftragstyp.Transportauftrag:
                if (AppConfig.current.einstellungen.TransportauftraegeAnGeraetegruppeManuellZuweisen !== 'ja') {
                    return false;
                }
                break;

            case Auftragstyp.Hofchecker:
                if (AppConfig.current.einstellungen.HofauftraegeAnGeraetegruppeManuellZuweisen !== 'ja') {
                    return false;
                }
                break;

            case Auftragstyp.Kommissionierung:
                if (AppConfig.current.einstellungen.KommissionierungAuftraegeAnGeraetegruppeManuellZuweisen !== 'ja') {
                    return false;
                }

                // Oberaufträge müssen nicht zugewiesen werden
                if (!auftrag.OberAuftragKey) {
                    return false;
                }

                break;

            case Auftragstyp.Wareneingang:
                return false; // TODO: Eventuell konfigurierbar machen
        }

        if (auftrag.GeraeteGruppe) {
            const ok = await UiHelper.confirmJaNein('Auftrag wurde noch nicht diesem Gerät zugewiesen. Jetzt zuweisen?');

            if (ok) {
                const success = await this.zuweisenAuftrag(auftrag, true);

                if (success) {
                    return false;
                }
            }

            return true;
        } else {
            return false;
        }
    }

    async zuweisenAuftrag(auftragListItem: AuftragListItem, zugewiesen: boolean): Promise<boolean> {
        let success = false;

        App.loadingDelayed(true);

        try {
            const result = await RemoteService.instance.zuweisenAuftrag({
                Key: auftragListItem.Key,
                Zuweisen: zugewiesen
            });

            App.loading(false);

            if (!result) {
                UiHelper.showFehlerKommunikation();
                return;
            }

            if (result.success) {
                if (zugewiesen) {
                    auftragListItem.Auftragsstatus = Auftragsstatus.Zugewiesen;
                    auftragListItem.Geraet = AppConfig.current.geraeteNummer;
                    auftragListItem.istZugewiesen = zugewiesen;
                    auftragListItem.istAnderemGraetZugewiesen = false;
                } else {
                    auftragListItem.Auftragsstatus = Auftragsstatus.Neu;
                    auftragListItem.Geraet = null;
                    auftragListItem.istZugewiesen = false;
                }

                const auftrag = await AuftragService.instance.getAuftrag(auftragListItem.Key);

                if (zugewiesen) {
                    auftrag.Geraet = AppConfig.current.geraeteNummer;

                    if (auftrag.Auftragsstatus < 35) {
                        auftrag.Auftragsstatus = 35;
                    }
                } else {
                    // Zuweisung wurde aufgehoben
                    auftrag.Geraet = '';

                    if (auftrag.Auftragsstatus < 90) {
                        auftrag.Auftragsstatus = 10;
                    }
                }

                await AuftragService.instance.speichereAuftrag(auftrag);

                success = true;
            } else {
                UiHelper.showError(result.message, 'Fehler', false, false);
                auftragListItem.istZugewiesen = false;
            }
        } catch (err) {
            App.loading(false);

            UiHelper.showError(err);
            auftragListItem.istZugewiesen = false;
        }

        setTimeout(() => {
            this.detectChanges.next('zuweisenAuftrag');
            // this.detectChanges('zuweisenAuftrag');
        });

        return success;
    }

    async starteNavigation(adresse: Adresse) {
        log.info('starteNavigation');

        if (!await this.pruefeZeiterfassungGestartet()) {
            return;
        }

        if (this.isNavigationGesperrt) {
            this.showAuftragSchreibgeschuetzt();
            return;
        }

        SystemService.instance.starteNavigation(adresse);

        // Navigation kann auch bei schreibgeschütztem Auftrag gestartet werden.
        // Das wird hauptsächlich am Vorabend von den Fahrern verwendet, um bereits die Tour anzuschauen.
        // Es soll dann aber keine Nachricht an den Server geschickt werden
        if (!this.isReadOnly) {
            SystemService.instance.sendeTextdatei({
                typ: TextdateiTyp.StartNavigation,
                Navigation: {
                    Adresse: adresse,
                    AuftragKey: this.auftrag?.Key
                }
            }, true);
        }

        // this.starteAuftragWennErforderlich('Navigation');
    }

    /**
     * Prüft ob die Zeiterfassung gestartet wurde und frägt den Benutzer ob er diese eventuell starten möchte.
     * Gibt FALSE zurück, falls die Zeiterfassung erforderlich ist und nicht gestartet wurde 
     */
    async pruefeZeiterfassungGestartet(): Promise<boolean> {
        if (AppConfig.current.einstellungen.AuftragZeiterfassungAktiv !== 'ja') {
            // Zeiterfassugn nicht aktiv. Deshalb alles gut, also TRUE zurückgeben
            return true;
        }

        if (this.auftrag.zeiterfassungLaeuft) {
            return true;
        }

        // Wenn ein manuell bearbeiteter Wert vorhanden ist, dann darf auch immer bearbeitet werden.
        // Ansonsten hat man nicht die möglichkeit einen Wert manuell einzugeben und anschließend den Auftrag fertig zu melden
        const zeiten = Zeiterfassung.getAuftragszeiten(this.auftrag);

        if (zeiten.find(p => p.Typ === 'Manuell')) {
            return true;
        }

        const ok = await UiHelper.confirmJaNein('Zeiterfassung starten?');

        if (ok) {
            if (AuftragHelper.isAuftragImArchiv(this.auftrag)) {
                UiHelper.showErrorLight('Zeiterfassung für archivierte Aufträge nicht erlaubt');
                return false;
            }

            if (this.isReadOnly) {
                UiHelper.showErrorLight('Auftrag ist schreibgeschützt. Zeiterfassung kann nicht gestartet werden.');
                return;
            }

            let art: ArbeitsartKonfiguration = null;

            if (AppConfig.current.einstellungen.AuftragZeiterfassungTyp === 'MitArt') {
                art = AppConfig.current.arbeitszeitKonfiguration.AuftragArbeitszeitArten.find(p => p.Standard);

                if (!art) {
                    UiHelper.showErrorLight('Keine Standard-Arbeitszeit konfiguriert. Zeiterfassung muss manuell gestartet werden.');
                    return false;
                }
            }

            return await this.starteZeiterfassung(art);
        }

        return false;
    }

    async starteZeiterfassung(art: ArbeitsartKonfiguration = null): Promise<boolean> {
        if (AuftragHelper.isAuftragImArchiv(this.auftrag)) {
            return false;
        }

        // Prüfe ob noch die Zeiterfassung von einem anderen Auftrag läuft. Wenn ja, dann muss diese erst gestoppt werden
        if (AppConfig.current.einstellungen.AuftragZeiterfassungNurEinAktiverAuftrag === "ja") {
            const ok = await Zeiterfassung.beendeLaufendeZeiterfassungMitRueckfrage();

            if (!ok) {
                return false;
            }
        }

        const ok = await this.auftragService.starteZeiterfassung(this.auftrag, art);

        if (ok) {
            this.starteAuftragWennErforderlich('starteZeiterfassung');
        }

        this.updateAuftragReadOnly();

        this.detectChanges.next('starteZeiterfassung');

        this.auftragService.zeiterfassungLetzterAuftragKey = null;
    }

    async unterbrecheZeiterfassung() {
        await this.auftragService.unterbrecheZeiterfassung(this.auftrag);

        this.updateAuftragReadOnly();

        this.detectChanges.next('unterbrecheZeiterfassung');
    }

    getEinstellung(komponente: AuftragsKomponente, key: string, defaultValue: string): string {
        if (!komponente || !komponente.einstellungen) {
            return defaultValue;
        }

        const einstellung = komponente.einstellungen.find(p => p.key === key);

        if (einstellung) {
            if (einstellung.wert !== null && einstellung.wert !== '' && einstellung.wert !== undefined) {
                return einstellung.wert.trim();
            }
        }

        return defaultValue;
    }

    getStyle(komponente: AuftragsKomponente): string {
        let result = '';

        const opacity = this.getEinstellung(komponente, 'Opacity', null);

        if (opacity) {
            result = 'opacity: ' + opacity + ';';
        }

        return result;
    }

    async anrufen(adresse: Adresse) {
        log.debug('onAnrufen');

        const auftrag = this.auftrag;
        const kontakte = AuftragHelper.getAdressKontakteZumAnrufen(auftrag, adresse);

        if (kontakte.length == 1) {
            // Direkt anrufen
            SystemService.instance.anrufenTelefonnummer(kontakte[0].Telefon);
        } else {
            this.isModalSichtbar = true;

            const modal = await this.modalController.create({
                component: KontaktlisteComponent,
                cssClass: 'kontaktliste-modal',
                swipeToClose: true,
                animated: true,
                backdropDismiss: true,
                showBackdrop: true,
                componentProps: {
                    kontakte: kontakte
                }
            });

            await modal.present();

            const result = await modal.onDidDismiss();

            this.isModalSichtbar = false;

            if (result && result.data) {
                const kontakt = result.data as Kontakt;
                SystemService.instance.anrufenTelefonnummer(kontakt.Telefon);
            }
        }
    }

    async frageAdresseAktion(komponente: AuftragsKomponente, adresse: Adresse): Promise<{ aktion: string, data?: any }> {
        return new Promise(async resolve => {
            const buttons: any[] = [];

            if (this.auftrag.IstNeuanlage) {
                buttons.push({
                    text: 'Adresse ändern',
                    icon: 'create',
                    handler: () => {
                        resolve({ aktion: 'adresse-aendern' });
                    }
                });
            }

            if (this.getEinstellung(komponente, "Navigation", "ja") === 'ja') {
                const navigationMoeglich = this.workflow.einstellungen.Navigation !== 'nein';

                if (navigationMoeglich) {
                    buttons.push({
                        text: 'Navigation starten',
                        icon: 'navigate',
                        handler: () => {
                            resolve({ aktion: 'navigation' });
                        }
                    });
                }
            }

            const kontakte = AuftragHelper.getAdressKontakteZumAnrufen(this.auftrag, adresse);

            for (const kontakt of kontakte) {
                let name = kontakt.Name;

                if (!name) {
                    name = kontakt.Telefon;
                }

                buttons.push({
                    text: name + ' anrufen\n' + kontakt.Telefon,
                    icon: 'call',
                    handler: () => {
                        resolve({ aktion: 'Anrufen', data: kontakt });
                    }
                });
            }

            if (buttons.length == 0) {
                return;
            }

            buttons.push({
                text: 'Abbruch',
                icon: 'close',
                role: 'cancel',
                handler: () => {
                    resolve(null);
                }
            });

            // const header = this.getEinstellung(komponente, 'Titel', 'Adresse');

            const alert = await this.alertController.create({
                cssClass: 'no-text-alert no-title-alert',
                mode: 'ios',
                buttons: buttons
            });

            await alert.present();

            // const actionSheet = await this.actionSheetController.create({
            //     header,
            //     buttons
            // });

            // await actionSheet.present();
        });
    }

    async ladeChecklisten(): Promise<void> {
        const namen: string[] = [];

        if (this.workflow && this.workflow.checkliste) {
            namen.push(this.workflow.checkliste);
        }

        if (this.auftrag.ChecklistenVorgabe) {
            for (const vorgabe of this.auftrag.ChecklistenVorgabe) {
                if (vorgabe.ChecklisteName && namen.indexOf(vorgabe.ChecklisteName) < 0) {
                    namen.push(vorgabe.ChecklisteName);
                }
            }
        }

        this.checklisten = [];

        // Werden Checklisten in der Formularkonfiguration angezeigt?
        // Wenn nicht ist etwas falsch konfiguriert
        if (namen.length) {
            const komponente = this.formularKonfiguration.komponenten.find(p => p.name === 'Checkliste');

            if (!komponente) {
                log.warn('Dieser Auftrag enthält eine Checkliste. Das Formular ist jedoch nicht für die Anzeige von Checklisten konfiguriert. Checkliste: ' + namen[0]);
                // UiHelper.showErrorOhneSentry('Dieser Auftrag enthält eine Checkliste. Das Formular ist jedoch nicht für die Anzeige von Checklisten konfiguriert. Bitte wenden Sie Sich an Ihren Administrator! Checkliste: ' + namen[0]);
                return;
            }
        }

        for (const checklisteName of namen) {
            const checkliste = await this.stammdatenService.getCheckliste(checklisteName);

            if (checkliste) {
                this.checklisten.push(checkliste);
            } else {
                UiHelper.showError('Checkliste nicht gefunden: ' + checklisteName);
            }
        }

        log.debug('ladeChecklisten', this.checklisten);
    }

    initButtons() {
        this.buttons = [];
        this.buttons2 = [];
        this.buttons3 = [];
        this.buttonsAuftragPopup = [];

        const istAuftragAbgeschlossen = this.istAuftragAbgeschlossen;

        if (this.workflow) {
            if (!this.workflow.status) {
                this.workflow.status = [];
            }

            let moeglicheStatus = this.workflow.status;

            if (istAuftragAbgeschlossen) {
                moeglicheStatus = moeglicheStatus.filter(p => p.NachAbschlussErlaubt);
            }

            moeglicheStatus = moeglicheStatus.sort((a, b) => a.sortierung - b.sortierung);

            for (const workflowStatus of moeglicheStatus) {
                let color = 'light';

                if (workflowStatus.buttonFarbe) {
                    color = workflowStatus.buttonFarbe;
                }

                if (this.isStatusmeldungGesperrt) {
                    if (!workflowStatus.NachAbschlussErlaubt
                        && workflowStatus.auftragsstatus !== Auftragsstatus.Weiterleiten
                        && workflowStatus.auftragsstatus !== Auftragsstatus.Angenommen
                        && workflowStatus.auftragsstatus !== Auftragsstatus.Abgelehnt) {

                        continue;
                    }
                }

                if (this.auftrag?.Auftragstyp === Auftragstyp.Kommissionierung) {
                    // Vollpalette melden ist nur für Aufträge mit einer Position erlaubt
                    // Abgelehnt => Vollpalette: später holen
                    if (workflowStatus.auftragsstatus === Auftragsstatus.Abgelehnt) {
                        // "Abgelehnt" bedeutet, dass die Palette nicht kommissioniert wird, weil es sich um eine Vollpalette handelt
                        // Der Button soll nicht angezeigt werden, wenn es keine Vollpalette ist
                        if (!this.auftrag.istVollePalette) {
                            continue;
                        }
                    }
                }

                if (workflowStatus.buttonAnzeigen) {
                    this.buttons.push({
                        workflowStatus,
                        color,
                        icon: workflowStatus.icon,
                        text: workflowStatus.name
                    })
                }

                if (workflowStatus.ButtonAnzeigen2) {
                    this.buttons2.push({
                        workflowStatus,
                        color,
                        icon: workflowStatus.icon,
                        text: workflowStatus.name
                    })
                }

                if (workflowStatus.ButtonAnzeigen3) {
                    this.buttons3.push({
                        workflowStatus,
                        color,
                        icon: workflowStatus.icon,
                        text: workflowStatus.name
                    })
                }

                if (workflowStatus.InAuftragsPopupAnzeigen) {
                    this.buttonsAuftragPopup.push({
                        workflowStatus,
                        color,
                        icon: workflowStatus.icon,
                        text: workflowStatus.name
                    })
                }
            }

            if (this.workflow.einstellungen && this.workflow.einstellungen.StatusmeldungButtonAnzeigen !== 'nein') {
                // Wurden bereits alle Buttons hinzugefügt, dann ist kein extra "Statusmeldung"-Button mehr notwendig
                if (this.buttons.length < moeglicheStatus.length) {
                    let text = I18N.instant('Statusmeldung') + '...';

                    if (this.auftrag.Auftragstyp === Auftragstyp.Kommissionierung) {
                        // TODO: Sollte konfigurierbar sein
                        text = '...';
                    }

                    this.buttons.push({
                        text: text,
                        color: 'light',
                        workflowStatus: null,
                        icon: null
                    })
                }
            }
        }
    }

    async onFotoAufnehmen() {
        if (this.istAuftragAbgeschlossen) {
            UiHelper.showAlert(I18N.instant('AuftragBereitsAbgeschlossen'));
            return;
        }

        if (this.isBilderErfassenGesperrt) {
            UiHelper.showAlert(I18N.instant('AuftragDarfNichtGeaendertWerden'));
            return;
        }

        if (!await this.pruefeZeiterfassungGestartet()) {
            return;
        }

        // imageData is a base64 encoded string
        // this.base64Image = "data:image/jpeg;base64," + imageData;
        let pictureSrc: string = null;

        this.kameraAktiv = true;

        try {
            pictureSrc = await this.camera.getPicture({
                destinationType: this.camera.DestinationType.FILE_URI,
                encodingType: this.camera.EncodingType.JPEG,
                mediaType: this.camera.MediaType.PICTURE,
                sourceType: this.camera.PictureSourceType.CAMERA,
                quality: App.current.qualitaetFotos,
                targetWidth: App.current.aufloesungFotos,
                targetHeight: App.current.aufloesungFotos,
                correctOrientation: true,

            });

            // Beispiel: pictureSrc='file:///storage/emulated/0/Android/data/de.recomobil.app/cache/1570482608018.jpg'

            // log.info('pictureSrc: ' + pictureSrc);

            if (pictureSrc && pictureSrc.startsWith('file:')) {
                // Extract just the filename. Result example: cdv_photo_003.jpg
                const tempFilename = FileHelper.getFilename(pictureSrc);

                // Now, the opposite. Extract the full path, minus filename.
                // Result example: file:///var/mobile/Containers/Data/Application
                // /E4A79B4A-E5CB-4E0C-A7D9-0603ECD48690/tmp/
                const tempBaseFilesystemPath = FileHelper.getDirectory(pictureSrc);

                // Get the Data directory on the device.
                // Result example: file:///var/mobile/Containers/Data/Application
                // /E4A79B4A-E5CB-4E0C-A7D9-0603ECD48690/Library/NoCloud/
                const newBaseFilesystemPath = this.file.dataDirectory;
                const newFilename = FileHelper.removeIllegalFilenameChars('auftrag-' + this.auftrag.Auftragsnummer + '-' + tempFilename);

                const entry = await this.file.copyFile(tempBaseFilesystemPath, tempFilename, newBaseFilesystemPath, newFilename);

                log.info('copyFile', entry);

                // log.info('newBaseFilesystemPath', newBaseFilesystemPath);
                // log.info('newFilename', newFilename);

                // Result example: file:///var/mobile/Containers/Data/Application
                // /E4A79B4A-E5CB-4E0C-A7D9-0603ECD48690/Library/NoCloud/cdv_photo_003.jpg
                pictureSrc = newBaseFilesystemPath + newFilename;

                let text = AppConfig.current.einstellungen.FotosTextAuftrag;

                if (text) {
                    const platzhalter = DruckerHelper.erstellePlatzhalter(
                        this.auftrag,
                        App.current.fahrer.getValue(),
                        App.current.fahrzeug.getValue(),
                        null);

                    if (text.includes('{GPS}')) {
                        platzhalter['GPS'] = await SystemService.instance.getAktuelleGpsPositionStr('Bild', 10);
                    }

                    const base64DataUrl = await this.file.readAsDataURL(newBaseFilesystemPath, newFilename);

                    const blob = await ImageHelper.writeImageText(base64DataUrl, text, platzhalter);

                    await this.file.writeExistingFile(newBaseFilesystemPath, newFilename, blob);
                }

                // await this.camera.cleanup();
            }

        } catch (err) {
            log.warn('camera error: ' + Utils.getErrorMessage(err), err);

            if (err === "cordova_not_available") {
                err = 'Kamera im Browser nicht verfügbar.';

                // Derzeit nur zum testen
                pictureSrc = 'R0lGODlhPQBEAPeoAJosM//AwO/AwHVYZ/z595kzAP/s7P+goOXMv8+fhw/v739/f+8PD98fH/8mJl+fn/9ZWb8/PzWlwv///6wWGbImAPgTEMImIN9gUFCEm/gDALUL'
                    + 'DN8PAD6atYdCTX9gUNKlj8wZAKUsAOzZz+UMAOsJAP/Z2ccMDA8PD/95eX5NWvsJCOVNQPtfX/8zM8+QePLl38MGBr8JCP+zs9myn/8GBqwpAP/GxgwJCPny78lzYLgjAJ8vAP9'
                    + 'fX/+MjMUcAN8zM/9wcM8ZGcATEL+QePdZWf/29uc/P9cmJu9MTDImIN+/r7+/vz8/P8VNQGNugV8AAF9fX8swMNgTAFlDOICAgPNSUnNWSMQ5MBAQEJE3QPIGAM9AQMqGcG9vb6'
                    + 'MhJsEdGM8vLx8fH98AANIWAMuQeL8fABkTEPPQ0OM5OSYdGFl5jo+Pj/+pqcsTE78wMFNGQLYmID4dGPvd3UBAQJmTkP+8vH9QUK+vr8ZWSHpzcJMmILdwcLOGcHRQUHxwcK9PT'
                    + '9DQ0O/v70w5MLypoG8wKOuwsP/g4P/Q0IcwKEswKMl8aJ9fX2xjdOtGRs/Pz+Dg4GImIP8gIH0sKEAwKKmTiKZ8aB/f39Wsl+LFt8dgUE9PT5x5aHBwcP+AgP+WltdgYMyZfyyw'
                    + 'z78AAAAAAAD///8AAP9mZv///wAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA'
                    + 'AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA'
                    + 'AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACH5BAEAAKgALAAAAAA9AEQAAAj/AFEJH'
                    + 'EiwoMGDCBMqXMiwocAbBww4nEhxoYkUpzJGrMixogkfGUNqlNixJEIDB0SqHGmyJSojM1bKZOmyop0gM3Oe2liTISKMOoPy7GnwY9CjIYcSRYm0aVKSLmE6nfq05QycVLPuhDrx'
                    + 'BlCtYJUqNAq2bNWEBj6ZXRuyxZyDRtqwnXvkhACDV+euTeJm1Ki7A73qNWtFiF+/gA95Gly2CJLDhwEHMOUAAuOpLYDEgBxZ4GRTlC1fDnpkM+fOqD6DDj1aZpITp0dtGCDhr+f'
                    + 'VuCu3zlg49ijaokTZTo27uG7Gjn2P+hI8+PDPERoUB318bWbfAJ5sUNFcuGRTYUqV/3ogfXp1rWlMc6awJjiAAd2fm4ogXjz56aypOoIde4OE5u/F9x199dlXnnGiHZWEYbGpsA'
                    + 'EA3QXYnHwEFliKAgswgJ8LPeiUXGwedCAKABACCN+EA1pYIIYaFlcDhytd51sGAJbo3onOpajiihlO92KHGaUXGwWjUBChjSPiWJuOO/LYIm4v1tXfE6J4gCSJEZ7YgRYUNrkji'
                    + '9P55sF/ogxw5ZkSqIDaZBV6aSGYq/lGZplndkckZ98xoICbTcIJGQAZcNmdmUc210hs35nCyJ58fgmIKX5RQGOZowxaZwYA+JaoKQwswGijBV4C6SiTUmpphMspJx9unX4Kaimj'
                    + 'Dv9aaXOEBteBqmuuxgEHoLX6Kqx+yXqqBANsgCtit4FWQAEkrNbpq7HSOmtwag5w57GrmlJBASEU18ADjUYb3ADTinIttsgSB1oJFfA63bduimuqKB1keqwUhoCSK374wbujvOS'
                    + 'u4QG6UvxBRydcpKsav++Ca6G8A6Pr1x2kVMyHwsVxUALDq/krnrhPSOzXG1lUTIoffqGR7Goi2MAxbv6O2kEG56I7CSlRsEFKFVyovDJoIRTg7sugNRDGqCJzJgcKE0ywc0ELm6'
                    + 'KBCCJo8DIPFeCWNGcyqNFE06ToAfV0HBRgxsvLThHn1oddQMrXj5DyAQgjEHSAJMWZwS3HPxT/QMbabI/iBCliMLEJKX2EEkomBAUCxRi42VDADxyTYDVogV+wSChqmKxEKCDAY'
                    + 'FDFj4OmwbY7bDGdBhtrnTQYOigeChUmc1K3QTnAUfEgGFgAWt88hKA6aCRIXhxnQ1yg3BCayK44EWdkUQcBByEQChFXfCB776aQsG0BIlQgQgE8qO26X1h8cEUep8ngRBnOy74E'
                    + '9QgRgEAC8SvOfQkh7FDBDmS43PmGoIiKUUEGkMEC/PJHgxw0xH74yx/3XnaYRJgMB8obxQW6kL9QYEJ0FIFgByfIL7/IQAlvQwEpnAC7DtLNJCKUoO/w45c44GwCXiAFB/OXAAT'
                    + 'QryUxdN4LfFiwgjCNYg+kYMIEFkCKDs6PKAIJouyGWMS1FSKJOMRB/BoIxYJIUXFUxNwoIkEKPAgCBZSQHQ1A2EWDfDEUVLyADj5AChSIQW6gu10bE/JG2VnCZGfo4R4d0sdQoB'
                    + 'AHhPjhIB94v/wRoRKQWGRHgrhGSQJxCS+0pCZbEhAAOw==';
            } else if (err == 20) {
                await UiHelper.showAlert('ReCoMobil hat kein Zugriff auf die Kamera. Bitte Berechtigung für die Kamera in den App-Einstellungen aktivieren', 'Fehler', true);
            } else if (err == 'No Image Selected') {
                // Aufnahme wurde abgebrochen
            } else {
                UiHelper.showError('Fehler Kamera: ' + Utils.getErrorMessage(err), 'Fehler', false, false);
            }
        }

        if (!pictureSrc) {
            setTimeout(() => {
                this.kameraAktiv = false;
            }, 250);

            return;
        }

        log.debug('pictureSrc', pictureSrc);

        const bild: ReCoMobilBild = {
            Datum: new Date().toISOString(),
            // Bild: imageData,
            BildGuid: Utils.uuid()
        };

        await AuftragService.instance.speichereBild(bild.BildGuid, pictureSrc);

        if (!this.auftrag.Auftragsbilder) {
            this.auftrag.Auftragsbilder = [];
        }

        this.auftrag.Auftragsbilder.push(bild);

        await AuftragService.instance.speichereAuftrag(this.auftrag);

        this.auftragsbilder.push(await this.createBildVo(bild));

        await this.starteAuftragWennErforderlich('onFotoAufnehmen');

        this.detectChanges.next('onFotoAufnehmen');

        setTimeout(() => {
            this.kameraAktiv = false;
        }, 250);

        // setTimeout(() => this.content.scrollToBottom(300), 250);
    }


    async onFotoAuswaehlen() {
        if (this.istAuftragAbgeschlossen) {
            UiHelper.showAlert(I18N.instant('AuftragBereitsAbgeschlossen'));
            return;
        }

        if (this.isBilderErfassenGesperrt) {
            UiHelper.showAlert(I18N.instant('AuftragDarfNichtGeaendertWerden'));
            return;
        }

        if (!await this.pruefeZeiterfassungGestartet()) {
            return;
        }

        // imageData is a base64 encoded string
        // this.base64Image = "data:image/jpeg;base64," + imageData;
        let pictureSrc: string = null;

        this.kameraAktiv = true;

        try {
            pictureSrc = await this.camera.getPicture({
                sourceType: this.camera.PictureSourceType.PHOTOLIBRARY, // Fotobibliothek auswählen
                destinationType: this.camera.DestinationType.FILE_URI, // Dateipfad zurückgeben
                quality: 100, // Bildqualität
                targetWidth: 600, // Bildbreite
                targetHeight: 600, // Bildhöhe
                encodingType: this.camera.EncodingType.JPEG, // Bildformat
                correctOrientation: true // Ausrichtung korrigieren
            });

            // Beispiel: pictureSrc='file:///storage/emulated/0/Android/data/de.recomobil.app/cache/1570482608018.jpg?1234567890'

            log.info('pictureSrc: ' + pictureSrc);

            if (pictureSrc && pictureSrc.startsWith('file:')) {
                // Extract just the filename. Result example: cdv_photo_003.jpg
                const tempFilename = FileHelper.getFilename(pictureSrc);

                log.debug('tempFilename: ' + tempFilename);

                // Now, the opposite. Extract the full path, minus filename.
                // Result example: file:///var/mobile/Containers/Data/Application
                // /E4A79B4A-E5CB-4E0C-A7D9-0603ECD48690/tmp/
                const tempBaseFilesystemPath = FileHelper.getDirectory(pictureSrc);

                log.debug('tempBaseFilesystemPath: ' + tempBaseFilesystemPath);

                // Get the Data directory on the device.
                // Result example: file:///var/mobile/Containers/Data/Application
                // /E4A79B4A-E5CB-4E0C-A7D9-0603ECD48690/Library/NoCloud/
                const newBaseFilesystemPath = this.file.dataDirectory;
                const newFilename = FileHelper.removeIllegalFilenameChars('auftrag-' + this.auftrag.Auftragsnummer + '-' + tempFilename);

                log.debug('newBaseFilesystemPath: ' + newBaseFilesystemPath);
                log.debug('newFilename: ' + newFilename);

                const entry = await this.file.copyFile(tempBaseFilesystemPath, tempFilename, newBaseFilesystemPath, newFilename);

                log.info('copyFile', entry);

                // log.info('newBaseFilesystemPath', newBaseFilesystemPath);
                // log.info('newFilename', newFilename);

                // Result example: file:///var/mobile/Containers/Data/Application
                // /E4A79B4A-E5CB-4E0C-A7D9-0603ECD48690/Library/NoCloud/cdv_photo_003.jpg
                pictureSrc = newBaseFilesystemPath + newFilename;

                let text = AppConfig.current.einstellungen.FotosTextAuftrag;

                if (text) {
                    const platzhalter = DruckerHelper.erstellePlatzhalter(
                        this.auftrag,
                        App.current.fahrer.getValue(),
                        App.current.fahrzeug.getValue(),
                        null);

                    if (text.includes('{GPS}')) {
                        platzhalter['GPS'] = await SystemService.instance.getAktuelleGpsPositionStr('Bild', 10);
                    }

                    const base64DataUrl = await this.file.readAsDataURL(newBaseFilesystemPath, newFilename);

                    const blob = await ImageHelper.writeImageText(base64DataUrl, text, platzhalter);

                    await this.file.writeExistingFile(newBaseFilesystemPath, newFilename, blob);
                }

                // await this.camera.cleanup();
            }

        } catch (err) {
            log.warn('camera error: ' + Utils.getErrorMessage(err), err);

            if (err === "cordova_not_available") {
                err = 'Kamera im Browser nicht verfügbar.';

                // Derzeit nur zum testen
                pictureSrc = 'R0lGODlhPQBEAPeoAJosM//AwO/AwHVYZ/z595kzAP/s7P+goOXMv8+fhw/v739/f+8PD98fH/8mJl+fn/9ZWb8/PzWlwv///6wWGbImAPgTEMImIN9gUFCEm/gDALUL'
                    + 'DN8PAD6atYdCTX9gUNKlj8wZAKUsAOzZz+UMAOsJAP/Z2ccMDA8PD/95eX5NWvsJCOVNQPtfX/8zM8+QePLl38MGBr8JCP+zs9myn/8GBqwpAP/GxgwJCPny78lzYLgjAJ8vAP9'
                    + 'fX/+MjMUcAN8zM/9wcM8ZGcATEL+QePdZWf/29uc/P9cmJu9MTDImIN+/r7+/vz8/P8VNQGNugV8AAF9fX8swMNgTAFlDOICAgPNSUnNWSMQ5MBAQEJE3QPIGAM9AQMqGcG9vb6'
                    + 'MhJsEdGM8vLx8fH98AANIWAMuQeL8fABkTEPPQ0OM5OSYdGFl5jo+Pj/+pqcsTE78wMFNGQLYmID4dGPvd3UBAQJmTkP+8vH9QUK+vr8ZWSHpzcJMmILdwcLOGcHRQUHxwcK9PT'
                    + '9DQ0O/v70w5MLypoG8wKOuwsP/g4P/Q0IcwKEswKMl8aJ9fX2xjdOtGRs/Pz+Dg4GImIP8gIH0sKEAwKKmTiKZ8aB/f39Wsl+LFt8dgUE9PT5x5aHBwcP+AgP+WltdgYMyZfyyw'
                    + 'z78AAAAAAAD///8AAP9mZv///wAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA'
                    + 'AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA'
                    + 'AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACH5BAEAAKgALAAAAAA9AEQAAAj/AFEJH'
                    + 'EiwoMGDCBMqXMiwocAbBww4nEhxoYkUpzJGrMixogkfGUNqlNixJEIDB0SqHGmyJSojM1bKZOmyop0gM3Oe2liTISKMOoPy7GnwY9CjIYcSRYm0aVKSLmE6nfq05QycVLPuhDrx'
                    + 'BlCtYJUqNAq2bNWEBj6ZXRuyxZyDRtqwnXvkhACDV+euTeJm1Ki7A73qNWtFiF+/gA95Gly2CJLDhwEHMOUAAuOpLYDEgBxZ4GRTlC1fDnpkM+fOqD6DDj1aZpITp0dtGCDhr+f'
                    + 'VuCu3zlg49ijaokTZTo27uG7Gjn2P+hI8+PDPERoUB318bWbfAJ5sUNFcuGRTYUqV/3ogfXp1rWlMc6awJjiAAd2fm4ogXjz56aypOoIde4OE5u/F9x199dlXnnGiHZWEYbGpsA'
                    + 'EA3QXYnHwEFliKAgswgJ8LPeiUXGwedCAKABACCN+EA1pYIIYaFlcDhytd51sGAJbo3onOpajiihlO92KHGaUXGwWjUBChjSPiWJuOO/LYIm4v1tXfE6J4gCSJEZ7YgRYUNrkji'
                    + '9P55sF/ogxw5ZkSqIDaZBV6aSGYq/lGZplndkckZ98xoICbTcIJGQAZcNmdmUc210hs35nCyJ58fgmIKX5RQGOZowxaZwYA+JaoKQwswGijBV4C6SiTUmpphMspJx9unX4Kaimj'
                    + 'Dv9aaXOEBteBqmuuxgEHoLX6Kqx+yXqqBANsgCtit4FWQAEkrNbpq7HSOmtwag5w57GrmlJBASEU18ADjUYb3ADTinIttsgSB1oJFfA63bduimuqKB1keqwUhoCSK374wbujvOS'
                    + 'u4QG6UvxBRydcpKsav++Ca6G8A6Pr1x2kVMyHwsVxUALDq/krnrhPSOzXG1lUTIoffqGR7Goi2MAxbv6O2kEG56I7CSlRsEFKFVyovDJoIRTg7sugNRDGqCJzJgcKE0ywc0ELm6'
                    + 'KBCCJo8DIPFeCWNGcyqNFE06ToAfV0HBRgxsvLThHn1oddQMrXj5DyAQgjEHSAJMWZwS3HPxT/QMbabI/iBCliMLEJKX2EEkomBAUCxRi42VDADxyTYDVogV+wSChqmKxEKCDAY'
                    + 'FDFj4OmwbY7bDGdBhtrnTQYOigeChUmc1K3QTnAUfEgGFgAWt88hKA6aCRIXhxnQ1yg3BCayK44EWdkUQcBByEQChFXfCB776aQsG0BIlQgQgE8qO26X1h8cEUep8ngRBnOy74E'
                    + '9QgRgEAC8SvOfQkh7FDBDmS43PmGoIiKUUEGkMEC/PJHgxw0xH74yx/3XnaYRJgMB8obxQW6kL9QYEJ0FIFgByfIL7/IQAlvQwEpnAC7DtLNJCKUoO/w45c44GwCXiAFB/OXAAT'
                    + 'QryUxdN4LfFiwgjCNYg+kYMIEFkCKDs6PKAIJouyGWMS1FSKJOMRB/BoIxYJIUXFUxNwoIkEKPAgCBZSQHQ1A2EWDfDEUVLyADj5AChSIQW6gu10bE/JG2VnCZGfo4R4d0sdQoB'
                    + 'AHhPjhIB94v/wRoRKQWGRHgrhGSQJxCS+0pCZbEhAAOw==';
            } else if (err == 20) {
                await UiHelper.showAlert('ReCoMobil hat kein Zugriff auf die Kamera. Bitte Berechtigung für die Kamera in den App-Einstellungen aktivieren', 'Fehler', true);
            } else if (err == 'No Image Selected') {
                // Aufnahme wurde abgebrochen
            } else {
                UiHelper.showError('Fehler Kamera: ' + Utils.getErrorMessage(err), 'Fehler', false, false);
            }
        }

        if (!pictureSrc) {
            setTimeout(() => {
                this.kameraAktiv = false;
            }, 250);

            return;
        }

        log.debug('pictureSrc', pictureSrc);

        const bild: ReCoMobilBild = {
            Datum: new Date().toISOString(),
            // Bild: imageData,
            BildGuid: Utils.uuid()
        };

        await AuftragService.instance.speichereBild(bild.BildGuid, pictureSrc);

        if (!this.auftrag.Auftragsbilder) {
            this.auftrag.Auftragsbilder = [];
        }

        this.auftrag.Auftragsbilder.push(bild);

        await AuftragService.instance.speichereAuftrag(this.auftrag);

        this.auftragsbilder.push(await this.createBildVo(bild));

        await this.starteAuftragWennErforderlich('onFotoAufnehmen');

        this.detectChanges.next('onFotoAufnehmen');

        setTimeout(() => {
            this.kameraAktiv = false;
        }, 250);

        // setTimeout(() => this.content.scrollToBottom(300), 250);
    }

    frageBildAktion(): Promise<string> {
        return new Promise(async resolve => {
            if (this.istAuftragAbgeschlossen) {
                // Keine Buttons notwendig. Bild kann nur angezeigt werden
                resolve('anzeigen');
                return;
            }

            const buttons: any[] = [];

            buttons.push({
                text: 'Löschen',
                role: 'destructive',
                icon: 'trash',
                handler: () => {
                    resolve('loeschen');
                }
            });

            buttons.push({
                text: 'Anzeigen',
                icon: 'image',
                handler: () => {
                    resolve('anzeigen');
                }
            });

            buttons.push({
                text: 'Abbruch',
                icon: 'close',
                role: 'cancel',
                handler: () => {
                    resolve(null);
                }
            });

            const alert = await this.alertController.create({
                cssClass: 'no-text-alert no-title-alert',
                mode: 'ios',
                buttons: buttons
            });

            await alert.present();
        });
    }

    async onBildClicked(vo: BildVO) {
        const aktion = await this.frageBildAktion();

        if (aktion === 'loeschen') {
            const ok = true; //  await UiHelper.confirmJaNein('', 'Bild wirklich löschen?');

            if (ok) {
                const idx = this.auftrag.Auftragsbilder.findIndex(p => p.BildGuid === vo.guid);

                if (idx > -1) {
                    const guid = vo.guid;

                    this.auftrag.Auftragsbilder.splice(idx, 1);

                    await AuftragService.instance.speichereAuftrag(this.auftrag);

                    const idx2 = this.auftragsbilder.findIndex(p => p.guid === vo.guid);

                    if (idx2 > -1) {
                        this.auftragsbilder.splice(idx2, 1);
                    }

                    this.detectChanges.next('doBildClicked');

                    await AuftragService.instance.loescheBild(guid);
                }
            }
        } else if (aktion === 'anzeigen') {
            this.doBildAnzeigen(vo);
        }
    }

    async doBildAnzeigen(vo: BildVO) {
        if (!App.isCordovaAvailable()) {
            UiHelper.showAlert('Bild anzeigen in Simulation nicht verfügbar', 'Simulation');
            return;
        }

        const filePath = await AuftragService.instance.getBildSrc(vo.guid);
        await this.fileOpener.open(filePath, 'image/jpeg');
    }

    async lieferungAendern() {
        log.debug('lieferungAendern: ' + this.auftrag.Key);

        const modal = await this.modalController.create({
            component: AuftragLieferungPage,
            componentProps: {
                'auftrag': this.auftrag,
                'auftragdetails': this
            }
        });

        log.debug('lieferungAendern: AuftragLieferungPage wird angezeigt...');
        await modal.present();

        await modal.onDidDismiss();

        log.debug('lieferungAendern: geschlossen');

        await Utils.delay(250);

        if (!this.disposed) {
            this.updateLieferungVollstaendig('lieferungAendern');
            this.detectChanges.next();
        }
    }

    async leergutAendern() {
        log.debug('leergutAendern: ' + this.auftrag.Key);

        const modal = await this.modalController.create({
            component: AuftragLieferungPage,
            componentProps: {
                'auftrag': this.auftrag,
                'auftragdetails': this
            }
        });

        log.debug('leergutAendern: AuftragLieferungPage wird angezeigt...');
        await modal.present();

        await modal.onDidDismiss();

        log.debug('leergutAendern: geschlossen');

        await Utils.delay(250);

        if (!this.disposed) {
            this.updateLeergutVollstaendig('leergutAendern');
            this.detectChanges.next();
        }
    }

    async anzeigenBarcode() {
        const modal = await this.modalController.create({
            component: AuftragBarcodeModalPage,
            componentProps: {
                'auftrag': this.auftrag
            }
        });

        await modal.present();

        await modal.onDidDismiss();
    }

    async anzeigenAnmerkungen() {
        log.debug('anzeigenAnmerkungen: ' + this.auftrag.Key);

        if (this.isAnmerkungenErfassenGesperrt) {
            return;
        }

        if (!await this.pruefeZeiterfassungGestartet()) {
            return;
        }

        this.updateAuftragAbgeschlossen();

        if (this.istAuftragAbgeschlossen) {
            return;
        }

        const modal = await this.modalController.create({
            component: AuftragAnmerkungenPage,
            componentProps: {
                'auftrag': this.auftrag
            }
        });

        await modal.present();

        await modal.onWillDismiss();

        if (this.auftrag.Anmerkungen) {
            log.debug('Anmerkungen: ' + this.auftrag.Anmerkungen);

            // Anmerkungen wurden eingegeben.
            await this.starteAuftragWennErforderlich('Anmerkungen');
        }

        this.detectChanges.next('anzeigenAnmerkungen');
    }

    async starteAuftragWennErforderlich(info: string): Promise<void> {
        try {
            if (AppConfig.current.einstellungen.AuftragStartenBeiAenderung === 'ja') {
                if (this.auftrag.Auftragsstatus < Auftragsstatus.InBearbeitung) {
                    if (this.isAuftragReadOnly()) {
                        return;
                    }

                    log.debug(`Starte Auftrag ${this.auftrag.Key}: ${info}`);

                    // alert('starte auftrag: ' + this.auftrag.Auftragsstatus);

                    await this.auftragService.statusmeldung(this.auftrag, {
                        name: 'Auftrag start',
                        key: '0',
                        auftragsstatus: Auftragsstatus.InBearbeitung,
                    });

                    log.debug(`Starte Auftrag ${this.auftrag.Key}: neuer Auftragsstatus ${this.auftrag.Auftragsstatus}`);
                }
            }
        } catch (err) {
            log.error('starteAuftragWennErforderlich: ' + Utils.getErrorMessage(err));
        }
    }

    updateAuftragReadOnly() {
        this.isReadOnly = this.isAuftragReadOnly();

        // Wenn der ganze Auftrag schreibgeschützt ist (weil Tour nicht gestartet o.ä.), dann sind auch alle Einzelaktionen gesperrt
        this.isLieferungAendernGesperrt = this.isReadOnly;
        this.isBilderErfassenGesperrt = this.isReadOnly;
        this.isAnmerkungenErfassenGesperrt = this.isReadOnly;
        this.isStatusmeldungGesperrt = this.isReadOnly;
        this.isSchreibgeschuetztAnzeigen = this.isReadOnly;
        this.isNavigationGesperrt = this.isReadOnly;
        this.isUnterschreibenGesperrt = this.isReadOnly;

        // Zusätzlich können auch einzelne Aktionen, wie z.B. die Navigation gesperrt sein, damit man den Auftrag erst annehmen muss.
        if (Utils.isTrue(this.workflow?.einstellungen.SchreibgeschuetztVorStatusmeldung) && !this.isReadOnly) {
            if (!this.auftrag?.statusmeldungen?.length) {
                // Es wurden noch keine Statusmeldungen gemacht
                // this.isSchreibgeschuetztAnzeigen = true;
                this.isNavigationGesperrt = true;
                this.isLieferungAendernGesperrt = true;
                this.isBilderErfassenGesperrt = true;
                this.isAnmerkungenErfassenGesperrt = true;
            }
        }

        // Navigation ist nur gesperrt, wenn es explizit so konfiguriert ist
        if (!Utils.isFalse(this.workflow?.einstellungen.NavigationErlaubenWennSchreibgeschuetzt)) {
            this.isNavigationGesperrt = false;
        }

        if (AppConfig.current.einstellungen.AuftragZeiterfassungAktiv === 'ja') {
            if (!this.auftrag.zeiterfassungLaeuft) {
                this.isLieferungAendernGesperrt = true;
            }
        }
    }

    private isAuftragReadOnly(): boolean {
        if (!this.auftrag) {
            return false;
        }

        if (this.auftrag.Auftragsstatus >= Auftragsstatus.Abgeschlossen) {
            log.debug('Auftrag ist ReadOnly, weil Auftragsstatus >= 90: ' + this.auftrag.Auftragsstatus);
            return true;
        }

        const app = App.current;

        if (this.auftrag.Auftragstyp === Auftragstyp.Transportauftrag) {
            if (app.tourStartenErforderlich.getValue()) {
                if (!app.tourGestartet.getValue()) {
                    log.debug('Auftrag ist ReadOnly, weil Tour nicht gestartet');
                    return true;
                }
            }

            const maxAnzahlSichtbareAuftraege = Utils.parseInt(AppConfig.current.einstellungen.TransportauftragMaxSichtbareAuftraege);

            if (maxAnzahlSichtbareAuftraege > 0) {
                // Anzahl sichtbare Aufträge ist eingeschränkt.
                // Prüfe ob der Auftrag derzeit eigentlich nicht sichtbar ist.
                // Wenn ja, dann ist er readOnly
                const alleAuftraege = this.auftragService.alleAuftraege;

                if (alleAuftraege) {
                    const aktuelleTransportauftraege = this.auftragService.filterAktuelleAuftraege(alleAuftraege, Auftragstyp.Transportauftrag);

                    AuftragHelper.sortiereAuftraege(aktuelleTransportauftraege);

                    const index = aktuelleTransportauftraege.findIndex(p => p.Key === this.auftrag.Key);

                    if (index >= maxAnzahlSichtbareAuftraege) {
                        log.debug('Auftrag ist ReadOnly, weil noch nicht an der Reihe');
                        return true;
                    }
                }
            }

            // Standardmäßig auf aus setzen, damit der Parameter nicht aktiviert ist
            if (AppConfig.current.einstellungen.TransportauftragBearbeitbarRegel === 'nur-heute') {
                const heute = moment().endOf('day');
                const abholdatum = Utils.parseDatum(this.auftrag.AAbholdatum);

                if (abholdatum.isAfter(heute, 'day')) {
                    log.debug('Auftrag ist ReadOnly, weil erst ab einem bestimmten Datum änderbar');
                    return true;
                }
            }
        }

        // Standard: nicht readonly
        return false;
    }

    async seriennummernBearbeiten(position: AuftragspositionEx) {
        log.debug('seriennummernBearbeiten: ' + this.auftrag.Key + ', ' + position.PosNr);

        const modal = await this.modalController.create({
            component: SeriennummernListeComponent,
            componentProps: {
                'auftrag': this.auftrag,
                'position': position,
                'auftragdetails': this
            }
        });

        await modal.present();

        await modal.onDidDismiss();

        this.detectChanges.next('seriennummernBearbeiten');
    }

    async druckenAuftragsposition(position: AuftragspositionEx, sofortDrucken = true) {
        log.debug('druckenAuftragsposition: ' + this.auftrag.Key + ', ' + position.PosNr);

        // TODO: Abhängig machen vom Auftragstyp
        const druckformularName = 'Wareneingang';

        // const workflowStatus = this.workflow.status.find(p => p.auftragsstatus == Auftragsstatus.Gedruckt);

        // if (!workflowStatus) {
        //     UiHelper.showErrorOhneSentry('Drucken-Status nicht gefunden');
        //     return;
        // }

        const modal = await this.modalController.create({
            component: AuftragDruckenPage,
            componentProps: {
                'auftrag': this.auftrag,
                'position': position,
                'auftragdetails': this,
                'workflowStatus': null,
                'druckformularName': druckformularName,
                'sofortDrucken': sofortDrucken,
            }
        });

        await modal.present();

        await modal.onDidDismiss();

        this.detectChanges.next('druckenAuftragsposition');
    }

    async drucken(druckformularName: string) {
        log.debug('drucken: ' + this.auftrag.Key + ', ' + druckformularName);

        const workflowStatus = this.workflow.status.find(p => p.auftragsstatus == Auftragsstatus.Gedruckt);

        if (!workflowStatus) {
            UiHelper.showErrorOhneSentry('Drucken-Status nicht gefunden');
            return;
        }

        const modal = await this.modalController.create({
            component: AuftragDruckenPage,
            componentProps: {
                'auftrag': this.auftrag,
                'auftragdetails': this,
                'workflowStatus': workflowStatus,
                'druckformularName': druckformularName,
                'sofortDrucken': true
            }
        });

        await modal.present();

        await modal.onDidDismiss();

        this.detectChanges.next('anzeigenDrucken');
    }

    async anzeigenDrucken(workflowStatus: WorkflowStatus) {
        log.debug('anzeigenDrucken: ' + this.auftrag.Key);

        const modal = await this.modalController.create({
            component: AuftragDruckenPage,
            componentProps: {
                'auftrag': this.auftrag,
                'auftragdetails': this,
                'workflowStatus': workflowStatus
            }
        });

        await modal.present();

        await modal.onDidDismiss();

        this.detectChanges.next('anzeigenDrucken');
    }

    async anzeigenBezahlen(workflowStatus: WorkflowStatus) {
        log.debug('anmerkungenAnzeigen: ' + this.auftrag.Key);

        const modal = await this.modalController.create({
            component: AuftragBezahlenPage,
            componentProps: {
                'auftrag': this.auftrag,
                'auftragdetails': this,
                'workflowStatus': workflowStatus
            }
        });

        await modal.present();

        await modal.onDidDismiss();

        this.detectChanges.next('anzeigenBezahlen');
    }

    async anzeigenAdresseAendern(adresse: Adresse) {
        log.debug('anzeigenAdresseAendern: ' + this.auftrag.Key);

        const modal = await this.modalController.create({
            component: AuftragAdressePage,
            componentProps: {
                'auftrag': this.auftrag,
                'auftragdetails': this,
                'adresse': adresse
            }
        });

        await modal.present();

        await modal.onDidDismiss();

        this.detectChanges.next('anzeigenAdresseAendern');
    }

    async anzeigenWeiterleiten(workflowStatus: WorkflowStatus) {
        log.debug('anzeigenWeiterleiten: ' + this.auftrag.Key);

        const modal = await this.modalController.create({
            component: AuftragWeiterleitenPage,
            componentProps: {
                'auftrag': this.auftrag,
                'auftragdetails': this,
                'workflowStatus': workflowStatus
            }
        });

        await modal.present();

        const result = await modal.onDidDismiss();

        log.debug('result', result);

        if (result.data?.weitergeleitet) {
            // Auftrag wurde erfolgreich weitergeleitet
            this.auftragAbgeschlossen.next('weiterleiten');
        }
    }

    async anzeigenStoerzeiten() {
        log.debug('anzeigenStoerzeiten: ' + this.auftrag.Key);

        // TODO

        const modal = await this.modalController.create({
            component: StoerzeitenPage,
            componentProps: {
                'auftragdetails': this
            }
        });

        await modal.present();

        await modal.onDidDismiss();
    }

    updateLieferungVollstaendig(info: string) {
        const fehlertext = this.pruefeAuftragspositionenVollstaendig();

        log.debug('updateLieferungVollstaendig ' + info + ': ' + fehlertext);

        // Bei ReadOnly-Ansicht keine Fehlermeldung anzeigen
        if (fehlertext && !this.isLieferungAendernGesperrt) {
            this.lieferungFehlertext.next(fehlertext);
            this.istLieferungVollstaendig.next(false);
        } else {
            this.lieferungFehlertext.next('');
            this.istLieferungVollstaendig.next(true);
        }
    }

    updateLeergutVollstaendig(info: string) {
        const fehlertext = this.pruefeLeergutVollstaendig();

        log.debug('updateLeergutVollstaendig ' + info + ': ' + fehlertext);

        if (fehlertext) {
            this.leergutFehlertext.next(fehlertext);
            this.istLeergutVollstaendig.next(false);
        } else {
            this.leergutFehlertext.next('');
            this.istLeergutVollstaendig.next(true);
        }
    }

    async onStatusmeldung() {
        const buttons: any[] = [];
        const workflow = this.workflow;

        if (!workflow) {
            return;
        }

        // TODO: Eventuell entfernen, weil man einen Auftrag ablehnen können soll, ohne die Statusmeldung zu starten
        // if (!await this.pruefeZeiterfassungGestartet()) {
        //     return;
        // }

        // const aktuellerStatus = this.auftrag.Statusmeldung;
        let statusListe = workflow.status.sort((a, b) => a.sortierung - b.sortierung);

        if (this.istAuftragAbgeschlossen) {
            statusListe = workflow.status.filter(p => p.NachAbschlussErlaubt).sort((a, b) => a.sortierung - b.sortierung);

            if (!statusListe.length) {
                this.showAuftragArchiviert();
                return;
            }
        } else if (this.isReadOnly) {
            // this.showAuftragSchreibgeschuetzt();
            // return;
        }

        if (statusListe.length >= 5) {
            // Liste anzeigen damit gescrollt werden kann
            const modal = await this.modalController.create({
                component: AuftragStatusmeldungPage,
                componentProps: {
                    'auftragdetails': this,
                    'statusListe': statusListe
                }
            });

            await modal.present();

            const result = await modal.onDidDismiss();

            if (result.data?.workflowStatus) {
                this.statusMelden(result.data.workflowStatus, true);
            }

            return;
        }

        // Normale Auswahl anzeigen
        for (const status of statusListe) {
            let role = '';

            if (status.auftragsstatus === Auftragsstatus.Abgelehnt) {
                role = 'destructive';
            }

            buttons.push({
                text: status.name,
                icon: status.icon,
                role: role,
                handler: () => {
                    this.statusMelden(status, true);
                }
            });
        }

        buttons.push({
            text: I18N.instant('Abbrechen'),
            icon: 'close',
            role: 'cancel',
            handler: () => { }
        });

        const alert = await this.alertController.create({
            header: 'Statusmeldung',
            cssClass: 'no-text-alert statusmeldungen-alert', // no-text-alert  no-title-alert
            mode: 'ios',
            buttons: buttons
        });

        await alert.present();
    }

    pruefeUnterschriftenVollstaendig(): string {
        const rollen = ['ERZ', 'BEF', 'ENT'];
        const erforderlicheUnterschriften: UnterschriftInfo[] = [];

        if (this.darstellung === 'wizard') {
            for (const wizardPage of this.workflow.WizardKonfiguration.WizardPages) {
                const unterschriftKomponente = wizardPage.Formular.komponenten.filter(p => p.name === 'Unterschrift');

                for (const komponente of unterschriftKomponente) {
                    for (const rolle of rollen) {
                        let wert = komponente.einstellungen.find(p => p.key === rolle)?.wert;

                        if (rolle === 'ERZ' && this.auftrag.UnterschriftKundeErforderlich) {
                            wert = 'ja';
                        }

                        if (wert === 'ja') {
                            const titel = komponente.einstellungen.find(p => p.key === rolle + '-Titel')?.wert;

                            erforderlicheUnterschriften.push({
                                rolle: rolle,
                                titel: titel
                            });
                        }
                    }
                }
            }
        } else {
            const komponente = this.formularKonfiguration.komponenten.find(p => p.name === 'Unterschrift');

            if (komponente) {
                for (const rolle of rollen) {
                    let wert = komponente.einstellungen.find(p => p.key === rolle)?.wert;

                    if (rolle === 'ERZ' && this.auftrag.UnterschriftKundeErforderlich) {
                        wert = 'ja';
                    }

                    if (wert === 'ja') {
                        const titel = komponente.einstellungen.find(p => p.key === rolle + '-Titel')?.wert;

                        erforderlicheUnterschriften.push({
                            rolle: rolle,
                            titel: titel
                        });
                    }
                }
            }
        }

        for (const item of erforderlicheUnterschriften) {
            const vorhanden = !!this.auftrag.Unterschriften.find(p => p.Typ === item.rolle)?.Guid;

            if (!vorhanden) {
                return `Unterschrift ${item.titel} (${item.rolle}) ist erforderlich`;
            }
        }

        return null;
    }

    onAuftragspositionFertigChanged(position: AuftragspositionEx) {
        this.auftragspositionFertigChanged.next(position);
    }


    updatePositionen() {
        log.debug('updatePositionen');

        const artikelList = this.stammdatenService.getAlleArtikel().getValue();

        for (const position of this.auftrag.Positionen) {
            if (!position.Eigenschaften) {
                position.Eigenschaften = [];
            }

            const eigenschaftenUppercase = AppConfig.current.einstellungen.AlleEigenschaftenUppercase === 'ja';

            for (const eigenschaft of position.Eigenschaften) {
                if (!eigenschaft.Typ) {
                    eigenschaft.Typ = 'text';
                }

                if (eigenschaftenUppercase) {
                    eigenschaft.Uppercase = true;
                }
            }

            // Hier extra mit == prüfen. Kann Zahl oder String sein
            const artikel = artikelList.find(p => p.artikelKey == position.ArtikelKey as any);

            if (artikel) {
                // SeriennummerErforderlich
                switch (AppConfig.current.einstellungen.DatenherkunftSeriennummerErforderlich) {
                    case 'auftrag':
                        // Keine Änderung
                        break;

                    case 'artikel':
                        position.SeriennummerErforderlich = artikel.SeriennummerErforderlich;
                        break;

                    default:
                        if (artikel.SeriennummerErforderlich) {
                            if (position.SeriennummerErforderlich === null || typeof (position.SeriennummerErforderlich) === 'undefined') {
                                position.SeriennummerErforderlich = artikel.SeriennummerErforderlich;
                            }
                        }
                        break;
                }

                if (this.auftrag.Auftragstyp === Auftragstyp.Kommissionierung) {
                    // Chargenpflicht kommt immer aus dem Artikel
                    // Derzeit nur für Kommissionierungs-Aufträge setzen.
                    // Wird sonst nirgends benötigt
                    position.Chargenpflicht = artikel.Chargenpflicht;
                }
            }
        }
    }
}
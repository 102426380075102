import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output } from '@angular/core';
import { Logger } from 'src/app/api/helper/app-error-logger';
import { AppConfig } from 'src/app/api/helper/app.config';
import { Utils } from 'src/app/api/helper/utils';
import { AuftragEx } from 'src/app/api/model/model';
import { Adresse, AuftragsKomponente, Kontakt } from 'src/app/api/model/swagger-model';

const log = new Logger("AdresseComponent");

@Component({
    selector: 'app-adresse',
    templateUrl: './adresse.component.html',
    styleUrls: ['./adresse.component.scss'],
})
export class AdresseComponent implements OnInit, OnChanges {
    @Input() auftrag: AuftragEx;
    @Input() adresse: Adresse;
    @Input() canNavigate: boolean;
    @Input() canCollapse: boolean;
    @Input() collapsed: boolean;
    @Input() buttonsAnzeigen = true;

    @Input() formularkonfiguration: AuftragsKomponente;

    @Output() navigation: EventEmitter<any> = new EventEmitter();
    @Output() anrufen: EventEmitter<any> = new EventEmitter();

    nameSichtbar = true;
    strasseSichtbar = true;
    ortSichtbar = true;
    plzSichtbar = true;
    adressNummerSichtbar = false;
    navigationErlaubt = true;
    telefonSichtbar = true;
    behoerdlicheNummerSichtbar = false;
    behoerdlicheNummerBezeichnung = '';

    kontakte: Kontakt[] = [];

    constructor() { }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.auftrag) {
            this.refresh('ngOnChanges');
        }
    }

    ngOnInit() {
        this.refresh('ngOnInit');
    }

    refresh(info: string) {
        log.debug('refresh: ' + info);

        if (!this.formularkonfiguration) {
            this.formularkonfiguration = {
                einstellungen: [
                    { key: 'Name', wert: 'ja' },
                    { key: 'Strasse', wert: 'ja' },
                    { key: 'Ort', wert: 'ja' },
                    { key: 'PLZ', wert: 'ja' },
                    { key: 'AdressKey', wert: 'nein' },
                ]
            };
        }

        if (!this.formularkonfiguration.einstellungen) {
            this.formularkonfiguration.einstellungen = [];
        }

        for (const c of this.formularkonfiguration.einstellungen) {
            if (c.key === 'Name') {
                this.nameSichtbar = c.wert == 'ja';
            } else if (c.key === 'Strasse') {
                this.strasseSichtbar = c.wert == 'ja';
            } else if (c.key === 'Ort') {
                this.ortSichtbar = c.wert == 'ja';
            } else if (c.key === 'PLZ') {
                this.plzSichtbar = c.wert == 'ja';
            } else if (c.key === 'Nummer') {
                this.adressNummerSichtbar = c.wert == 'ja';
            } else if (c.key === 'Navigation') {
                this.navigationErlaubt = c.wert == 'ja';
            } else if (c.key === 'Telefon') {
                this.telefonSichtbar = c.wert !== 'nein';
            } else if (c.key === 'BehoerdlicheNummer') {
                this.behoerdlicheNummerSichtbar = c.wert !== 'nein';
            } else if (c.key === 'BehoerdlicheNummerBezeichnung') {
                this.behoerdlicheNummerBezeichnung = Utils.trimToEmpty(c.wert);
            }
        }

        this.kontakte = [];

        if (this.adresse && this.auftrag) {
            // Prüfe ob die Kontakte schon in dern euen Struktur übergeben werden
            if (this.auftrag.Kontakte) {
                for (const kontakt of this.auftrag.Kontakte) {
                    if (kontakt.AdressNummer == this.adresse.AdressNummer) {
                        // Wenn Erzeugeradresse gleich der Abholadresse ist, dann sollen die Kontakte nicht doppelt angezeigt werden
                        let vorhanden = this.kontakte.find(p => p.Name === kontakt.Name && p.Telefon === kontakt.Telefon);

                        if (!vorhanden) {
                            this.kontakte.push(kontakt);
                        }
                    }
                }
            }

            if (this.kontakte.length == 0) {
                if (this.adresse.Telefon) {
                    this.kontakte.push({
                        Name: this.adresse.Ansprechpartner,
                        Telefon: this.adresse.Telefon
                    })
                }
            }
        }

        if (this.kontakte.length > 1) {
            if (AppConfig.current.einstellungen.AuftragKontakteAlleAnzeigen !== 'ja') {
                // Nur den ersten Kontakt anzeigen. Das ist der Standard-Fall
                this.kontakte = [this.kontakte[0]];
            }
        }
    }

    onToggle() {
        if (this.canCollapse) {
            this.collapsed = !this.collapsed;
        }
    }

    onAnrufen(e: Event) {
        log.debug('onAnrufen');
        this.anrufen.emit();
    }

    onNavigation(e: Event) {
        log.debug('onNavigation');
        this.navigation.emit();
    }
}

import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild, ChangeDetectorRef, NgZone, Input, EventEmitter, Output } from '@angular/core';
import { AuftragEx, AuftragspositionEx, AuftragspositionWiegungEx } from 'src/app/api/model/model';
import { NavController, AlertController, ModalController, IonSelect } from '@ionic/angular';
import { ActivatedRoute } from '@angular/router';
import { UiHelper } from 'src/app/api/helper/ui-helper';
import { Subscription, BehaviorSubject } from 'rxjs';
import { Logger } from 'src/app/api/helper/app-error-logger';
import { App } from 'src/app/api/helper/app';
import { Artikel, AdresseEntity, Auftragsposition, GpsPosition, MengeOptionTyp, Vertragsposition, WiegungTyp, Waage, Auftragstyp, Fahrzeug, WorkflowStatus, WiegenRequest } from 'src/app/api/model/swagger-model';
import { TranslateService } from '@ngx-translate/core';
import { WaageService } from 'src/app/api/waage.service';
import { StammdatenService } from 'src/app/api/stammdaten.service';
import { SystemService } from 'src/app/api/system.service';
import { AppConfig } from 'src/app/api/helper/app.config';
import { i18n } from 'src/app/api/helper/i18n';
import { IAuftragdetails } from '../auftragdetails/iauftragdetails';
import { DateHelper } from 'src/app/api/helper/date-helper';
import { AuftragService } from 'src/app/api/auftrag.service';
import { Utils } from 'src/app/api/helper/utils';

@Component({
    selector: 'app-auftragsposition-wiegen',
    templateUrl: './auftragsposition-wiegen.page.html',
    styleUrls: ['./auftragsposition-wiegen.page.scss'],
})
export class AuftragspositionWiegenPage implements OnInit, AfterViewInit, OnDestroy {
    log = new Logger("AuftragspositionWiegenPage");

    @ViewChild('waagenSelect', { read: IonSelect, static: false }) waagenSelect: IonSelect;

    @Input() auftrag: AuftragEx;
    @Input() auftragsposition: AuftragspositionEx;
    @Input() auftragdetails: IAuftragdetails;
    @Output() changed: EventEmitter<any> = new EventEmitter();

    subscriptions: Subscription[] = [];

    title = new BehaviorSubject('Lieferung');

    einheiten = ['KG', 'Tonnen'];

    waagen: Waage[] = [];

    letzteWiegungDate = 0;
    wiegenNummer = 1;
    ausgewaehlteWaage: Waage;
    nfcRegistrationId: number;

    app = App.current;

    wiegenHandeingabeErlaubt = false;
    wiegenLeergewichtErlaubt = false;

    constructor(
        private alertController: AlertController,
        private modalController: ModalController,
        private auftragService: AuftragService,
        private cdr: ChangeDetectorRef,
        private systemService: SystemService,
        private route: ActivatedRoute,
        private ngZone: NgZone,
        private nav: NavController,
        private stammdatenService: StammdatenService,
        private waageService: WaageService,
        private translate: TranslateService) { }


    async ngOnInit() {
        this.log.debug('ngOnInit', { auftragsposition: this.auftragsposition });
        this.aktualisiereWiegungenListe();

        if (this.auftrag.Auftragstyp === Auftragstyp.Transportauftrag) {
            this.wiegenHandeingabeErlaubt = AppConfig.current.einstellungen.TransportauftragPositionWiegenHandeingabe === 'ja';
            this.wiegenLeergewichtErlaubt = AppConfig.current.einstellungen.TransportauftragPositionWiegenLeergewicht === 'ja';
        } else if (this.auftrag.Auftragstyp === Auftragstyp.Hofchecker) {
            this.wiegenHandeingabeErlaubt = AppConfig.current.einstellungen.HofauftraegePositionWiegenHandeingabe === 'ja';
            this.wiegenLeergewichtErlaubt = AppConfig.current.einstellungen.HofauftraegePositionWiegenLeergewicht === 'ja';
        }
    }

    ngAfterViewInit(): void {
        this.log.debug('ngAfterViewInit');
    }

    ngOnDestroy(): void {
        this.log.debug('ngOnDestroy');
        this.systemService.unregisterNfcReceiver(this.nfcRegistrationId);
    }

    onGewichtChanged(wiegung: AuftragspositionWiegungEx) {
        this.log.debug('onGewichtChanged');
        this.aktualisiereVollstaendig();
        this.aktualisiereNetto();

        this.changed.next();
    }

    async onWaageSelected(e: Event) {
        this.log.debug('onWaageSelected', e);

        if (!e) {
            return;
        }

        const event = e as CustomEvent;

        if (this.waagen && this.waagen.length == 1) {
            // Irgnorieren. Das event wurde nur ausgelöst weil die Property 'waagen' gesetzt wurde
            return;
        }

        this.doWiegen(event.detail.value);
    }

    private loescheWiegung(nummer: number) {
        const wiegung = this.getWiegung(nummer);

        wiegung.Erfolgreich = false;
        wiegung.Einheit = null;
        wiegung.Gewicht = null;
        wiegung.WiegeNr = null;
        wiegung.WaageKey = null;
        wiegung.Typ = WiegungTyp.Unbekannt;
        wiegung.Text = '';
    }

    getWiegung(nummer: number): AuftragspositionWiegungEx {
        switch (nummer) {
            case 1:
                return this.auftragsposition.Wiegungen2[0];

            case 2:
                return this.auftragsposition.Wiegungen2[1];

            default:
                throw new Error('Ungültige Nummer für Wiegung: ' + nummer);
        }
    }

    async onLeergewicht(nummer: number) {
        this.log.debug('onLeergewicht: (' + nummer + ')');

        let fahrzeug: Fahrzeug;

        if (this.auftrag.Auftragstyp === Auftragstyp.Transportauftrag) {
            fahrzeug = App.current.fahrzeug.getValue();

            if (!fahrzeug) {
                UiHelper.showAlert('Kein Fahrzeug geladen. Leergewicht unbekannt');
                return;
            }
        } else {
            // Fahrzeug des Auftrags suchen
            if (!this.auftrag.FahrzeugKennung) {
                UiHelper.showAlert('Kein Fahrzeug zum Auftrag definiert. Leergewicht unbekannt.');
                return;
            }

            fahrzeug = await this.stammdatenService.getFahrzeug(this.auftrag.FahrzeugKennung);

            if (!fahrzeug) {
                UiHelper.showAlert(`Fahrzeug '${this.auftrag.FahrzeugKennung}' nicht gefunden. Leergewicht unbekannt`);
                return;
            }
        }

        if (!fahrzeug.Leergewicht) {
            UiHelper.showAlert(`Kein Leergewicht für Fahrzeug ${fahrzeug.kennzeichen} konfiguriert`);
            return;
        }

        const wiegung = this.getWiegung(nummer);

        if (wiegung.Erfolgreich) {
            const ok = await UiHelper.confirmJaNein('Wiegung wirklich löschen?');

            if (!ok) {
                return;
            }
        }

        this.loescheWiegung(nummer);

        wiegung.Einheit = 'KG';
        wiegung.Gewicht = fahrzeug.Leergewicht;
        wiegung.WiegeNr = '****';
        wiegung.Typ = WiegungTyp.Leergewicht;
        wiegung.Text = `${wiegung.Gewicht} ${wiegung.Einheit}`;
        wiegung.Datum = Utils.nowIsoDateString();

        this.aktualisiereVollstaendig();

        this.changed.next();
    }

    /**
     * Berechnet das Netto-Gewicht neu, oder löscht es falls noch keien zwei Wiegungen erfolgt sind
     */
    aktualisiereNetto() {
        this.auftragsposition.NettoGewicht = null;

        if (this.auftragsposition.Wiegungen2 && this.auftragsposition.Wiegungen2.length === 2) {
            const wiegung1 = this.auftragsposition.Wiegungen2[0];
            const wiegung2 = this.auftragsposition.Wiegungen2[1];

            if (wiegung1.Gewicht != null && wiegung2.Gewicht != null) {
                this.auftragsposition.NettoGewicht = Math.abs(wiegung1.Gewicht - wiegung2.Gewicht);
            }
        }
    }

    getWiegenButtonColor(nummer: number, typ: number): string {
        const wiegung = this.getWiegung(nummer);

        if (!wiegung.Typ) {
            wiegung.Typ = WiegungTyp.Unbekannt;
        }

        switch (typ) {
            case WiegungTyp.Wiegung:
                if (wiegung.Typ === WiegungTyp.Wiegung || wiegung.Typ === WiegungTyp.Unbekannt) {
                    if (wiegung.Erfolgreich) {
                        return 'light';
                    } else {
                        return 'primary';
                    }
                } else {
                    return 'light';
                }

            case WiegungTyp.Handeingabe:
                if (wiegung.Typ === WiegungTyp.Handeingabe) {
                    return 'primary';
                } else {
                    return 'light';
                }

            case WiegungTyp.Leergewicht:
                if (wiegung.Typ === WiegungTyp.Leergewicht) {
                    return 'primary';
                } else {
                    return 'light';
                }
        }

        return 'light';
    }

    async onWiegen(nummer: number) {
        this.log.debug('onWiegen:  (' + nummer + ')');

        const wiegung = this.getWiegung(nummer);

        if (wiegung.Erfolgreich) {
            const ok = await UiHelper.confirmJaNein('Wirklich neu wiegen?');

            if (!ok) {
                return;
            }
        }

        this.loescheWiegung(nummer);

        await UiHelper.showLoading('Suche Waagen');

        const waagen = await this.waageService.getRemoteWaagenInReichweite();

        if (!waagen || !waagen.length) {
            UiHelper.showAlert('Keine Waage in Reichweite gefunden', 'Wiegen');
            return;
        }

        this.waagen = waagen;
        this.wiegenNummer = nummer;

        if (waagen.length == 1) {
            this.doWiegen(waagen[0]);
        } else {
            this.cdr.detectChanges();

            await UiHelper.hideLoading();

            setTimeout(() => {
                this.waagenSelect.open();
            });
        }
    }

    async onHandeingabe(nummer: number) {
        this.log.debug('onHandeingabe:  (' + nummer + ')');

        const wiegung = this.getWiegung(nummer);

        if (wiegung.Erfolgreich) {
            const ok = await UiHelper.confirmJaNein('Wiegung wirklich löschen?');

            if (!ok) {
                return;
            }
        }

        this.loescheWiegung(nummer);

        wiegung.Typ = WiegungTyp.Handeingabe;
        wiegung.Einheit = 'KG';
        wiegung.Gewicht = null;
        wiegung.WiegeNr = '****';
        wiegung.Datum = Utils.nowIsoDateString();

        this.cdr.detectChanges();

        const id = `wiegung-${this.auftragsposition.PosNr}-${wiegung.Nummer}-input`;

        this.aktualisiereVollstaendig();

        setTimeout(() => {
            const e = document.getElementById(id);
            if (e) {
                const input = e.getElementsByTagName('input')[0]
                if (input) {
                    input.focus();
                }
            }
        }, 100);

        this.changed.next();
    }

    async doWiegen(waage: Waage) {
        this.log.debug('doWiegen', waage);

        if (!waage) {
            return;
        }

        if (this.letzteWiegungDate > Date.now() - 1000) {
            this.log.warn('doWiegen wurde zu schnell hintereinander aufgerufen. Max 1 Aufruf je 1000 ms erlaubt');
            return;
        }

        this.letzteWiegungDate = Date.now();
        this.ausgewaehlteWaage = waage;

        let sendenWiegung: AuftragspositionWiegungEx = null;

        try {
            await UiHelper.showLoading('Wiegung läuft...');

            const request: WiegenRequest = {
                WaageKey: waage.Key,
                Auftragsnummer: this.auftrag.Auftragsnummer,
                PositionNummer: this.auftragsposition.PosNr,
                PositionArtikelKey: this.auftragsposition.ArtikelKey,
                PositionBezeichnung: this.auftragsposition.Bezeichnung,
            };

            if (this.wiegenNummer == 1) {
                request.Wiegeart = 'Erstwiegung';
            } else if (this.wiegenNummer == 2) {
                request.Wiegeart = 'Zweitwiegung';
            }

            const wiegenResult = await this.waageService.wiegenRemote(request);

            await UiHelper.hideLoading();

            const wiegung = this.getWiegung(this.wiegenNummer);

            if (wiegenResult.Success) {
                wiegung.Typ = WiegungTyp.Wiegung;
                wiegung.Datum = wiegenResult.Datum;
                wiegung.Gewicht = wiegenResult.Gewicht;
                wiegung.WiegeNr = wiegenResult.WiegeNr;
                wiegung.WaageKey = wiegenResult.WaageKey;
                wiegung.Einheit = "KG";
                wiegung.Erfolgreich = true;

                let uhrzeit = DateHelper.formatUhrzeit(wiegung.Datum);

                wiegung.Text = `${wiegung.Gewicht} KG, Nr. ${wiegung.WiegeNr}, Waage: ${wiegung.WaageKey}, ${uhrzeit} Uhr`

                sendenWiegung = wiegung;

                this.aktualisiereNetto();
            } else {
                UiHelper.showError(wiegenResult.Message, 'Fehler', false, false);
            }

            this.cdr.detectChanges();
        } catch (err) {
            UiHelper.showError(err);
        }

        this.aktualisiereVollstaendig();

        if (this.auftrag) {
            // Hier soll sofort der Auftrag gespeichert werden.
            // Manchmal wird gewogen ohne dass die Auftragsposition danach verlassen wird.
            // Die Daten dürfen nicht verloren gehen wenn die App geschlossen wird.
            if (this.auftragService.istAktiverAuftrag(this.auftrag)) {
                await this.auftragService.speichereAuftrag(this.auftrag);
            }

            if (sendenWiegung && AppConfig.current.einstellungen.TransportauftragPositionWiegenSofortSenden === 'ja') {
                await UiHelper.showLoading('Wiegung wird gesendet...');

                try {
                    // Austragstatus automatisch senden
                    // Die Wiegungen sollen in ReCoLog direkt sichtbar sein
                    const status: WorkflowStatus = {
                        anmerkungErforderlich: false,
                        auftragAbschliessen: false,
                        auftragsdatenSenden: true,
                        bildErforderlich: false,
                        bilderSenden: false,
                        GeopositionExaktErfassen: false,
                        geopositionSenden: false,
                        minAnzahlBilder: 0,
                        unterschriftErforderlich: false,
                        unterschriftSenden: false,
                        key: AppConfig.current.einstellungen.TransportauftragPositionWiegenSofortSendenStatus,
                        name: `Wiegung ${sendenWiegung.Gewicht} KG, Nr. ${sendenWiegung.WiegeNr}`
                    };

                    await this.auftragService.statusmeldung(this.auftrag, status)
                } catch (err) {
                    this.log.error('Fehler beim Senden der automatischen Statusmeldung nach Wiegen: ' + Utils.getErrorMessage(err), err);
                }

                await UiHelper.hideLoading();
            }
        }

        this.changed.next();
    }

    aktualisiereVollstaendig() {
        this.aktualisiereWiegungenListe();
        this.aktualisiereNetto();
    }

    aktualisiereWiegungenListe() {
        if (!this.auftragsposition.Wiegungen2) {
            this.auftragsposition.Wiegungen2 = [];
        }

        // Es muss mindestens eine Wiegung
        if (this.auftragsposition.Wiegungen2.length < 1) {
            this.auftragsposition.Wiegungen2.push({
                Nummer: 1,
                Typ: WiegungTyp.Unbekannt
            });
        }

        if (this.auftragsposition.Wiegungen2.length === 1) {
            if (this.auftragsposition.Wiegungen2[0].Gewicht || this.auftragsposition.Wiegungen2[0].Gewicht === 0) {
                this.auftragsposition.Wiegungen2.push({
                    Nummer: 2,
                    Typ: WiegungTyp.Unbekannt
                });
            }
        } else if (this.auftragsposition.Wiegungen2.length === 2) {
            if (!this.auftragsposition.Wiegungen2[0].Gewicht && this.auftragsposition.Wiegungen2[0].Gewicht !== 0) {
                this.auftragsposition.Wiegungen2.splice(1, 1);
            }
        }

        for (const wiegung of this.auftragsposition.Wiegungen2) {
            wiegung.Bezeichnung = i18n('AuftragWiegung' + wiegung.Nummer);
        }

        // if (this.auftragsposition.Menge) {
        // } else {
        //     this.auftragsposition.Wiegungen2 = [];
        // }
    }
}

interface AuftragspositionVO {
    auftragsposition: Auftragsposition;
    artikel: Artikel;
    vertragsposition?: Vertragsposition;
    behaelter: BehaelterbewegungVO[];
    mengeUngueltig: boolean;
    MengeOption: MengeOptionTyp;
    StandardMenge: number;
    wiegungZuordnenAnzeigen: boolean;
    LaderaumAuswahlAktiv: boolean;
    suchtext: string;
}

interface BehaelterbewegungVO {
    name: string;
    aktion: 'stellen' | 'entleeren' | 'abziehen' | 'umsetzen' | 'direktbeladung';
    behaelterNr: string;
    manuelleNummer?: string;
    behaelterNrUngueltig?: boolean;
    isScan?: boolean;

    // Zum anzeigen von "NFC aktiv"
    aktiv?: boolean;

    // Soll der Abstellort ausgewählt werden?
    abstellortAktiv?: boolean;

    // Nur temporär fpr ionic-selectable
    abstellort?: AdresseEntity;

    // Adresse des Abstellorts
    abstellortKey?: string;
    zustand?: string; // voll / leer

    position?: GpsPosition;

    changed?: boolean;
}

interface LaderaumVO {
    key: string;
    bezeichnung: string;
}

